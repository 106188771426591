/* eslint-disable */
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import StripeCheckout from 'react-stripe-checkout';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';

import 'react-datepicker/dist/react-datepicker.css';
import { setMinutes, setHours, format } from 'date-fns';

import { appId, apiUrl,apiUrlV2, deliveryId, CountryTxt, stripeReference, stripeCurrency, stripeImage, stripeCompany ,timThumpUrl} from "../Helpers/Config";
import { getReferenceID, stripslashes, showPriceValue, callImage, showLoader, hideLoader, getCalculatedAmount, showAlert, showCustomAlert, showCustomCenterAlert, getOrderDateTime, getPromoCkValue, removePromoCkValue, smoothScroll } from "../Helpers/SettingHelper";
import { GET_CART_DETAIL, UPDATE_CART_DETAIL, DELETE_CART_DETAIL, DESTROY_CART_DETAIL, GET_ACTIVITYCOUNT, GET_HOLIDAYS,GET_ADDONPRODUCT } from '../../actions';

var Parser = require('html-react-parser');
const isEqual = require("react-fast-compare");
var qs = require('qs');
var dateFormat = require('dateformat');

import $ from "jquery";
import Slider from "react-slick";

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import OrderdatetimeSlot from "../Layout/OrderdatetimeSlot";

import productImg from "../../common/images/noimg-400x400.jpg";
import payment from "../../common/images/payment.png";
import paymentOmise from "../../common/images/paymentOmise.png";

import tickImage from "../../common/images/tick_popup.png";
import striplogo from "../../common/images/logo.png";
import loadingImage from "../../common/images/loading_popup.gif";

class Checkout extends Component {
	constructor(props) {
		super(props);
  
		/* Rounding to the nearest 15 minute interval */
		const start = moment();
		const remainder = 15 - (start.minute() % 15);
		const currentTime = moment(start).add(remainder, "minutes");
		/* To change the date after 2 PM */
		/*var CurrentDate = this.calcTime('Singapore', '+8');*/
		
		var CurrentDate = new Date();
		
		var avilablityId = (cookie.load('defaultAvilablityId') != '' && cookie.load('defaultAvilablityId') != undefined) ? cookie.load('defaultAvilablityId') : '';
		var orderOutletId = (cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined) ? cookie.load('orderOutletId') : '';
		var orderPostalCode = (typeof cookie.load('orderPostalCode') === 'undefined') ? '' : cookie.load('orderPostalCode');
		var orderDeliveryAddress = (typeof cookie.load('orderDeliveryAddress') === 'undefined') ? '' : cookie.load('orderDeliveryAddress');
		var unitNoOne = (typeof cookie.load('unitNoOne') === 'undefined') ? '' : cookie.load('unitNoOne');
		var unitNoTwo = (typeof cookie.load('unitNoTwo') === 'undefined') ? '' : cookie.load('unitNoTwo');

		var billingPostalCode = (typeof cookie.load('billingPostalCode') === 'undefined') ? '' : cookie.load('billingPostalCode');
		var billingDeliveryAddress = (typeof cookie.load('billingDeliveryAddress') === 'undefined') ? '' : cookie.load('billingDeliveryAddress');
		var billunitNoOne = (typeof cookie.load('billunitNoOne') === 'undefined') ? '' : cookie.load('billunitNoOne');
		var billunitNoTwo = (typeof cookie.load('billunitNoTwo') === 'undefined') ? '' : cookie.load('billunitNoTwo');

		var orderDateTime = (typeof cookie.load('orderDateTime') === 'undefined') ? '' : cookie.load('orderDateTime');
		var orderTAT = (typeof cookie.load('orderTAT') === 'undefined') ? '' : cookie.load('orderTAT');
		
		var getPromoCkVal = getPromoCkValue();
		
		this.state = {globalSettings: [], staticblacks: [], overAllcart: [], cartItems: [], cartDetails: [], cartStatus: '', cartTotalItmCount: 0, order_postcode: orderPostalCode, order_delivery_address: orderDeliveryAddress, unitnumber1 : unitNoOne, unitnumber2 : unitNoTwo, billing_postcode: billingPostalCode, billing_delivery_address: billingDeliveryAddress, billunitnumber1 : billunitNoOne, billunitnumber2 : billunitNoTwo, billing_addrs_sameas: "yes", order_specil_note: '', getDateTimeFlg:'', seleted_ord_date: '', seleted_ord_time: '', activitycount_arr: [], promotion_count: 0, reward_point_count: 0, used_reward_point: getPromoCkVal['usedPoints'], reward_point_val: getPromoCkVal['reedemPointVal'], promo_code_val: getPromoCkVal['promoCodeVal'], promotion_applied: getPromoCkVal['promotionApplied'], promotion_type: getPromoCkVal['promotionType'], promotion_amount: getPromoCkVal['promotionAmount'], promotion_source: getPromoCkVal['promotionSource'], promoIs_delivery: getPromoCkVal['promoIsDelivery'],paymentmodevalue: 'Omise',validateimage: loadingImage,placingorderimage: loadingImage, completingpaymentimage: loadingImage,orderValidFail: 0,cod_payment_enable: 0,omise_payment_enable: 0,stripe_payment_enable: 0,postorder_triggered: 'no',chk_tarms_contn: 'No',termsAndConditions:'',seletedAvilablityId:avilablityId,seletedOutletId:orderOutletId,order_tat_time:orderTAT,otp_verification_enable:0,otp_verification_via:0,
		omisePayment:0,
		omisecodeerror:'',omiseyearerror:'', omisemontherror:'', omisecardrror:'', omisenameerror:'',
		omise_tokken_response:"",omise_tokken_card_id:"",omise_tokken_id:'',
		holdername:'', cardNumber:'', expiration_month:'', expiration_year:'', security_code:'', cardImage:'',log_id:'',
	    delivery_self_collection: 0,  updateCartResponse: [], startMsg: 0		}

	  }
 
	componentDidMount() {

		var orderOutletId = (cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined) ? cookie.load('orderOutletId') : '';	
		orderOutletId = (orderOutletId === undefined) ? '' : orderOutletId;
		
		this.props.getAddonProList(orderOutletId);
		
		if(cookie.load('UserId') !== undefined) {
			this.getActivityCounts();
		}

		const script = document.createElement("script");
		script.src = "https://cdn.omise.co/omise.js";
		script.async = true;
		document.body.appendChild(script);

	}

  componentWillReceiveProps(nextProps){

	    if(this.state.globalSettings !== nextProps.settingsArr) {

			var tampStArr = nextProps.settingsArr;
			var paymentmode = 'Omise';
			var codPayment = 0;
			var stripePayment = 0;
			var omisePayment = 0;

			if(Object.keys(tampStArr).length > 0) {

				if(tampStArr.client_cod_enable == 1 && tampStArr.client_cod_availability == 1) {
					codPayment = 1;
					paymentmode = 'Cash';
				}

				if(tampStArr.client_stripe_enable == 1 && tampStArr.client_stripe_availability == 1) {
					stripePayment = 1;
				}

				if(tampStArr.client_omise_enable == 1 && tampStArr.client_omise_availability == 1) {
					omisePayment = 1;
				}
			}

			this.setState({globalSettings: nextProps.settingsArr,cod_payment_enable: codPayment,stripe_payment_enable: stripePayment,omise_payment_enable: omisePayment,paymentmodevalue:paymentmode,otp_verification_enable:tampStArr.client_otp_verification_enable,otp_verification_via:tampStArr.client_otp_verification_via});
		}

		if(this.state.activitycount_arr !== nextProps.activitycountArr) {
			this.setState({activitycount_arr: nextProps.activitycountArr, promotion_count: nextProps.activitycountArr.promotionwithoutuqc, reward_point_count: nextProps.activitycountArr.reward_ponits});
		}
	  
	    if(this.state.overAllcart !== nextProps.overAllcart) {
			this.setState({overAllcart: nextProps.overAllcart, cartItems: nextProps.cartItems, cartDetails: nextProps.cartDetails, cartStatus: nextProps.cartStatus, cartTotalItmCount: nextProps.cartTotalItmCount});
		}

	    if (this.state.updateCartResponse !== nextProps.updateCartResponse) {
	      if (Object.keys(nextProps.updateCartResponse).length > 0) {
	        this.setState(
	          { updateCartResponse: nextProps.updateCartResponse },
	          function () {
	            var Response = nextProps.updateCartResponse;
	            if (Object.keys(Response).length > 0) {
	              if (Response[0].status === "error") {
	                if (this.state.startMsg === 1) {
	                  this.handleShowAlertFun("Error", Response[0].message);
	                  this.setState({ startMsg: 0 });
	                }
	              }
	            }
	          }
	        );
	      }
	    }		
  }


	componentDidUpdate() {
		this.checkOutAuthentication();
		var modevalue = this.state.paymentmodevalue;
		var promoTionArr = Array();
			promoTionArr['promotionApplied'] = this.state.promotion_applied;
			promoTionArr['promotionAmount'] = this.state.promotion_amount;
			promoTionArr['promoIsDelivery'] = this.state.promoIs_delivery;

	    var calculatedAmount = getCalculatedAmount(this.state.globalSettings,this.props.zonedetails,this.state.cartDetails,promoTionArr, this.state.delivery_self_collection);
		
		var subTotalAmount = parseFloat(calculatedAmount['cartSubTotalAmount']);
		var grandTotalAmount = parseFloat(calculatedAmount['grandTotalAmount']);
		if(subTotalAmount>0 && grandTotalAmount===0) {
			modevalue = 'promotion';
		}
		if(this.state.paymentmodevalue !== modevalue) {
			this.setState({paymentmodevalue:modevalue});
		}
		
		var cartDetailsArr = this.state.cartDetails;
		var settingsArr = this.state.globalSettings;
        if ((cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined ) && (cookie.load('UserMobile') != ''  && cookie.load('UserMobile') != undefined ) && (Object.keys(cartDetailsArr).length > 0 && Object.keys(settingsArr).length > 0) && (this.state.postorder_triggered === 'no')) {
				this.setState({postorder_triggered:'yes',getDateTimeFlg:'yes'}, function(){ this.postOrder('initial', 'Yes'); }.bind(this));
        }
		
		var sltdOrdDate = this.state.seleted_ord_date;
		var sltdOrdTime = this.state.seleted_ord_time;
		if(sltdOrdDate !== '' && sltdOrdTime !== '') {
			 this.updateOrderDateTimeCookie();
		}
  }

	checkOutAuthentication() {
		
		var orderOutletId = (cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined) ? cookie.load('orderOutletId') : '';
		var avilablityId = (cookie.load('defaultAvilablityId') != '' && cookie.load('defaultAvilablityId') != undefined) ? cookie.load('defaultAvilablityId') : '';
		var zoneIdTxt = (typeof cookie.load('orderZoneId') === 'undefined') ? '' : cookie.load('orderZoneId');
		

		if(orderOutletId === '' || avilablityId === '') {
			showAlert('Error', 'Sorry!. Your order outlet detail is empty.');
			window.$.magnificPopup.open({
				items: {
					src: '.alert_popup'
				},
				type: 'inline'
			});
			this.props.history.push('/menu');
		}
		
		if(avilablityId === deliveryId && zoneIdTxt === '') {
			showAlert('Error', 'Sorry!. Your order zone detail is empty.');
			window.$.magnificPopup.open({
				items: {
					src: '.alert_popup'
				},
				type: 'inline'
			});
			this.props.history.push('/menu');
		}
		
		var cartDetailsArr = this.state.cartDetails;
		var settingsArr = this.state.globalSettings;
		var zonedetailsArr = this.props.zonedetails;
		
		if(this.state.cartStatus === 'failure') {
			showAlert('Error', 'Sorry!. Your cart is empty.');
			window.$.magnificPopup.open({
				items: {
					src: '.alert_popup'
				},
				type: 'inline'
			});
			this.props.history.push('/menu');
		}

		if(this.state.cartStatus === 'success' && (Object.keys(cartDetailsArr).length > 0) && (Object.keys(zonedetailsArr).length > 0)) {

			var cartMinAmount = (zonedetailsArr[0].zone_min_amount !== '') ? parseFloat(zonedetailsArr[0].zone_min_amount) : 0;
			var cartSubTotal = parseFloat(cartDetailsArr.cart_sub_total);
			var errorMsg = "You must have an order with a minimum of <span class='cart-min-amt'>$"+(cartMinAmount.toFixed(2))+"</span> to place your order, your current order total is <span class='cart-min-amt'>$"+cartSubTotal.toFixed(2)+"</span>";

			if((cookie.load('defaultAvilablityId')===deliveryId) && (cartSubTotal < cartMinAmount)) {
			/*showCustomAlert('error',errorMsg);*/
			showAlert('Error', errorMsg);
			window.$.magnificPopup.open({
				items: {
					src: '.alert_popup'
				},
				type: 'inline'
			});
			this.props.history.push('/menu');
			}
		}

		var UserId = (cookie.load('UserId') != '' && cookie.load('UserId') != undefined) ? cookie.load('UserId') : '';	
		if(UserId === '') {

			cookie.save("loginpopupTrigger", 'Yes');
			this.props.history.push('/menu');
		}

		var UserMobile = (cookie.load('UserMobile') != '' && cookie.load('UserMobile') != undefined) ? cookie.load('UserMobile') : '';
		if(UserId !== '' && UserMobile === '') {
			showAlert('Error', 'Please update your mobile no.');
			window.$.magnificPopup.open({
			items: {
				src: '.alert_popup'
			},
			type: 'inline'
			});
			this.props.history.push('/myaccount');
		}

	}

	/* Get Redeem Points Count History Details */
	getActivityCounts() {
		const inputKeys = ["promotionwithoutuqc", "reward"];
		this.props.getActivityCount(JSON.stringify(inputKeys));
	  }
	 /* this fuction used to post order to biz panel */
	 postOrder(paymentMode, validation = 'No', captureID = '', payGetWayType = '',Paymentpop = 'No') {

        if (cookie.load('UserId') == '' || cookie.load('UserId') == undefined) {
            cookie.save("loginpopupTrigger", 'Yes');
			this.props.history.push('/menu');
            return false;
        }

		if (cookie.load('defaultAvilablityId') == deliveryId && paymentMode !== 'initial')
		{
			var unitNoOne = this.state.unitnumber1;
			var unitNoTwo = this.state.unitnumber2;
			if(unitNoOne == '' && unitNoTwo == '' && this.state.delivery_self_collection === 0) {
				showAlert('Error', 'Please enter Unit No.');
				window.$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});
				
				if(paymentMode === 1) {
					window.$.magnificPopup.close();	
				}

				smoothScroll(500,270);
				/*showCustomAlert('error','Please enter unit No.');
				showCustomCenterAlert('error','Please enter unit No.');
				*/
				$("#unit_no1_id").focus();

				return false;
			}
			
			var billingAddrsSameas = this.state.billing_addrs_sameas;
			var billingPostcode = this.state.billing_postcode;
			var billingAddrss = this.state.billing_delivery_address;
			if(billingAddrsSameas === 'no' && (billingPostcode === '' || billingAddrss === '')) {
				showAlert('Error', 'Please enter default billing address.');
				window.$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});
				return false;
			}
		}
		
        var finalcartItems = this.state.cartItems;
		
        var products = [];
        var row = this.state.cartItems.map(function (item, i) {

            var modifierdata = [];
            var comboData = [];
            var ComboLen = item.set_menu_component.length;
            var modifierLen = item.modifiers.length;
            if (item.cart_item_type === "Modifier" && modifierLen > 0) {

                for (var l = 0; l < modifierLen; l++) {
                    var modifierVal = [];
                    modifierVal.push({
                        modifier_value_id: item.modifiers[l].modifiers_values[0].cart_modifier_id,
                        modifier_value_qty: item.modifiers[l].modifiers_values[0].cart_modifier_qty,
                        modifier_value_name: item.modifiers[l].modifiers_values[0].cart_modifier_name,
                        modifier_value_price: item.modifiers[l].modifiers_values[0].cart_modifier_price
                    });
                    modifierdata.push({
                        modifier_id: item.modifiers[l].cart_modifier_id,
                        modifier_name: item.modifiers[l].cart_modifier_name,
                        modifiers_values: modifierVal
                    })
                }


            } else if (item.cart_item_type === "Component" && ComboLen > 0) {
			
                for (var m = 0; m < ComboLen; m++) {
                    var combomodifierdata = [];
                    /* build  modifier array */
                    var comboModifier = item.set_menu_component[m].product_details[0].modifiers;

                    var combomodifierdata = [];
                    if (comboModifier.length > 0) { 

                        for (var p = 0; p < comboModifier.length; p++) {
                            var comboModifierVal = [];
                            comboModifierVal.push({
                                modifier_value_id: comboModifier[p].modifiers_values[0].cart_modifier_id,
                                modifier_value_qty: comboModifier[p].modifiers_values[0].cart_modifier_qty,
                                modifier_value_name: comboModifier[p].modifiers_values[0].cart_modifier_name,
                                modifier_value_price: comboModifier[p].modifiers_values[0].cart_modifier_price
                            });
                            combomodifierdata.push({
                                modifier_id: comboModifier[p].cart_modifier_id,
                                modifier_name: comboModifier[p].cart_modifier_name,
                                modifiers_values: comboModifierVal
                            })
                        }
                    }

                    var comborVal = [];
                    var comboProductDetailslen = item.set_menu_component[m].product_details.length;
                    if (comboProductDetailslen > 0) {
                        for (var j = 0, lengthComboPro = comboProductDetailslen; j < lengthComboPro; j++) {
                            comborVal.push({
                                product_id: item.set_menu_component[m].product_details[j].cart_menu_component_product_id,
                                product_name: item.set_menu_component[m].product_details[j].cart_menu_component_product_name,
                                product_sku: item.set_menu_component[m].product_details[j].cart_menu_component_product_sku,
                                product_qty: item.set_menu_component[m].product_details[j].cart_menu_component_product_qty,
                                product_price: item.set_menu_component[m].product_details[j].cart_menu_component_product_price,
                                modifiers: combomodifierdata
                            })
                        }
                    }

                    comboData.push({
                        menu_component_id: item.set_menu_component[m].menu_component_id,
                        menu_component_name: item.set_menu_component[m].menu_component_name,
                        product_details: comborVal
                    })

                }

            }

            products.push({
                product_name: item.cart_item_product_name,
                product_unit_price: item.cart_item_unit_price,
                product_total_amount: item.cart_item_total_price,
                product_sku: item.cart_item_product_sku,
                product_image: item.cart_item_product_image,
                product_id: item.cart_item_product_id,
                product_qty: item.cart_item_qty,
                condiments: '',
                modifiers: modifierdata,
                bakers_modifiers: '',
                menu_set_components: comboData,
                baby_pack: '',
                product_special_notes: item.cart_item_special_notes,
                voucher_gift_name:item.cart_item_product_voucher_gift_name, 
				voucher_gift_email: item.cart_item_product_voucher_gift_email, 
				voucher_gift_message : item.cart_item_product_voucher_gift_message, 
				order_item_id : (item.cart_voucher_order_item_id!='0'? item.cart_voucher_order_item_id: ""), 
				voucher_free_product: item.cart_item_voucher_product_free,
				order_voucher_id: item.cart_item_voucher_id
            })

            return products;

        }.bind(this));


        /* if  merge order date */
        var orderDate = '';
		
		var seletedOrdDate = this.state.seleted_ord_date;
		var seletedOrdTime = this.state.seleted_ord_time;
		
		if(seletedOrdDate !== '' && seletedOrdTime !=='') {
            var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
			var OrderHours = seletedOrdTime.getHours();
			var OrderMunts = seletedOrdTime.getMinutes();
			var OrderSecnd = seletedOrdTime.getSeconds();
			
			var orderTime = this.pad(OrderHours)+":"+this.pad(OrderMunts);
			
			orderDate = formatedDate + " " + orderTime;
		}

		if(orderDate === '' && paymentMode !== 'initial') {
			showAlert('Error', 'Please select order date and time.');
			window.$.magnificPopup.open({
				items: {
					src: '.alert_popup'
				},
				type: 'inline'
			});
			return false;
		}
		

		if(this.state.chk_tarms_contn !== "Yes" && this.state.termsAndConditions !== '' && paymentMode !== 'initial') {
			$(".tarms_chkbox_div").addClass('err_tarms_chk');
			window.scrollTo(0, 1000);
			return false;
		}
		
		if(paymentMode === 'initial') {
			paymentMode = 2;
		}
		
		/*orderDate = '2019-07-10 10:15';*/

        /* insert customner details */
        if (validation !== 'Yes') {
            var addressObject = {};
            var customerAddressId = 0;
            addressObject = {
                'app_id': appId,
                "customer_first_name": cookie.load('UserFname'),
                "customer_last_name": cookie.load('UserLname'),
                "customer_phone": cookie.load('UserMobile'),
                "customer_email": cookie.load('UserEmail'),
                "customer_address_line1": cookie.load('orderDeliveryAddress'),
                "customer_postal_code": cookie.load('orderPostalCode'),
                "customer_address_name": this.state.unitnumber1,
                "customer_address_name2": this.state.unitnumber2,
                "refrence": cookie.load('UserId'),
                "customer_status": 'A',
                "customer_order_status": 'order'
			}
			
			var header = {
				headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
			  }
			  
			  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
				header.headers.Auth = cookie.load('Token')
			  }

            if (cookie.load('defaultAvilablityId') === deliveryId) {
                axios.post(apiUrl + "customer/secondary_address_add", qs.stringify(addressObject), header).then(res => {
                    if (res.data.status === "ok") {
                        customerAddressId = res.data.insert_id;
                    }
                });

            }
        }
		
		var promotionApplied = this.state.promotion_applied;
		var promotionType = this.state.promotion_type;
		var promotionAmount = this.state.promotion_amount;
		var promotionSource = this.state.promotion_source;
		var promoIsDelivery = this.state.promoIs_delivery;
		
		var cartDetailsArr = this.state.cartDetails;
		var promoTionArr = Array();
			promoTionArr['promotionApplied'] = promotionApplied;
			promoTionArr['promotionAmount'] = promotionAmount;
			promoTionArr['promoIsDelivery'] = promoIsDelivery;
	    var calculatedAmount = getCalculatedAmount(this.state.globalSettings,this.props.zonedetails,cartDetailsArr,promoTionArr, this.state.delivery_self_collection);

		var BillingAddress = this.state.billing_delivery_address;
		var BillingPostalCode = this.state.billing_postcode;
		var BillingUnitNo1 = this.state.billunitnumber1;
		var BillingUnitNo2 = this.state.billunitnumber2;
		
		if(this.state.billing_addrs_sameas === 'yes') {
			BillingAddress 		= cookie.load('orderDeliveryAddress');
			BillingPostalCode 	= cookie.load('orderPostalCode');
			BillingUnitNo1 		= this.state.unitnumber1
			BillingUnitNo2 		= this.state.unitnumber2
		}
		
		var zoneIdTxt = (typeof cookie.load('orderZoneId') === 'undefined') ? '' : cookie.load('orderZoneId');

        var postObject = {};
        postObject = {

            /* cart details */
            'app_id': appId,
            'availability_id': cookie.load('defaultAvilablityId'),
            'sub_total': parseFloat(calculatedAmount['cartSubTotalAmount']).toFixed(2),
            "grand_total": parseFloat(calculatedAmount['grandTotalAmount']).toFixed(2),
            "outlet_id": cookie.load('orderOutletId'),
            "zone_id": zoneIdTxt,
            "table_number": '',
            "order_status": 1,
            "order_source": 'Web',
            "order_date": orderDate,
            "order_remarks": this.state.specialInstructions,
            "products": JSON.stringify(products),


            /* customer  Details */
            "customer_id": cookie.load('UserId'),
            "customer_address_id": customerAddressId,
            "customer_fname": cookie.load('UserFname'),
            "customer_lname": cookie.load('UserLname'),
            "customer_mobile_no": cookie.load('UserMobile'),
            "customer_email": cookie.load('UserEmail'),
            "customer_address_line1": cookie.load('orderDeliveryAddress'),
            "customer_address_line2": '',
            "customer_postal_code": cookie.load('orderPostalCode'),
            "customer_unit_no1": this.state.unitnumber1,
            "customer_unit_no2": this.state.unitnumber2,
			
			"billing_address_line1": BillingAddress,
			"billing_postal_code": BillingPostalCode,
			"billing_unit_no1": BillingUnitNo1,
			"billing_unit_no2": BillingUnitNo2,

            /* Payment */
            "payment_mode": paymentMode, /*paymentMode, */
            "payment_reference": stripeReference,
            "stripe_envir": 'test',
            "payment_type": payGetWayType,
            "order_capture_token": captureID,
            "order_payment_getway_type": '',
            "order_payment_getway_status": 'Yes',

            /* additional paras */
            "delivery_charge": parseFloat(calculatedAmount['deliveryCharge']).toFixed(2),
            "additional_delivery": parseFloat(calculatedAmount['additionalDelivery']).toFixed(2),
            "order_tat_time": (typeof cookie.load('orderTAT') === "undefined" ? '' : cookie.load('orderTAT')),
            "tax_charge": calculatedAmount['orderDisplayGst'],
            "order_tax_calculate_amount": parseFloat(calculatedAmount['orderGstAmount']).toFixed(2),
            "order_data_encode": 'Yes',
            
            "delivery_self_collection": this.state.delivery_self_collection,

            /* discount */
            "order_discount_applied": '',
            "order_discount_amount": '',
            "order_discount_type": '',

			"order_special_discount_amount": cartDetailsArr.cart_special_discount,
			"order_special_discount_type": cartDetailsArr.cart_special_discount_type,            

        }
        console.log(postObject); 
        /* check  validation */
        if (validation === 'Yes') {
            postObject['validation_only'] = "Yes";
        }


        /*  promo code object - start */
        if (promotionApplied === 'Yes' && parseFloat(promotionAmount)>0) {
		   
		      postObject['discount_applied'] = "Yes";
			  postObject['discount_amount'] = promotionAmount; 
			
			  if(promotionType === "promoCode") {
				var categoryIdsDet = this.getProductIdsDet(finalcartItems);
				
				postObject['coupon_applied'] = "Yes";
				postObject['promo_code'] = promotionSource;
				postObject['cart_quantity'] = cartDetailsArr.cart_total_items;
				postObject['category_id'] = categoryIdsDet;
				postObject['coupon_amount'] = promotionAmount;
				postObject['promotion_delivery_charge_applied'] = promoIsDelivery;

			  }
			
			  if(promotionType === "points") {
				
				postObject['redeem_applied'] = "Yes"; 
				postObject['redeem_point'] = promotionSource; 
				postObject['redeem_amount'] = promotionAmount;
			  }
			
		}
		
		postObject['reward_point_status'] = 'Yes';
		
		var header = {
			headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
		  }
		  
		  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
			header.headers.Auth = cookie.load('Token')
		  }
 //ordersnewv1 ordersv1
        axios.post(apiUrl + "ordersv1/submit_order", qs.stringify(postObject), header).then(res => {
            if (res.data.status === "ok") {
                this.setState({ orderValidFail : 0});
                if (validation !== 'Yes') {
                    var localOrderNo = res.data.common.local_order_no;
                    this.setState({ placingorderimage: tickImage });

                    /* capture payemnt */
                    if (payGetWayType === "Stripe") {
                        this.captureAmount(captureID, res.data.common.order_primary_id, localOrderNo);
                    }else if (payGetWayType === "Omise") {

                        this.captureOmiseAmount(captureID, res.data.common.order_primary_id, localOrderNo);
                    } else {
                        this.showSuccessPage(localOrderNo,res.data.common.order_primary_id);
                    }

                } else {
					if(Paymentpop === 'Yes'){

						window.$.magnificPopup.open({
							items: {
								src: '#pay-conf-popup'
							},
							type: 'inline'
						});
					}	

                    return true;
                }
				
            } else if (res.data.status === "error") {
                
                    // This is used for stripe Auth Capture 
                    this.setState({ orderValidFail : 1});

                    if (res.data.form_error !== undefined) {
                        showAlert('Error', res.data.form_error);
                    } else {
                        showAlert('Error', res.data.message);
                    }
					window.$.magnificPopup.open({
                        items: {
                            src: '.alert_popup'
                        },
                        type: 'inline'
                    });

            }
        }).catch((error) => {
console.log(error);
            window.$.magnificPopup.close();
            this.paymentFail('Error', "Error code: 1004 Oops! Unable to processing your order. Please try again.");
            window.$.magnificPopup.open({
                items: {
                    src: '.warining_popup'
                },
                type: 'inline'
            });
            return false;
        });
    }

    changeAddrrFun(){
		window.$.magnificPopup.open({
		  items: {
			src: '#warning-popup'
		  },
		  type: 'inline'
		});
		return false;
	}


	handleAddrChange(event) 
	{
		  if(event.target.name === 'unit_no1') {
			  this.setState({unitnumber1: event.target.value}, function () { this.setBillingAddrs(); }.bind(this));
		  } else if(event.target.name === 'unit_no2') {
			  this.setState({unitnumber2: event.target.value}, function () { this.setBillingAddrs(); }.bind(this));
		  } else if(event.target.name === 'specil_note') {
			  this.setState({order_specil_note: event.target.value});
		  } else if(event.target.name === 'reward_point') {
			  this.setState({reward_point_val: event.target.value});
		  } else if(event.target.name === 'promo_code') {
			  this.setState({promo_code_val: event.target.value});
		  } else if(event.target.name === 'bill_unit_no1') {
			  this.setState({billunitnumber1: event.target.value});
		  } else if(event.target.name === 'bill_unit_no2') {
			  this.setState({billunitnumber2: event.target.value});
		  }
	}


	changeBillingAddrChk() {
		var isSame = this.state.billing_addrs_sameas;
		if(isSame === 'yes') {
			var tempvlu = '';
			this.setState({billing_postcode: tempvlu, billing_delivery_address: tempvlu, billunitnumber1: tempvlu, billunitnumber2: tempvlu, billing_addrs_sameas: 'no'});
		} else {
			this.setState({billing_addrs_sameas: 'yes'}, function () { this.setBillingAddrs(); }.bind(this));
		}
  }

  setBillingAddrs() {
		var isSame = this.state.billing_addrs_sameas;
		if(isSame === 'yes') {
			var postalCode = this.state.order_postcode;
			var custAddress = this.state.order_delivery_address;
			var floorNo = this.state.unitnumber1;
			var unitNo = this.state.unitnumber2;
			this.setState({billing_postcode: postalCode, billing_delivery_address: custAddress, billunitnumber1: floorNo, billunitnumber2: unitNo});
		}
   }

   CheckBillingAddrChk(type) {
		var isSame = this.state.billing_addrs_sameas;
		var chkBox = false;
        var diplayTxt = 'block';
		if(isSame === 'yes') {
			chkBox = true;
            diplayTxt = 'none';
		}
		
		return (type === 'checkbox') ? chkBox : diplayTxt;
  }

    /* post code */
	changePostalCode(isFrm,event) 
	{
			var postalCode = event.target.value;
			var postCdLth = (postalCode !== '') ? postalCode.length : 0;
			
			if(postalCode !== '' && parseInt(postCdLth) === 6) {

				var header = {
					headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
				  }
				  
				  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
					header.headers.Auth = cookie.load('Token')
				  }
			
				var urlShringTxt = apiUrl + 'outlets/find_outlet?app_id=' + appId +'&availability_id='+ deliveryId+'&postal_code='+postalCode;
			
				axios.get(urlShringTxt, header)
			   .then(res => {
				   
					var custAddress = '';
				   if (res.data.status === "ok") {
					  var outltResulSet = res.data.result_set;
					  if(Object.keys(outltResulSet.postal_code_information).length > 0) {
						  
						  var outletAddress = (outltResulSet.postal_code_information.zip_buno != "") ? outltResulSet.postal_code_information.zip_buno + ", " + outltResulSet.postal_code_information.zip_sname : outltResulSet.postal_code_information.zip_sname;
						  
						  custAddress = outletAddress+" "+CountryTxt;
					  }
				   }
				   
				   this.setState({billing_postcode: postalCode, billing_delivery_address: custAddress});
				   
				   
				});	 
			
			} else {
				var temparrs = '';
				this.setState({billing_postcode: postalCode, billing_delivery_address: temparrs});
			}	
	}

	setdateTimeFlg = (field, value) => {
		if(field == 'tmflg') {
			  this.setState({getDateTimeFlg:value});
		}	
		else if(field == 'ordDate') {
			  var ordTime = '';
			  $('.ordrdatetime_error').html('');
			  this.setState({seleted_ord_date:value,seleted_ord_time:ordTime});
		}		
		else if(field == 'ordTime') {
			  $('.ordrdatetime_error').html('');
			  this.setState({seleted_ord_time:value});
		}
	} 
	
	updateOrderDateTimeCookie() {
	    var sltdOrdDate = this.state.seleted_ord_date;
		var sltdOrdTime = this.state.seleted_ord_time;
		var OrdDate  = format(sltdOrdDate, "yyyy-MM-dd");
		var OrdHours = sltdOrdTime.getHours();
		var OrdMunts = sltdOrdTime.getMinutes();
		var OrdSecnd = sltdOrdTime.getSeconds();
		var ordDateTime = new Date(OrdDate);
			ordDateTime.setHours(OrdHours);
			ordDateTime.setMinutes(OrdMunts);
			ordDateTime.setSeconds(OrdSecnd);
	
		var deliveryDate = format(sltdOrdDate, "dd/MM/yyyy");
		var deliveryTime = this.pad(OrdHours)+":"+this.pad(OrdMunts)+":"+this.pad(OrdSecnd);			
		
		cookie.save('orderDateTime', ordDateTime);
		cookie.save('deliveryDate', deliveryDate);
		cookie.save('deliveryTime', deliveryTime);
  }

  pad(d) {
	return (d < 10) ? '0' + d.toString() : d.toString();
  }

  loadCartOverallData() {
	  
	var cartDetailsArr = this.state.cartDetails;
	
	if (Object.keys(cartDetailsArr).length > 0) {
		
	$('#dvLoading').fadeOut(1000);	  
		
	var promoTionArr = Array();
		promoTionArr['promotionApplied'] = this.state.promotion_applied;
		promoTionArr['promotionAmount'] = this.state.promotion_amount;
		promoTionArr['promoIsDelivery'] = this.state.promoIs_delivery;
	var calculatedAmount = getCalculatedAmount(this.state.globalSettings,this.props.zonedetails,cartDetailsArr,promoTionArr,this.state.delivery_self_collection);
	
	return (<div className="cart_scrollarea">

				  <div className="hcart_tt">
					<div className="row-replace">
						<div className="col-sm-cls text-left">
							<h3>Your Items</h3>
						</div>
						<div className="col-sm-cls text-right">
						<a href="javascript:void(0)" onClick={this.clearCartItm.bind(this)} className="hclear_cart" title="CLEAR CART">CLEAR CART</a>
						</div>
					</div>
				</div>						

			 	<div className="cart_body pdt-cart-list">

				  {this.loadCartList()}
			  </div>
			  
			  <div className="cart_footer">
						 
						  <div className="cart_row">
									<div className="row">
										<div className="col-xs-8">
											<p className="text-uppercase">SUBTOTAL</p>
										</div>
										<div className="col-xs-4 text-right">
										<span>${calculatedAmount['cartSubTotalAmount']}</span>
										</div>
									</div>
							</div>


						  {(parseFloat(calculatedAmount['deliveryCharge'])>0) &&  <div className="cart_row">
						  <div className="row">
							  <div className="col-xs-8">
								  <p className="text-uppercase">Delivery</p>
							  </div>
							  <div className="col-xs-4 text-right">
							  <span>${parseFloat(calculatedAmount['deliveryCharge']).toFixed(2)}</span>
							  </div>
						  </div>
				 		 </div>
						  }

						  {(parseFloat(calculatedAmount['additionalDelivery'])>0) &&  
						  <div className="cart_row">
						  <div className="row">
							  <div className="col-xs-8">
							  <p className="text-uppercase">Additional Delivery</p>
							  </div>
							  <div className="col-xs-4 text-right">
							  <span>${parseFloat(calculatedAmount['additionalDelivery']).toFixed(2)}</span>
							  </div>
						  </div>
				 		 </div>
						  }
						  {(parseFloat(calculatedAmount['promotionAmount'])>0) && 
						  
						  
						  <div className="cart_row">
						  <div className="row">
							  <div className="col-xs-8">
							  <p className="text-uppercase">Discount</p>
							  </div>
							  <div className="col-xs-4 text-right">
							  <span>(${parseFloat(calculatedAmount['promotionAmount']).toFixed(2)})</span>
							  </div>
						  </div>
						  <a href="javascript:void(0)" onClick={this.removePointsAndPromo.bind(this)} className="cart_remove"></a>
				 		 </div>

						  
						}
						  {(parseFloat(calculatedAmount['orderGstAmount'])>0) && 
						  <div className="cart_row">
						  <div className="row">
							  <div className="col-xs-8">
							  <p className="text-uppercase">GST ({calculatedAmount['orderDisplayGst']}%)</p>
							  </div>
							  <div className="col-xs-4 text-right">
							  <span>${parseFloat(calculatedAmount['orderGstAmount']).toFixed(2)}</span>
							  </div>
						  </div>
				 		 </div>
						  }
						  
						   {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
						<div className="cart_row gst-row">
						  <div className="row-replace">
							<div className="col-sm-cls text-left">
							  <p className="text-uppercase">
								Voucher Discount Amount 
							  </p>
							</div>
							<div className="col-sm-cls text-right">
							  <span>
								$
								{parseFloat(calculatedAmount["voucherDiscountAmount"]).toFixed(
								  2
								)}
							  </span>
							</div>
						  </div>
						</div>
					  )}

						<div className="cart_row cart_footer_totrow">
							<div className="row">
								<div className="col-xs-8">
									<p className="text-uppercase">Total</p>
								</div>
								<div className="col-xs-4 text-right">
								<span>${calculatedAmount['grandTotalAmount']}</span>
								</div>
							</div>
							{parseFloat(cartDetailsArr.cart_special_discount) > 0 && 
							  
							  <div className="member-discount-total">* {cartDetailsArr.cart_special_discount_type} ${cartDetailsArr.cart_special_discount} Applied</div>
							  
							  }
						</div>

						  {(cookie.load("defaultAvilablityId") === deliveryId) && this.loadDeliveryPercentageBar()}
						  
				  </div>
				  
			</div>);
	} else {
		return '';
	}
	
}

/* this  function used to load delivery percentage */
loadDeliveryPercentageBar() {
	  var freeDeliveryAmnt = 0;
	  var DeliveryAmnt = 0;
	  var remainAmnt = 0;
	  var delPercentage = 0;
	  
	  var cartDetailsArr = this.state.cartDetails;
	
	  if (Object.keys(cartDetailsArr).length > 0) {
	  
		  var promoTionArr = Array();
			  promoTionArr['promotionApplied'] = this.state.promotion_applied;
			  promoTionArr['promotionAmount'] = this.state.promotion_amount;
			  promoTionArr['promoIsDelivery'] = this.state.promoIs_delivery;
		  
		  var calculatedAmount = getCalculatedAmount(this.state.globalSettings,this.props.zonedetails,cartDetailsArr,promoTionArr, this.state.delivery_self_collection);
		  
			  freeDeliveryAmnt = parseFloat(calculatedAmount['freeDeliveryAmnt']);
			  DeliveryAmnt = parseFloat(calculatedAmount['deliveryCharge']);
		  var subTotal = parseFloat(calculatedAmount['cartSubTotalAmount']);
		  var percentage = (subTotal * 100) / freeDeliveryAmnt;
			  percentage = Math.round(percentage);
			  delPercentage = percentage;
			  remainAmnt = parseFloat((freeDeliveryAmnt - subTotal)).toFixed(2);
	  }

	  if(DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {

		return (
			<div className="cart_row min-delivery">
			<div className="indication">
				<p className="help-block">${remainAmnt} more to free delivery</p>
				<div className="indication_progress">
					<span className="progress_bar" style={{ width: delPercentage + "%" }} ><span class="progress_indi"></span></span>
				</div>
			</div>
		</div>
		);
	  }

}

loadCartList() {
	
	  var cartItemsArr = this.state.cartItems;
	  if (Object.keys(cartItemsArr).length > 0) {
		  return cartItemsArr.map((product, index) =>
					 
					 <div className="cart_row product-details" id={'rowcartid-'+product.cart_item_id} key={index}>
						  <div className="row">
							  <div className="col-xs-8 cart_left">
								  <div className="cart_img">
									  <a href="javascript:void(0)" title="">
									  {(product.cart_item_product_image !== '')?<img src={product.cart_item_product_image} width="110" height="67" alt="" />:<img src={productImg} width="110" height="67" alt="" />}
									  </a>
								  </div>
								  <div className="cart_info">
									  <h4>{stripslashes(product.cart_item_product_name)}</h4>

									  <h4>{product.cart_item_voucher_id !== "" &&
									  product.cart_item_voucher_id != null
										? " Discount Applied"
										: ""}
									 </h4>

									  {this.loadModifierItems(product.cart_item_type, product.modifiers, product.set_menu_component)}

									  {product.cart_item_special_notes !== '' && <p className="help-block">{stripslashes(product.cart_item_special_notes)}</p>}

									  {parseFloat(product.cart_item_promotion_discount) > 0 &&

									  <span className="member-discount-desc">$ {product.cart_item_promotion_discount} {product.cart_item_promotion_type} discount Applied</span>
									  }

								  </div>
							  </div>
							  <div className="col-xs-4 cart_right text-right">
							  <div className="cart_price"><p>${product.cart_item_total_price}</p></div>

								   {(product.cart_item_voucher_product_free != 1 ) ?
								  <div className="qty_bx">
									  <span className="qty_minus" onClick={this.updateCartQty.bind(this, product, 'decr')}>-</span>
									  <input type="text" value={product.cart_item_qty} readOnly />
									  <span className="qty_plus" onClick={this.updateCartQty.bind(this, product, 'incr')}>+</span>
								  </div>:
								  <div className="qty_bx free_product">
										<span className="qty_minus">  - </span>
										<input type="text" value={product.cart_item_qty} readOnly />
										<span className="qty_plus"> +</span>
									  </div>
								  }			  
							  </div>
						  </div>
						  <a href="javascript:void(0)" onClick={this.deleteCartItm.bind(this, product)} className="cart_remove"></a>
					  </div>);
	  }
	
 }

 updateCartQty(itemArr, type) {
	  var productId = itemArr.cart_item_product_id;
	  var cartItemId = itemArr.cart_item_id;
	  var cartQty = itemArr.cart_item_qty;
	  var totalItmCount = this.props.cartTotalItmCount;
	   var orderVoucherId = itemArr.cart_voucher_order_item_id;
	  
	  showLoader('rowcartid-'+cartItemId,'Idtext');
	  
	  if(type === 'decr') {
		  cartQty = parseInt(cartQty) - 1;
		  if(parseInt(totalItmCount) === 0) {
			  
		  } else if(parseInt(cartQty) === 0) {
			  this.props.deleteCartDetail(cartItemId);
		  } else {
			  this.props.updateCartDetail(productId,cartItemId,cartQty,orderVoucherId);
		  }
	  } else {
		  cartQty = parseInt(cartQty) + 1;
		  this.props.updateCartDetail(productId,cartItemId,cartQty,orderVoucherId);
	  }
	  this.setState({ startMsg: 1 });
	  this.removePointsAndPromo();
  }

  deleteCartItm(itemArr) {
	  var cartItemId = itemArr.cart_item_id;
	  showLoader('rowcartid-'+cartItemId,'Idtext');
	  this.props.deleteCartDetail(cartItemId);
	  this.removePointsAndPromo();
  }
  
  clearCartItm() {
	  showLoader('cart_body','class');
	  this.props.destroyCartDetail();
  }

/* this function used to load modifer items */
loadModifierItems(itemType, modifiers, combo) {
  var len = modifiers.length;
  var comboLen = combo.length;
  var html = '<div class="cart_extrainfo">';

  var temp_html = '';

  if (itemType === "Modifier" && len > 0) {
	for (var i = 0, length = len; i < length; i++) {
	  var modName = modifiers[i]['cart_modifier_name'];
	  var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name'];
	  var modVlPrice = modifiers[i]['modifiers_values'][0]['cart_modifier_price'];
	  var newModVlPrice = (modVlPrice > 0) ? " (+ $" + modVlPrice + ")" : "";
	  temp_html += "<p><b>" + modName + ":</b></p> <p>" + modval + newModVlPrice + "</b></p> ";
	}
	
	html += temp_html+"</div>";
	var reactElement = Parser(html);
	return reactElement;

  } else if (itemType === "Component" && comboLen > 0) {
	for (var i = 0, length = comboLen; i < length; i++) {
	  var comboName = combo[i]['menu_component_name'];
	  var comboVal = this.showComboProducts(combo[i]['product_details']);

	  if (combo[i]['product_details'][0]['modifiers'].length) {

		html += "<p><b>" + comboName + ":</b> </p><p>" + comboVal + "  " + this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) + "</p> ";

	  } else {
		html += "<p><b>" + comboName + ":</b> </p><p>" + comboVal + " " + this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) + "</p> ";
	  }
	}
	html += "</div>";
	var reactElement = Parser(html);
	return reactElement;

  }
}

/* show combo products  list */
  showComboProducts(combos) {
	  var lenCombo = combos.length;
	  var html = " ";
	  if (lenCombo > 0) {
		for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
		 if(parseInt(combos[r]['cart_menu_component_product_qty']) > 0) {	  
		  var comboPro = combos[r]['cart_menu_component_product_name'];
		  var comboQty = combos[r]['cart_menu_component_product_qty'];
		  var comboPrice = combos[r]['cart_menu_component_product_price'];
		  var newPrice = (comboPrice > 0) ? " (+ $" + comboPrice + ")" : "";
		  html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
		 }
		}
		return html;
	  }
	  return "";
  }
  
 /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
	  var lenMod = modifiers.length;
	  var html = '<div >';
	  if (lenMod > 0) {
		for (var i = 0, length = lenMod; i < length; i++) {
		  var modName = modifiers[i]['cart_modifier_name'];
		  var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name'];
		  var modValPrice = modifiers[i]['modifiers_values'][0]['cart_modifier_price'];
		  var newModValPrice = (modValPrice > 0) ? " (+ $" + modValPrice + ")" : "";
		  html += "<p><b>" + modName + ":</b> </p><p> " + modval + newModValPrice + "</p> ";
		}
		html += '</div>';

		return html;

	  }

	  return "";
  }
  

  applyPointsAndPromo(type) {
		
	var promotionApplied = this.state.promotion_applied;
	var promotionType = this.state.promotion_type;
	var promotionAmount = this.state.promotion_amount;
	var promotionSource = this.state.promotion_source;
	var promoIsDelivery = this.state.promoIs_delivery;
	var reedemPointVal = this.state.reward_point_val;
	var promoCodeVal = this.state.promo_code_val;
	var usedPoints = this.state.used_reward_point;
	
	var cartDetailsArr = this.state.cartDetails;
	var cartItemsSateArr = this.state.cartItems;
	
	var subTotal = (Object.keys(cartDetailsArr).length > 0)?cartDetailsArr.cart_sub_total:0;
	var totalItems = (Object.keys(cartDetailsArr).length > 0)?cartDetailsArr.cart_total_items:0;

	var company_otp_verification = this.state.otp_verification_enable;
	var company_otp_via = this.state.otp_verification_via;
	
	if(type === 'points') {
		
		showLoader('applypoints_cls','class');
		
		var postObject = {
			"app_id": appId,
			"reference_id": cookie.load('UserId'),
			"redeem_point": reedemPointVal,
			"cart_amount": subTotal
		};

		cookie.save('otpver_reedemPointVal','');
		cookie.save('otpver_promoCodeVal','');
		cookie.save('otpver_promotionApplied','');
		cookie.save('otpver_promotionType','');
		cookie.save('otpver_promotionAmount',0);
		cookie.save('otpver_promotionSource','');
		cookie.save('otpver_promoIsDelivery','');
		cookie.save('otpver_usedPoints','');

		var header = {
			headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
		  }
		  
		  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
			header.headers.Auth = cookie.load('Token')
		  }

		axios.post(apiUrl + "loyalty/apply_loyalityv1", qs.stringify(postObject), header).then(res => {
			if (res.data.status === "success") {
				

				if(company_otp_verification === '1' && company_otp_via > 0){

					var postOTPObject = {
						"app_id": appId,
						"customer_first_name" : cookie.load('UserFname'),
						"customer_last_name": cookie.load('UserLname'),
						"customer_email" : cookie.load('UserEmail'),
						"customer_phone" : cookie.load('UserMobile'),
						"customer_otp_type": company_otp_via,
						"verify_type":"redeempoints"
					};

					var header = {
						headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
					  }
					  
					  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
						header.headers.Auth = cookie.load('Token')
					  }
	
					axios.post(apiUrl+"customer/send_customer_otp", qs.stringify(postOTPObject), header)
					.then(response => {
						hideLoader('applypoints_cls','class');
						if(response.data.status==="ok"){
							document.getElementById("redeem_points_option").style.display = "none";
							document.getElementById("show_redeem_otp").style.display = "block";
							$("#redeem_points_otp").focus();
							if(company_otp_via === "1"){
								this.handleShowAlertFun('Success',response.data.message);
							}else{
								this.handleShowAlertFun('Success','OTP has been sent to your mobile number.');
							}

							var pointDet = res.data.result_set;
							promotionApplied = 'Yes';
							promotionType = 'points';
							promotionAmount = pointDet.points_amount;
							promotionSource = reedemPointVal;
							promoIsDelivery = '';
							promoCodeVal = '';
							usedPoints = reedemPointVal;

							cookie.save('otpver_reedemPointVal',reedemPointVal);
							cookie.save('otpver_promoCodeVal',promoCodeVal);
							cookie.save('otpver_promotionApplied',promotionApplied);
							cookie.save('otpver_promotionType',promotionType);
							cookie.save('otpver_promotionAmount',promotionAmount);
							cookie.save('otpver_promotionSource',promotionSource);
							cookie.save('otpver_promoIsDelivery',promoIsDelivery);
							cookie.save('otpver_usedPoints',usedPoints);

						} else if(response.data.status==="error"){
							this.handleShowAlertFun('Error',response.data.message);
						} else{
							this.handleShowAlertFun('Error','Sorry! Otp verification is somethig wrong.');
						}
					});

				} else {

					var pointDet = res.data.result_set;
					promotionApplied = 'Yes';
					promotionType = 'points';
					promotionAmount = pointDet.points_amount;
					promotionSource = reedemPointVal;
					promoIsDelivery = '';
					promoCodeVal = '';
					usedPoints = reedemPointVal;

					showCustomAlert("success", "Rewards Points applied successfully");

					this.handleShowAlertFun('Success','Rewards Points applied successfully');

					hideLoader('applypoints_cls','class');
			
					cookie.save('reedemPointVal',reedemPointVal);
					cookie.save('promoCodeVal',promoCodeVal);
					cookie.save('promotionApplied',promotionApplied);
					cookie.save('promotionType',promotionType);
					cookie.save('promotionAmount',promotionAmount);
					cookie.save('promotionSource',promotionSource);
					cookie.save('promoIsDelivery',promoIsDelivery);
					cookie.save('usedPoints',usedPoints);
					cookie.save('redeem_point_option',1);
					
					this.setState({reward_point_val: reedemPointVal,promo_code_val: promoCodeVal,promotion_applied: promotionApplied,promotion_type: promotionType,promotion_amount: promotionAmount,promotion_source: promotionSource,promoIs_delivery: promoIsDelivery,used_reward_point: usedPoints});

				}

			} else {
				if(promotionType === 'points') {
					promotionApplied = '';
					promotionType = '';
					promotionAmount = '';
					promotionSource = '';
					promoIsDelivery = '';
					usedPoints = 0;
				}

				showCustomAlert('success',(res.data.form_error !== undefined)?res.data.form_error:res.data.message);


				hideLoader('applypoints_cls','class');

				cookie.save('reedemPointVal',reedemPointVal);
				cookie.save('promoCodeVal',promoCodeVal);
				cookie.save('promotionApplied',promotionApplied);
				cookie.save('promotionType',promotionType);
				cookie.save('promotionAmount',promotionAmount);
				cookie.save('promotionSource',promotionSource);
				cookie.save('promoIsDelivery',promoIsDelivery);
				cookie.save('usedPoints',usedPoints);

				this.setState({reward_point_val: reedemPointVal,promo_code_val: promoCodeVal,promotion_applied: promotionApplied,promotion_type: promotionType,promotion_amount: promotionAmount,promotion_source: promotionSource,promoIs_delivery: promoIsDelivery,used_reward_point: usedPoints});
			}

		});
		
	} else if(type === 'promoCode') {
		
		showLoader('applypromo_cls','class');
		
		var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);
		var avilablityId = cookie.load('defaultAvilablityId');
		
		var postObject = {
			"app_id": appId,
			"reference_id": cookie.load('UserId'),
			"promo_code": promoCodeVal,
			"cart_amount": subTotal,
			"cart_quantity": totalItems,
			"category_id": categoryIdsDet,
			"availability_id": avilablityId,
			"outlet_id": cookie.load('orderOutletId')
		};

		var header = {
			headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
		  }
		  
		  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
			header.headers.Auth = cookie.load('Token')
		  }
		
		axios.post(apiUrl + "promotion_api_v2/apply_promotion", qs.stringify(postObject), header).then(res => {
			
			if (res.data.status === "success") {
				var pointDet = res.data.result_set;
				var IsDelivery = (pointDet.promotion_delivery_charge_applied == 'Yes') ? 'Yes' : 'No';
				promotionApplied = 'Yes';
				promotionType = 'promoCode';
				promotionAmount = pointDet.promotion_amount;
				promotionSource = promoCodeVal;
				promoIsDelivery = IsDelivery;
				reedemPointVal = '';
				usedPoints = 0;
				showCustomAlert('success','Promotion applied successfully');
			} else {
				var msgTxt = (res.data.message !== '') ? res.data.message : 'Sorry! Did not applied promo code';
				if(promotionType === 'promoCode') {
					promotionApplied = '';
					promotionType = '';
					promotionAmount = '';
					promotionSource = '';
					promoIsDelivery = '';
				}
				showCustomAlert('error',msgTxt);
			}
			
			hideLoader('applypromo_cls','class');
			
			cookie.save('reedemPointVal',reedemPointVal);
			cookie.save('promoCodeVal',promoCodeVal);
			cookie.save('promotionApplied',promotionApplied);
			cookie.save('promotionType',promotionType);
			cookie.save('promotionAmount',promotionAmount);
			cookie.save('promotionSource',promotionSource);
			cookie.save('promoIsDelivery',promoIsDelivery);
			cookie.save('usedPoints',usedPoints);
			
			this.setState({reward_point_val: reedemPointVal,promo_code_val: promoCodeVal,promotion_applied: promotionApplied,promotion_type: promotionType,promotion_amount: promotionAmount,promotion_source: promotionSource,promoIs_delivery: promoIsDelivery,used_reward_point: usedPoints});
		});
	}
}



subbmitpromotionotp(){

	var otpObject = {};
	var otp_value = $("#redeem_points_otp").val();	

	if(otp_value != ''){

		showLoader('applyotp_cls','class');

		var qs = require('qs');
		otpObject = {"app_id": appId,"customer_otp_val" : otp_value,"customer_email_id":cookie.load('UserEmail'),"verify_type":"redeempoints"}
		
		var header = {
			headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
		  }
		  
		  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
			header.headers.Auth = cookie.load('Token')
		  }

		axios.post(apiUrl+"customer/customer_otp_verification", qs.stringify(otpObject), header).then(response => { 
			hideLoader('applyotp_cls','class'); 
			if(response.data.status==="ok"){
				this.otp_redeem_points();
				$("#redeem_points_otp").val('');
			} else {

				this.handleShowAlertFun('Error',response.data.message);	
			} 
		});

	}else{
		$("#redeem_points_otp").val('');
		this.handleShowAlertFun('Error',"Enter valid OTP.");
		return false;

	}

  }

  otp_redeem_points(){

	this.setState({reward_point_val: cookie.load('otpver_reedemPointVal'),promo_code_val: cookie.load('otpver_promoCodeVal'),promotion_applied: cookie.load('otpver_promotionApplied'),promotion_type: cookie.load('otpver_promotionType'),promotion_amount: cookie.load('otpver_promotionAmount'),promotion_source: cookie.load('otpver_promotionSource'),promoIs_delivery: cookie.load('otpver_promoIsDelivery'),used_reward_point: cookie.load('otpver_usedPoints')});
	cookie.save('redeem_point_option',1);

	cookie.save('reedemPointVal',cookie.load('otpver_reedemPointVal'));
	cookie.save('promoCodeVal',cookie.load('otpver_promoCodeVal'));
	cookie.save('promotionApplied',cookie.load('otpver_promotionApplied'));
	cookie.save('promotionType',cookie.load('otpver_promotionType'));
	cookie.save('promotionAmount',cookie.load('otpver_promotionAmount'));
	cookie.save('promotionSource',cookie.load('otpver_promotionSource'));
	cookie.save('promoIsDelivery',cookie.load('otpver_promoIsDelivery'));
	cookie.save('usedPoints',cookie.load('otpver_usedPoints'));

	document.getElementById("redeem_points_option").style.display = "block";
	document.getElementById("show_redeem_otp").style.display = "none";

	this.handleShowAlertFun('Success','Rewards Points applied successfully');


/*	window.location.reload();*/

  }

  handleResendotp(){

	showLoader('applyotp_cls','class');

	var otp_limit = this.state.customer_otp_limit+1;
	var qs = require('qs');
	var postObject = {"app_id": appId,"customer_email" : cookie.load('UserEmail'),"customer_mobile":cookie.load('UserMobile'),"customer_first_name":cookie.load('UserFname'),"customer_last_name":cookie.load('UserLname'),"verify_type":"redeempoints"};
	
	if(otp_limit > 1){
		document.getElementById("resend_otp_button").style.display = "none";
	}else{

		var header = {
			headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
		  }
		  
		  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
			header.headers.Auth = cookie.load('Token')
		  }

		axios.post(apiUrl+"customer/resend_otp", qs.stringify(postObject), header).then(response => {
			hideLoader('applyotp_cls','class');
			if(response.data.status==="ok"){
				this.setState({customer_otp_limit:otp_limit});
				this.handleShowAlertFun('Success',response.data.message);
				document.getElementById("show_redeem_otp").style.display = "block";
			} else {
				this.handleShowAlertFun('Error',response.data.message);
			} 
		});

	}
	  
  }

  cancelRedeemOtp(){
	document.getElementById("redeem_points_option").style.display = "block";
	document.getElementById("show_redeem_otp").style.display = "none";
  }



	removePointsAndPromo() {

	var paymentmode = this.state.paymentmodevalue;
	if(paymentmode === 'promotion') {
		var settingsArr = this.state.globalSettings;
		var paymentmode = 'Stripe';
		if(Object.keys(settingsArr).length > 0) {
			if(settingsArr.client_cod_enable == 1 && settingsArr.client_cod_availability == 1) {
				paymentmode = 'Cash';
			}
		}
	}

	removePromoCkValue();
	
	this.setState({reward_point_val: '',promo_code_val: '',promotion_applied: '',promotion_type: '',promotion_amount: '',promotion_source: '',promoIs_delivery: '',used_reward_point: 0,paymentmodevalue:paymentmode});

	}

	choosePayment(paymentMode) {

        if (paymentMode === 'Cash') {
            this.setState({ paymentmodevalue: "Cash" });
        } else if (paymentMode === 'Stripe') {
            this.setState({ paymentmodevalue: "Stripe" });
        }else if (paymentMode === 'Omise') {
            this.setState({ paymentmodevalue: "Omise" });
        } else {
            this.setState({ paymentmodevalue: "promotion" });
        }
		
		this.postOrder(2,'Yes');
	}

	/* stripe description  */
	stripeDescription() {
        //userName
        if (typeof cookie.load('UserLname') !== 'undefined' && cookie.load('UserLname') !== "") {
            return cookie.load('UserFname') + " " + cookie.load('UserLname');
        } else {
            return cookie.load('UserLname');
        }
    }

  /* post stripe account */
    onToken = (token) => {
		window.$.magnificPopup.open({
            items: {
                src: '.processing'
            },
            type: 'inline',
			midClick: true,
			closeOnBgClick: false
        });

		var promoTionArr = Array();
			promoTionArr['promotionApplied'] = this.state.promotion_applied;
			promoTionArr['promotionAmount'] = this.state.promotion_amount;
			promoTionArr['promoIsDelivery'] = this.state.promoIs_delivery;
	    var calculatedAmount = getCalculatedAmount(this.state.globalSettings,this.props.zonedetails,this.state.cartDetails,promoTionArr, this.state.delivery_self_collection);
		
		var payAmount = parseFloat(calculatedAmount['grandTotalAmount']).toFixed(2);
		
        /*  load process html */
        var postObject = {};
        postObject = {
            'app_id': appId,
            'token': token.id,
            'stripe_envir': this.state.globalSettings.stripe_envir,
            'stripe_key': this.state.globalSettings.stripe_public_key,
            "customer_id": cookie.load('UserId'),
            "paid_amount": payAmount,
            "outlet_name": (typeof cookie.load('orderOutletName') === 'undefined' ? cookie.load('orderOutletName') : ''),
            "payment_reference": stripeReference
        }

        this.postOrder(2,'Yes'); // To validate the order 
        
        if(this.state.orderValidFail === 0 ){

			var header = {
				headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
			  }
			  
			  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
				header.headers.Auth = cookie.load('Token')
			  }

            axios.post(apiUrl + "payment/stripeTokenPay", qs.stringify(postObject), header).then(res => {
                if (res.data.status === "ok") {
                    var captureID = res.data.result_set.payment_reference_1;
                    this.setState({ validateimage: tickImage });
                    this.postOrder(3, '', captureID, 'Stripe');
                }
                else if (res.data.status === "error") {
                    window.$.magnificPopup.close();
                    this.paymentFail('Error', "Error code: 1001 Oops! Something went wrong! Please try again.");
                   window.$.magnificPopup.open({
                        items: {
                            src: '.warining_popup'
                        },
                        type: 'inline'
                    });
                    return false;
    
                }
            }).catch((error) => {
                window.$.magnificPopup.close();
                this.paymentFail('Error', "Error code: 1002 Oops! Unable to connect to server. Please try again.");
				window.$.magnificPopup.open({
                    items: {
                        src: '.warining_popup'
                    },
                    type: 'inline'
                });
                return false;
            });
        }else{
			window.$.magnificPopup.close();
            this.paymentFail('Error', "Error code:1003 Oops! Something went wrong! Please try again." );
            window.$.magnificPopup.open({
                items: {
                    src: '.warining_popup'
                },
                type: 'inline'
            });

            return false;


        }
  }

	payCash() {

        window.$.magnificPopup.open({
            items: {
                src: '.processing'
            },
            type: 'inline',
			showCloseBtn: false,
			midClick: true,
			closeOnBgClick: false 
        });
	
        this.postOrder(1);

    }
	
	postPromotionOrder() {

        window.$.magnificPopup.open({
            items: {
                src: '.processing'
            },
            type: 'inline',
			showCloseBtn: false,
			midClick: true,
			closeOnBgClick: false 
        });
        this.postOrder(4);

	}
	
	orderPayValidaion(){
		this.postOrder(2,'Yes','','','Yes');
  }

	 /* show online payment mode loading */
	 onlinePaymentLoading() {
		
		var promoTionArr = Array();
			promoTionArr['promotionApplied'] = this.state.promotion_applied;
			promoTionArr['promotionAmount'] = this.state.promotion_amount;
			promoTionArr['promoIsDelivery'] = this.state.promoIs_delivery;
	    var calculatedAmount = getCalculatedAmount(this.state.globalSettings,this.props.zonedetails,this.state.cartDetails,promoTionArr,this.state.delivery_self_collection);
		var grandTotal = parseFloat(calculatedAmount['grandTotalAmount']);
		
        if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {

            return (<div className="process_col">
                <div className="process_left">
                    <img src={this.state.validateimage} />
                </div>
                <div className="process_right">
                    <h5>Processing</h5>
                    <p>Validating card information.</p>
                </div>
            </div>);
        }
    }
    /**/
	
	/* show online payment mode loading */
    orderBCLoading() {

        return (<div className="process_col">
            <div className="process_left">
                <img src={this.state.placingorderimage} />
            </div>
            <div className="process_right">
                <h5>Processing</h5>
                <p>Placing your order now.</p>
            </div>
        </div>);

    }
    /**/

    /* show online payment mode loading */
    amountCaptureLoading() {
		
		var promoTionArr = Array();
			promoTionArr['promotionApplied'] = this.state.promotion_applied;
			promoTionArr['promotionAmount'] = this.state.promotion_amount;
			promoTionArr['promoIsDelivery'] = this.state.promoIs_delivery;
	    var calculatedAmount = getCalculatedAmount(this.state.globalSettings,this.props.zonedetails,this.state.cartDetails,promoTionArr,this.state.delivery_self_collection);
		var grandTotal = parseFloat(calculatedAmount['grandTotalAmount']);
		
        if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {

            return (<div className="process_col">
                <div className="process_left">
                    <img src={this.state.completingpaymentimage} />
                </div>
                <div className="process_right">
                    <h5>Processing</h5>
                    <p>Completing your online payment.</p>
                </div>
            </div>);
        }
    }
	/**/
	pageReload = () => {
        setTimeout(function(){
            window.location.reload();
        },15000);
        
    }

    paymentFail(header,message){
        $(".warining_popup").remove();
		$('body').append('<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">'+header+'</div><div class="alert_body"><p>'+message+'</p><div class="alt_btns"><a href="javascript:void(0);" onClick="'+this.pageReload()+'"class="btn btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>');

	}

	/* Capture amount */
    captureAmount(captureID, orderPrimaryId, localOrderNo) {
        var cabtureObjects = {};
        var captureParams = captureID + "~" + orderPrimaryId + "~" + localOrderNo;
        cabtureObjects = { payment_reference: stripeReference, stripe_envir: this.state.globalSettings.stripe_envir, "customer_id": cookie.load('UserId'), 'app_id': appId, 'token': captureID, 'order_id': orderPrimaryId };
		var header = {
			headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
		  }
		  
		  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
			header.headers.Auth = cookie.load('Token')
		  }
		axios.post(apiUrl + "payment/captureAmount", qs.stringify(cabtureObjects), header).then(captureRes => {
            if (captureRes.data.status === "ok") {
                this.setState({ completingpaymentimage: tickImage });

                this.showSuccessPage(localOrderNo,orderPrimaryId);

            }
            else if (captureRes.data.status === "error") {
                showStripePopup('Error', 'Your order was not successful.');
                $.magnificPopup.open({
                    items: {
                        src: '.alert_popup'
                    },
                    type: 'inline'
                });



            } else {
                showStripePopup('Error', 'Your order was not successful.');
                $.magnificPopup.open({
                    items: {
                        src: '.alert_popup'
                    },
                    type: 'inline'
                });
                



            }
        }).catch(error => {



        });

    }
	/* sucess page */
    showSuccessPage(localOrderNo) {
        this.props.destroyCartDetail();
        this.deleteOrderCookie();
		cookie.save("ChkOrderid", localOrderNo);
		const{history} = this.props;
        setTimeout(function () {
            window.$.magnificPopup.close();
            history.push("/thankyou/" + localOrderNo);
        }, 450);
	}
	
	/* this function used to  delete all cookie values */
    deleteOrderCookie() {

		removePromoCkValue();
		
        cookie.remove('orderPaymentMode');       
        cookie.remove('orderTableNo');
        cookie.remove('product_remarks');
        cookie.remove('orderOutletName');
        //cookie.remove('orderOutletId');
        cookie.remove('carttotalitems');
        cookie.remove('cartsubtotal');
        cookie.remove('cartid');

        /* Delivery avilablity */
        cookie.remove('deliveryDate');
        cookie.remove('deliveryTime');
        cookie.remove('unitNoOne');
        cookie.remove('unitNoTwo');
        cookie.remove('firstNavigation');

        cookie.remove('promotion_id');
        cookie.remove('promotion_applied');
        cookie.remove('promotion_code');
        cookie.remove('promotion_delivery_charge_applied');
        cookie.remove('promotion_amount');
        cookie.remove('promotion_category');
        cookie.remove('prmo_type');

        /*Remove voucher*/
        cookie.remove('voucher_applied');
        cookie.remove('voucher_code');
        cookie.remove('voucher_amount');

        cookie.remove('points_redeemed');
        cookie.remove('points_used');
        cookie.remove('points_amount');
        cookie.remove('prmo_type');
    }
	
    /* Validate Float Value */
	validateFloatval(e) {
		const re = /[0-9.]+/g;
		if (!re.test(e.key)) {
		  e.preventDefault();
		}
  }

  /* Validate Int Value */
  validateIntval(e) {
		const re = /[0-9]+/g;
		if (!re.test(e.key)) {
		  e.preventDefault();
		}
  }	

  getProductIdsDet(cartItems) {
	var product_cartid = "";
	if(Object.keys(cartItems).length > 0) {
		for (var key in cartItems) {
			if(product_cartid !=='' && cartItems[key].cart_item_product_id !=='')
			{
				product_cartid+=";";
			}
			product_cartid +=cartItems[key].cart_item_product_id+"|"+cartItems[key].cart_item_total_price+"|"+cartItems[key].cart_item_qty;
		}
	}
	
	return product_cartid;
}


  /* Addon product Listing */
  addonProductListing() {
	var addonProductlst = this.props.addonproductlist;
	var slugType = this.state.selectedSlugType;
	var slugValue = this.state.selectedSlugValue;
	if (Object.keys(addonProductlst).length > 0) {
	if (Object.keys(addonProductlst[0].subcategorie).length > 0) {
		var addonProlstOnly = addonProductlst[0].subcategorie[0].products;
		var addonCommonImg = this.props.addonproductcommon.product_image_source;
		const Phtml = addonProlstOnly.map((product, index) =>

			  <div className="check-pg-slide addon-list-slider" key={index}>
				  <div className="innerproduct-col" id={"proIndex-"+product.product_primary_id}>
					<div className="innerproduct-item">
					
						<div className="innerproduct-item-image"> 
						{callImage(addonCommonImg, product.product_thumbnail, 800, 803, timThumpUrl)}
						</div>
						
						<div className="innerproduct-item-text">
						<h4>{stripslashes(product.product_alias !== '' ? product.product_alias : product.product_name)}</h4>
							{/*<div className="bakery-review">
							<span className="fa fa-star wppc-checked"></span>
							<span className="fa fa-star wppc-checked"></span>
							<span className="fa fa-star wppc-checked"></span>
							<span className="fa fa-star"></span>
							<span className="fa fa-star"></span>
							</div>*/}
						{/*<p>{(product.product_short_description !== '') ? stripslashes(product.product_short_description) : ''}</p>
						<h5><span className="price_none"></span>${product.product_price}</h5>*/}
					</div>

						<div className="innerproduct-button">
						
						{(product.product_stock > 0 || product.product_stock === null)?((product.product_type === '1')?<a className="btn btn_yelow btn_minwid smiple_product_lk" href="javascript:void(0);" onClick={this.addToCartSimple.bind(this,product,'initial')}>Add to Cart</a>:<Link to={"/products/"+slugType+"/"+slugValue+"/"+product.product_slug} title="Product Details" className="btn btn_pink btn_minwid compo_product_lk">Order Now</Link>):<a className="btn btn_pink btn_minwid disabled" href='javascript:;'>Sold Out</a>}
						
						<div className="addcart_row addcart_done_maindiv">
							<div className="qty_bx">
								<span className="qty_minus" onClick={this.proQtyAction.bind(this,product.product_primary_id, 'decr')}>-</span>
								<input type="text" className="proqty_input" readOnly value="1" />
								<span className="qty_plus" onClick={this.proQtyAction.bind(this,product.product_primary_id, 'incr')}>+</span>
							</div>
							<button className="btn btn_black" onClick={this.addToCartSimple.bind(this,product,'done')}>Done</button>
						</div>
						
						</div>
						
						{/*<div className="product-tags-list">
						{(Object.keys(product.product_tag).length > 0)?<ul>
								{ 
								  (product.product_tag).map(
								  (producttag,index1) => {
								  return (<li key={index1}>{producttag.pro_tag_name}</li>) 
								  } )
								}
						</ul>:''}
							</div>*/}

					</div>
					
				</div>
			</div>);

		return Phtml;
			
	} else {
		return false;
	}


  }
  }

   /* add to cart */
   addToCartSimple(productDetail, actionFlg) {
	
	var IndexFlg = productDetail.product_primary_id;
	
	if (actionFlg === 'initial') {
		$("#proIndex-"+IndexFlg).find('.smiple_product_lk').hide();
		$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').show();
		return false;
	} else {
		showLoader('proIndex-'+IndexFlg,'Idtext');
		var availabilityId = cookie.load("defaultAvilablityId");
		/*var availabilityId = deliveryId;*/
		var availabilityName = (availabilityId === deliveryId) ? "Delivery" : "Pickup";
		var isAddonProduct = 'No';
		var productId = productDetail.product_id
		var customerId = (typeof cookie.load('UserId') === 'undefined') ? '' : cookie.load('UserId');
		var proqtyQty = $("#proIndex-"+IndexFlg).find('.proqty_input').val();
		
		var postObject = {};
		postObject = {
			'app_id': appId,
			"product_id": productId,
			"product_qty": proqtyQty,
			"product_type": 1,
			"availability_id": availabilityId,
			"availability_name": availabilityName,
			"isAddonProduct": isAddonProduct,
			"reference_id": (customerId === '') ? getReferenceID() : '',
			"customer_id": customerId
		};

		var header = {
			headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
		  }
		  
		  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
			header.headers.Auth = cookie.load('Token')
		  }

		axios.post(apiUrlV2 + "cart/simpleCartInsert", qs.stringify(postObject), header).then(res => {
			hideLoader('proIndex-'+IndexFlg,'Idtext');
			$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').hide();
		    $("#proIndex-"+IndexFlg).find('.smiple_product_lk').show();
			if (res.data.status === "ok") {
				showCustomAlert('success','Great choice! Item added to your cart.');
				this.setState({cartTriggerFlg: 'yes'});
				removePromoCkValue();
				this.handleShowAlertFun('Success','Great choice! Item added to your cart.');
				/*showCartLst();*/
			} else if (res.data.status === "error") {
				var errMsgtxt = (res.data.message !== '') ? res.data.message : "Sorry! Products can`t add your cart.";
				showCustomAlert('error',errMsgtxt);
				this.handleShowAlertFun('Error',errMsgtxt);
			}
			
            return false;
		});
		
	}
	
  }
  
  proQtyAction(indxFlg, actionFlg) {
	var proqtyInput = $("#proIndex-"+indxFlg).find('.proqty_input').val();
		proqtyInput = parseInt(proqtyInput);
	if (actionFlg === 'decr') {
		proqtyInput = (proqtyInput > 1) ? proqtyInput - 1 : proqtyInput;
	} else {
		proqtyInput = proqtyInput + 1;
	}
	$("#proIndex-"+indxFlg).find('.proqty_input').val(proqtyInput);
}


sateValChange = (field, value) => {

	if(field === 'cartflg') {
		this.setState({cartTriggerFlg: value});
	}
}

handleShowAlertFun(header,msg) {
	var magnfPopup = window.$.magnificPopup.instance;
	showAlert(header, msg, magnfPopup);
	window.$.magnificPopup.open({
	  items: {
		src: '.alert_popup'
	  },
	  type: 'inline'
	});
}

/* Omise Payment Gateway Start */
handleChangeTxt = (item, event) => {
	const re = /^[0-9 \b]+$/;
	if(item=='cardNumber') {		
		/*if (event.target.value === '' || re.test(event.target.value)) {*/
			//var cardnumber = this.space(event.target.value);
			var cardnumberLenght = (event.target.value).length;
			if(cardnumberLenght<=16) {
				this.setState({ [item]:event.target.value});
				this.cardValidation(event.target.value);
			} 		
		/*}*/
	}
	else if(item=='expiration_month') {
		if (event.target.value === '' || re.test(event.target.value) && (event.target.value).length<=2) {
			this.setState({ [item]:event.target.value });	
		}
	}
	else if(item=='expiration_year') {
		if (event.target.value === '' || re.test(event.target.value) && (event.target.value).length<=4) {
			this.setState({ [item]:event.target.value });	
		}
	}
	else if(item=='security_code') {
		if (event.target.value === '' || re.test(event.target.value) && (event.target.value).length<=3) {
			this.setState({ [item]:event.target.value });	
		}
	}
	else {
		this.setState({ [item]:event.target.value });	
	}
}


cardValidation(carnumber) {
	var imagename = '';
	if(carnumber!='') {
		var single = carnumber.substring(0,1);
		var double = carnumber.substring(0,2);
		
		if(single==4) {
			imagename = 'visa.png';
		}
		else if(double==34 || double==37) {
			imagename = 'american-express.png';
		}
		else if(double>=51 && double<=55) {
			imagename = 'master-card.png';
		}
		else if(double==60 || double==64 || double==65) {
			imagename = 'discover.png';
		}
		if(imagename!='') {
			this.setState({cardImage:Parser('<img src="img/cards/'+imagename+'" class="card-image" alt="" />')});	
		}
		else {
			this.setState({cardImage:''});	
		}
		
	}
	else {

		this.setState({cardImage:''});	
	}
	
	
}


/*Omise popup trigger */
payOmiepopup (){

	window.$.magnificPopup.open({
		items: {
			src: '#omisepopup'
		},
		type: 'inline'
	});

}

/* Generate Token */
getOmiseToken() {

	var error = 0;

	if(this.state.holdername=='') { error++; this.setState({omisenameerror:Parser('<span class="error">This field is required.</span>')});} else { this.setState({omisenameerror:''});}

	if(this.state.cardNumber=='') { error++; this.setState({omisecardrror:Parser('<span class="error">This field is required.</span>')});} else { this.setState({omisecardrror:''});}

	if(this.state.expiration_month=='') { error++; this.setState({omisemontherror:Parser('<span class="error">Required.</span>')});} else { this.setState({omisemontherror:''});}

	if(this.state.expiration_year=='') { error++; this.setState({omiseyearerror:Parser('<span class="error">Required.</span>')});} else { this.setState({omiseyearerror:''});}

	if(this.state.security_code=='') { error++; this.setState({omisecodeerror:Parser('<span class="error">This field is required.</span>')});} else { this.setState({omisecodeerror:''});}	
	
	if(error>0) {
		return false;
	} else {

		var promoTionArr = Array();
		promoTionArr['promotionApplied'] = this.state.promotion_applied;
		promoTionArr['promotionAmount'] = this.state.promotion_amount;
		promoTionArr['promoIsDelivery'] = this.state.promoIs_delivery;
		var calculatedAmount = getCalculatedAmount(this.state.globalSettings,this.props.zonedetails,this.state.cartDetails,promoTionArr, this.state.delivery_self_collection);

		let payAmount = parseFloat(calculatedAmount['grandTotalAmount']).toFixed(2);

		payAmount = 'Pay '+stripeCurrency+payAmount;

		$('#omisepopup .btn.bg-black').html('loading');
		$('.omisepop-error-msg').html('').hide();
		Omise.setPublicKey(this.state.globalSettings.omise_public_key);		
		var current = this;
		var card = {
		    "name"				: this.state.holdername,
		    "number"			: this.state.cardNumber,
		    "expiration_month"	: this.state.expiration_month,
		    "expiration_year"	: this.state.expiration_year,
		    "security_code"		: this.state.security_code,
		    'livemode'			: false
		 };
		Omise.createToken("card", card, function (statusCode, response) {
			$('#omisepopup .btn.bg-black').attr('disabled',true);
			if (statusCode === 200) {
			    if (response.object == "error" || !response.card.security_code_check) {

				    var errorMsg = "Invalid card details.";

				    if(response.object == "error") {
				        errorMsg = response.message;
					}

					$('.omisepop-error-msg').html(errorMsg).show();
					$('#omisepopup .btn.bg-black').attr('disabled',false);
					$('#omisepopup .btn.bg-black').html(payAmount);

				} else {
					window.$.magnificPopup.close();
					current.setState({'omise_tokken_response': response,'omise_tokken_card_id':response.card.id,'omise_tokken_id':response.id}, () => { current.onProcessOmiseToken(response)});
				}
			}
			else {
				$('.omisepop-error-msg').html(response.message).show();
				$('#omisepopup .btn.bg-black').attr('disabled',false);

				$('#omisepopup .btn.bg-black').html(payAmount);
			}
		});
	}
}
/* Authentication For Payment */
 onProcessOmiseToken = (token) => {

	window.$.magnificPopup.open({
		items: {
			src: '.processing'
		},
		type: 'inline',
		midClick: true,
		closeOnBgClick: false
	});
	
	var promoTionArr = Array();
		promoTionArr['promotionApplied'] = this.state.promotion_applied;
		promoTionArr['promotionAmount'] = this.state.promotion_amount;
		promoTionArr['promoIsDelivery'] = this.state.promoIs_delivery;
	var calculatedAmount = getCalculatedAmount(this.state.globalSettings,this.props.zonedetails,this.state.cartDetails,promoTionArr, this.state.delivery_self_collection);

	var payAmount = parseFloat(calculatedAmount['grandTotalAmount']).toFixed(2);

	this.postOrder(2,'Yes'); // To validate the order 
	
	if( this.state.orderValidFail === 0){

		var postObject = {};
		postObject = {
		'app_id' : appId,
		'token' : token.id,	
		'customer_id':cookie.load('UserId'),
		"paid_amount":payAmount,
		"outlet_name" :((cookie.load('orderOutletName') !== undefined && cookie.load('orderOutletName') !== '') ? cookie.load('orderOutletName') + " - " +cookie.load('UserMobile'): cookie.load('UserMobile')	),
		}

		var header = {
			headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
		  }
		  
		  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
			header.headers.Auth = cookie.load('Token')
		  }

		axios.post(apiUrl+"payment/authOmise", qs.stringify(postObject), header).then(res => {
			if(res.data.status === "ok"){

				var captureID = res.data.result_set.payment_reference_1;
				this.setState({ validateimage: tickImage,log_id:res.data.result_set.log_id });
				this.postOrder(3, '', captureID, 'Omise');

			} else if(res.data.status === "error") {

				if(res.data.message == "token was already used"){
					this.omise_search_history();		
				} else {

					window.$.magnificPopup.close();
					this.paymentFail('Error', res.data.message);
					window.$.magnificPopup.open({
						items: {
							src: '.warining_popup'
						},
						type: 'inline'
					});

					return false;

				}

			} else {
				var currents = this;		 	
				setTimeout(function() {
						currents.omise_search_history();
				}.bind(this), 2000);
			}
		}).catch(error => {
				var currentcatch = this;
				setTimeout(function() {
					currentcatch.omise_search_history();
				}.bind(this), 2000);
			});
				

	} else {

		window.$.magnificPopup.close();
		this.paymentFail('Error', "Error code:1003 Oops! Something went wrong! Please try again." );

		setTimeout(function(){

			/////console.log('intimefunction',$('.warining_popup').length);
			window.$.magnificPopup.open({
				items: {
					src: '.warining_popup'
				},
				type: 'inline'
			});
	  }, 2000);


		return false;

	}
 }



 omise_search_history = () => {

	var postObject = {};
	postObject = {
	'app_id' : appId,
	'card_id' : this.state.omise_tokken_card_id,
	'token_id': this.state.omise_tokken_id 
	}

	var header = {
		headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
	  }
	  
	  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
		header.headers.Auth = cookie.load('Token')
	  }

	axios.post(apiUrl+"payment/omise_search_details", qs.stringify(postObject), header).then(res => {
		
		if(res.data.status === "ok"){

			this.setState({'paymentLoading':'/img/tick.png'});
			this.setState({'paymentGateway':'Omise'});				  
			/* Reset poup message -  end  */

			var captureID = res.data.captureID;
			this.setState({log_id:res.data.log_id});
			this.postOrder(3,"",captureID);
		 }
		   else if(res.data.status === "error") {
			/* Reset poup message -  start */
			 this.onProcessOmiseToken(this.state.omise_tokken_response);
		  }else{
			this.onProcessOmiseToken(this.state.omise_tokken_response);
		  }
	 });
}


/* Capture Payment */
captureOmiseAmount(captureID, orderPrimaryId, localOrderNo) {

	var header = {
		headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
	  }
	  
	  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
		header.headers.Auth = cookie.load('Token')
	  }

	var cabtureObjects = {};
    cabtureObjects = { payment_reference: stripeReference, "customer_id": cookie.load('UserId'), 'app_id': appId, 'token': captureID, 'order_id': orderPrimaryId, 'log_id':this.state.log_id };
    axios.post(apiUrl + "payment/captureAmountOmise", qs.stringify(cabtureObjects), header).then(captureRes => {
        if (captureRes.data.status === "ok") {

			this.setState({ completingpaymentimage: tickImage });
			this.showSuccessPage(localOrderNo,orderPrimaryId);

        }else if(captureRes.data.status === "pending"){
			this.retrieve_charge_details(captureID,localOrderNo,orderPrimaryId);
		}else if (captureRes.data.status === "error") {

			var msgTxt = (captureRes.data.message != '') ? captureRes.data.message : captureRes.data.status;

			window.$.magnificPopup.close();
            this.paymentFail('Error', msgTxt);
            window.$.magnificPopup.open({
                items: {
                    src: '.warining_popup'
                },
                type: 'inline'
            });
            return false;

        } else {
        	/*Get Status of Charge ID*/
			this.retrieve_charge_details(captureID,localOrderNo,orderPrimaryId);
        }
    }).catch(error => {
		//console.log(error,'captureOmiseAmount');
    	this.captureOmiseAmount(captureID, orderPrimaryId, localOrderNo);
    });
}

retrieve_charge_details = (captureID,localOrderNo,orderPrimaryId) => {

	var postObject = {};
	 postObject = {'charge_id':captureID,'app_id':appId,'order_primary_id':orderPrimaryId};

	 var header = {
		headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
	  }
	  
	  if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
		header.headers.Auth = cookie.load('Token')
	  }

	 axios.post(apiUrl+"payment/retrieve_charge_details", qs.stringify(postObject), header).then(res => {

		if(res.data.status ==='ok' && res.data.payment_status === "successful") {
			this.setState({ completingpaymentimage: tickImage });
			this.showSuccessPage(localOrderNo,orderPrimaryId);
		} else if(res.data.status ==='ok' && res.data.payment_status === "pending") {
			this.retrieve_charge_details(captureID,localOrderNo,orderPrimaryId);
		} else {

			var msgTxt = (res.data.message != '') ? res.data.message : res.data.status;

			window.$.magnificPopup.close();
            this.paymentFail('Error', msgTxt);
            window.$.magnificPopup.open({
                items: {
                    src: '.warining_popup'
                },
                type: 'inline'
            });
            return false;

		}
	 });

}

 changeDeliverySelfCollection(event) {

    if (this.state.delivery_self_collection === 1) {
      this.setState({ delivery_self_collection: 0 })
    } else {
      this.setState({ delivery_self_collection: 1 }, function(){

      	var sltdOrdDate = this.state.seleted_ord_date;
		var sltdOrdTime = this.state.seleted_ord_time;

		if(sltdOrdDate !=="" && sltdOrdTime !==""){
		var OrdDate  = format(sltdOrdDate, "yyyy-MM-dd");
		var OrdHours = sltdOrdTime.getHours();
		var OrdMunts = sltdOrdTime.getMinutes();
		var OrdSecnd = sltdOrdTime.getSeconds();
		var ordDateTime = new Date(OrdDate);
			ordDateTime.setHours(OrdHours);
			ordDateTime.setMinutes(OrdMunts);
			ordDateTime.setSeconds(OrdSecnd);
	
		var deliveryDate = format(sltdOrdDate, "dd-MM-yyyy");
		var deliveryTime = this.pad(OrdHours)+":"+this.pad(OrdMunts);		

		this.showAlert_pickup('Alert', "");
		///this.showAlert_pickup('Alert', 'Please note you are self collecting this order on the'+deliveryDate+ "at"+deliveryTime);
		window.$.magnificPopup.open({
			items: {
				src: '.alert_popup'
			},
			type: 'inline'
		});

	   }
	   
      });
    }
  }


  showAlert_pickup(header, message, autoClose = 'No'){

  	$(".alert_popup").remove();

    var ordered_date_checkout = this.state.seleted_ord_date;

    var formatedDate = format(ordered_date_checkout, "dd-MM-yyyy");

    var seletedOrdTime = this.state.seleted_ord_time;

	var OrderHours = seletedOrdTime.getHours();
	var OrderMunts = seletedOrdTime.getMinutes();
    var OrderSecnd = seletedOrdTime.getSeconds();
    var orderTime =  moment(seletedOrdTime, 'hh:mm A').format('hh:mm A');
	if(autoClose === 'No') {
		$('body').append('<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">'+header+'</div><div class="alert_body"><p>'+"Please note you are self collecting this order on " +formatedDate+ " at " + orderTime+'</p><div class="alt_btns"><a href="javascript:;" class="popup-modal-dismiss button">OK</a></div></div></div></div></div></div>');
	} else {
		$('body').append('<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">'+header+'</div><div class="alert_body"><p>'+"Please note you are self collecting this order on " +formatedDate+ " at " + orderTime+'</p><div class="alt_btns"></div></div></div></div></div></div>');
		setTimeout(function () {
			autoClose.close();
		}, 1800);
	}
  }

	  render() {
		var settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 4,
			initialSlide: 0,
			responsive: [
			  {
				breakpoint: 1024,
				settings: {
				  slidesToShow: 3,
				  slidesToScroll: 3,
				  infinite: true,
				  dots: true
				}
			  },
			  {
				breakpoint: 600,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 2,
				  initialSlide: 2
				}
			  },
			  {
				breakpoint: 480,
				settings: {
				  slidesToShow: 1,
				  slidesToScroll: 1
				}
			  }
			]
		  };

		var showRdmPoints = (parseFloat(this.state.reward_point_count) - parseFloat(this.state.used_reward_point));
		showRdmPoints = showRdmPoints.toFixed(2);
		var InptRdmPoints = (parseFloat(this.state.reward_point_count)>0) ? this.state.reward_point_count : 0;  
		var promoTionArr = Array();
		promoTionArr['promotionApplied'] = this.state.promotion_applied;
		promoTionArr['promotionAmount'] = this.state.promotion_amount;
		promoTionArr['promoIsDelivery'] = this.state.promoIs_delivery;
		var calculatedAmount = getCalculatedAmount(this.state.globalSettings,this.props.zonedetails,this.state.cartDetails,promoTionArr, this.state.delivery_self_collection);

		var subTotalAmount = parseFloat(calculatedAmount['cartSubTotalAmount']);
		var payAmount = parseFloat(calculatedAmount['grandTotalAmount']).toFixed(2);

		return (
				<div className="home-wrapper inner-page-wrapper">	

					{/* Header start */}
					<Header cartTriggerFlg={this.state.cartTriggerFlg} sateValChange={this.sateValChange}/>
					{/* Header End */}				

					{/*------wraper-------------*/}							
									
					<div className="wraper checkout-wrapper">

					<div className="checkout_hea">
					<div className="container">
							<h3>Checkout</h3>
					</div>
					</div>

					
					{/*}<div className="promotion_offer_wrap">
					<div className="container">
						<div className="promotion_offer_inner">
							<div className="chk-title text-center">
								<h6>Hello</h6>
								<h3>Thanuja</h3>
								<h4>Do share the following for us to reach out to you with promotions and offers !</h4>
							</div>	
							<div className="form-group">
							<div className="row">
							<div className="col-md-6">
								<div className="focus-out">
								<label>Your Birthday</label>
								<input type="input" className="form-control input-focus" />
								</div>
							</div>
							<div className="col-md-6">
								<div className="focus-out">
								<label>Gender</label>
								<input type="input" className="form-control input-focus" />
								</div>
							</div>
							</div>
							</div>							
						</div>
					</div>
		</div>{*/}

					{/*------Order and Delivery ------- */}

					<div className="check_out_fourcommon">
					<div className="container">
					
					
						<div className="checkoutpage_form_outer">
							<form id="checkoutpage_form" className=" form_sec checkout-total" method="post" accept-charset="utf-8">
								<div className="cdd-details">
								
									<div className="cdd-details-lhs fl">

										<div className="checkout-details-bar">																					
											<div className="cart-header">
												<div className="ctitle">
													<h3>Delivery Details</h3>							
												</div>
											</div>
										<div className="checkout-form-wrap">
                                                

                                             {cookie.load("defaultAvilablityId") === deliveryId && (
											  <div className="checkout-control-group-bottom">
												<label className="chk_hea">
												  <span>
													<div className="custom_checkbox">
													  <input
														type="checkbox"
														className="addon_list_single"
														onChange={this.changeDeliverySelfCollection.bind(
														  this
														)}
														checked={
														  this.state.delivery_self_collection === 1
															? true
															: false
														}
														id="checkout_takeway"
													  />
													  <span></span>
													</div>
													I would like to self collect this order{" "}
												  </span>
												</label>
											  </div>
											)}


										{(cookie.load('defaultAvilablityId') === deliveryId && this.state.delivery_self_collection === 0) &&   <div className="checkout-control-group-top"> 
											<label className="chk_hea">Delivery Location</label>
											<div className="form-group">
												<div className="focus-out controls-single">
													<input type="text" readOnly className="form-control input-focus" value={this.state.order_delivery_address} placeholder="Address" />
												</div>
											</div>                            
											<div className="form-group controls-three">
												
														<div className={(this.state.order_postcode != '' ? 'focus-out focused' : 'focus-out')}>
															<label>Postal Code</label>
															<input type="text" readOnly value={this.state.order_postcode} className="form-control input-focus" />
														</div>
													
													
														<div className="focus-out">
															<label className="unit-num">Unit No 01</label>
															<input type="text" id="unit_no1_id" name="unit_no1" value={this.state.unitnumber1} className="form-control input-focus" onChange={this.handleAddrChange.bind(this)} />
														</div>
													
													
														<div className="focus-out">
															<label className="unit-num">Unit No 02</label>
															<input type="text" name="unit_no2" value={this.state.unitnumber2} className="form-control input-focus" onChange={this.handleAddrChange.bind(this)} />
														</div>
												</div>
												<a href="javascript:void(0);" onClick={this.changeAddrrFun.bind(this)} className="">Change Delivery Address</a> 
											 </div>}	

											{(cookie.load('defaultAvilablityId') === deliveryId && this.state.delivery_self_collection === 0) && <div className="checkout-control-group-top"> 
												<label className="chk_hea">Billing Address <span>Same As Delivery Address <div className="custom_checkbox">
											<input type="checkbox" name="bill_chk" className="addon_list_single" onChange={this.changeBillingAddrChk.bind(this)} checked={this.CheckBillingAddrChk('checkbox')} />
											<span></span>
										 </div></span></label>
                                             <div className="check-billing" style={{display:this.CheckBillingAddrChk('display')}}>
												<div className="form-group">
													<div className="focus-out controls-single">
														<input type="text" className="form-control input-focus" placeholder="Address" readOnly value={this.state.billing_delivery_address} />
													</div>
												</div>                            
												<div className="form-group controls-three">
													
														<div className={(this.state.billing_postcode != '' ? 'focus-out focused' : 'focus-out')}>
															<label>Postal Code</label>
															<input type="text" name="billing_postcode" value={this.state.billing_postcode} onChange={this.changePostalCode.bind(this,'bill')} onKeyPress={(e) => this.validateIntval(e)} className="form-control input-focus" />
														</div>
														
														<div className={(this.state.billunitnumber1 != '' ? 'focus-out focused' : 'focus-out')}>
															<label className="unit-num">Unit No 01</label>
															<input type="text" name="bill_unit_no1" value={this.state.billunitnumber1} className="form-control input-focus" onChange={this.handleAddrChange.bind(this)} />
														</div>
														
														<div className={(this.state.billunitnumber2 != '' ? 'focus-out focused' : 'focus-out')}>
															<label className="unit-num">Unit No 02</label>
															<input type="text" name="bill_unit_no2" value={this.state.billunitnumber2} className="form-control input-focus" onChange={this.handleAddrChange.bind(this)} />
														</div>
														
												</div>
											  </div>
											</div>}									

											<div className="checkout-control-group-middle"> 
												<label className="chk_hea">Delivery Date & Time</label>
												<div className="form-group">
													<OrderdatetimeSlot {...this.props} hdrState={this.state} setdateTimeFlg={this.setdateTimeFlg} />
													<div className="ordrdatetime_error"></div>	
												</div>
											</div>
											
											

											<div className="checkout-control-group-bottom"> 
												<label className="chk_hea">Special Instruction</label> 
												<textarea className="" placeholder="Please enter your special message here..." name="specil_note" value={this.state.order_specil_note} onChange={this.handleAddrChange.bind(this)} id="orderRemarks"></textarea> 

											</div>
											
										</div>
										</div>
									</div>{/*---------Delivery Details left--------*/}		

									
					
					
                    <div className="cdd-details-rhs fl">
                        <div className="chekout_cart_bar">
							
						<div className="cart-header">
							<div className="ctitle">
								<h3>Your Order Details</h3>							
							</div>
						</div>
						
						{this.loadCartOverallData()}	

					    </div>
                    </div>{/*---Order Details right----*/}						
									
								
								</div>
							</form>
						</div>{/*---------checkoutpage_form_outer--------*/}
						
						{/*-----Redeem-----*/}
						
						<div className="redeem">
						<div className="redeem-row">
							<div className="redeem-col">
								<div className="redeem-item">
									<div className="redeem-item-hea">
										<div className="redeem-item-hea-inner">
											<h4>Get Redeem</h4>
											<span>Redeem your points here</span>
										</div>
										{(parseFloat(this.state.reward_point_count)>0)?<Link to={"/rewards"} className="points">You have <b>{showRdmPoints} points</b> available</Link>:''}

									</div>
									<div className="redeem_apply focus-out applypoints_cls" id="redeem_points_option">
										<div className="focus-out">

											<label>{"You Can Redeem "+InptRdmPoints+" Points"}</label>
											<input type="text" name="reward_point" value={this.state.reward_point_val} onKeyPress={(e) => this.validateFloatval(e)} onChange={this.handleAddrChange.bind(this)} id="pointValue" className="form-control input-focus" />

										</div>

										{(this.state.promotion_applied === 'Yes' && this.state.promotion_type === 'points')?<button className="btn btn_minwid applyPoints" onClick={this.removePointsAndPromo.bind(this)}>Remove</button>:<button className="btn btn_minwid applyPoints" onClick={this.applyPointsAndPromo.bind(this,'points')}>Apply Now</button>}

									</div>

									<div className="otp_verfy_main applyotp_cls" id="show_redeem_otp" style={{display:'none'}}>
										<div className="otp_option_main_div" >
											<div className="redeem_apply focus-out applypoints_otp_cls">
												<div className="focus-out">
													<label>Enter Your OTP Here.</label>
													<input type="text" name="reward_point_otp" value={this.state.reward_point_otp}  id="redeem_points_otp" className="form-control input-focus" />
												</div>

												<div className="redeem_otp_butn">
													<button className="btn btn_minwid applyPointsButn" onClick={this.subbmitpromotionotp.bind(this,'points')}>Submit</button>
													<button className="btn btn_minwid cancelPointsButn" onClick={this.cancelRedeemOtp.bind(this)}>Cancel</button>
												</div>
											</div>
										</div>
										<a className="resent_redeem_otp" id="resend_otp_button" onClick={this.handleResendotp.bind(this)}><b>Resent OTP</b></a>
									</div>

								</div>
							</div>
							<div className="redeem-col redeem-col-right">
								<div className="redeem-item">
									<div className="redeem-item-hea">
										<div className="redeem-item-hea-inner">
											<h4>Promo Code</h4>
											<span>Apply your promo code here</span>
										</div>
										{(parseFloat(this.state.promotion_count)>0)?<Link to={"/mypromotions"} className="points">You have <b>{this.state.promotion_count} Promotions</b> available</Link>:''}
									</div>
									<div className="redeem_apply focus-out">
										<div className={(this.state.promo_code_val != '' ? 'focus-out focused' : 'focus-out')}>

											<label>Add Your Promo Code Here</label>
											<input name="promo_code" value={this.state.promo_code_val} id="promocodeValue" type="text" className="form-control input-focus" onChange={this.handleAddrChange.bind(this)} />

										</div>
										{(this.state.promotion_applied === 'Yes' && this.state.promotion_type === 'promoCode')?<button className="btn btn_black btn_minwid promobtn applyPromo" onClick={this.removePointsAndPromo.bind(this)} >Remove</button>:<button className="btn btn_black btn_minwid promobtn applyPromo" onClick={this.applyPointsAndPromo.bind(this,'promoCode')}>Apply Now</button>}

									</div>
								</div>
							</div>
						</div>
					</div>
						
						{/*----Redeem End----*/}

						{/*------- product slider ------- */}
						{Object.keys(this.props.addonproductlist).length > 0 &&
						<div className="check_pg">
							<div className="container">
								<div className="check_pg_inner">
									<div className="chk-title text-center">
										<h4>Would you like to add following items ?</h4>
									</div>
									<Slider {...settings}>
									{this.addonProductListing()}			
									</Slider>
								</div>
							</div>
						</div>}
						{/*------product slider end ------- */}

						{/*----Payment Method----*/}
						<div className="chk-payment">
							<div className="chk-payment-row">								
							<div className="chk-title text-center">
								<h4>Select your Payment Method</h4>
							</div>
								<div className="chk-payment-col">
									
									<form action="#">
										{(this.state.paymentmodevalue !== 'promotion' && parseFloat(payAmount)>0) && <ul className="chk-payment-col-radio">
										{(this.state.cod_payment_enable === 1) && <li>
											<input type="radio" defaultChecked={this.state.paymentmodevalue == 'Cash' ? "checked" : ''} id="cash" onClick={this.choosePayment.bind(this, "Cash")} name="radio-group" />
											<label htmlFor="cash"></label>
											<div className="radio_con">Cash On Delivery</div>
										</li>}
										{(this.state.stripe_payment_enable === 1) && <li>
											<input type="radio" defaultChecked={this.state.paymentmodevalue == 'Stripe' ? "checked" : ''} id="card" onClick={this.choosePayment.bind(this, "Stripe")} name="radio-group" />
											<label htmlFor="card"></label>
											<div className="radio_con"><img src={payment} /></div>
										</li>}

										{(this.state.omise_payment_enable === 1) && <li>
											<input type="radio" defaultChecked={this.state.paymentmodevalue == 'Omise' ? "checked" : ''} id="cardOmise" onClick={this.choosePayment.bind(this, "Omise")} name="radio-group" />
											<label htmlFor="cardOmise"></label>
											<div className="radio_con"><img src={paymentOmise} /></div>
										</li>}
										</ul>}

										{(this.state.paymentmodevalue === 'promotion' && parseFloat(payAmount)===0 && subTotalAmount>0) && <ul className="chk-payment-col-radio promotion-ul-topcls">
											<li>
												<input type="radio" defaultChecked={this.state.paymentmodevalue == 'promotion' ? "checked" : ''} id="promotion" name="radio-group" />
												<label htmlFor="promotion"></label>
												<div className="radio_con">Promotion</div>
											</li>
										</ul>}
									</form>

									{/* chk-payment-btn div - end */}
									<div className="chk-payment-btn">

									{(this.state.paymentmodevalue !== 'promotion' && parseFloat(payAmount)>0) && <div className="chk-payment-btn-row">
										{this.state.paymentmodevalue == 'Cash' && this.state.cod_payment_enable === 1 && <a href="javascript:;" className="btn btn_yelow" title="Checkout" onClick={this.payCash.bind(this)}>Checkout</a>}

										{cookie.load('UserMobile') !='' && ((this.state.globalSettings.stripe_public_key && this.state.paymentmodevalue == 'Stripe' && this.state.stripe_payment_enable === 1) || (this.state.globalSettings.omise_public_key && this.state.paymentmodevalue == 'Omise' && this.state.omise_payment_enable === 1)) && <a href="javascript:;" onClick={this.orderPayValidaion.bind(this)} className="btn btn_yelow" title="Checkout">Checkout</a>}

									</div>}

									{(this.state.paymentmodevalue === 'promotion' && parseFloat(payAmount)===0 && subTotalAmount>0) && <div className="chk-payment-btn-row">
									<a href="javascript:;" className="btn btn_yelow" title="Checkout" onClick={this.postPromotionOrder.bind(this)}>Checkout</a>
									</div>}
									
								</div>
								{/* chk-payment-btn div - end */}




								</div>
							</div>
						</div>
					
						
						{/*----Payment Method End----*/}

					</div>	{/*---------Container--------*/}
				</div>




					</div>{/*------ wraper end--------*/}	


 			{/*Payment confirm popup Start*/}
		 	<div id="pay-conf-popup" className="white-popup mfp-hide popup_sec pay-conf-popup">
				<div className="custom_alert">
					<div className="custom_alertin">
						<div className="alert_height">
							<div className="alert_header">Alert!</div>
								<div className="alert_body">
								<p>Awesome ! You are all set</p>
								<div className="alt_btns">

									{this.state.globalSettings.stripe_public_key && cookie.load('UserMobile') !='' && this.state.paymentmodevalue == 'Stripe' && this.state.stripe_payment_enable === 1 && <StripeCheckout name={stripeCompany} image={stripeImage} description={this.stripeDescription()} token={this.onToken} stripeKey={this.state.globalSettings.stripe_public_key} amount={(payAmount * 100)} email={cookie.load('UserEmail')} currency={stripeCurrency} > <a href="javascript:;" className="button" title="Proceed To Payment">Proceed To Payment</a>
                                    </StripeCheckout>}

									{this.state.globalSettings.omise_public_key && cookie.load('UserMobile') !='' && this.state.paymentmodevalue == 'Omise' && this.state.omise_payment_enable === 1 &&  <a href="javascript:;" onClick={this.payOmiepopup.bind(this)} className="button" title="Proceed To Payment">Proceed To Payment</a>
                                    }

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*payment confirm popup - End*/}

			{/* Omise Popup Start */}
		
			<div id="omisepopup" className="white-popup popup_sec mfp-hide omise_popsec">

				<div className="white-outer">
				<div className="omise_pophead">
					
						<div className="omisepop_logo">
							<img src={striplogo} className="img-responsive img-width-120" alt="omise"  />
						</div>
						<h3>Payment</h3>
					</div>
					<div className="omisepop_in">
						<div className="omisepop-error-msg"></div>
						<div className="form_sec">
							<div className="">
								<div className="form-group label-floating is-empty card-no">
									<div className="input-group">
										<label className="control-label" htmlFor="holdername">Card Holder Name</label>
										<input type="text" id="holdername" className="form-control" value={this.state.holdername} onChange={this.handleChangeTxt.bind(this, 'holdername')} />
										{this.state.omisenameerror}
									</div>
								</div>
								<div className="form-group label-floating is-empty card-no">
									<div className="input-group">
										<label className="control-label" htmlFor="omise_card_number">Card Number</label>
										<div className="form_innr_grp">
											<input type="number" className="form-control" value={this.state.cardNumber} id="omise_card_number" value={this.state.cardNumber} onChange={this.handleChangeTxt.bind(this, 'cardNumber')} />
											{this.state.cardImage}
											{this.state.omisecardrror}
										</div>
									</div>
								</div>
								<div className="form-group form_expire_row">
									<label className="black" >Expiration Date</label>
									<div className="row">							
										<div className="col-xs-6">
											<div className="form-group label-floating is-empty card-month card-no">
												<div className="input-group">
													<label className="control-label" htmlFor="expiration_month">MM</label>
													<input type="number" className="form-control" maxLength="2" id="expiration_month" value={this.state.expiration_month} onChange={this.handleChangeTxt.bind(this, 'expiration_month')}/>
													{this.state.omisemontherror}
												</div>
											</div>
										</div>
										<div className=" col-xs-6 ">
											<div className="form-group label-floating is-empty card-year card-no">
												<div className="input-group">
													<label className="control-label" htmlFor="expiration_year">YY</label>
													<input type="number" className="form-control" maxLength="4" id="expiration_year" value={this.state.expiration_year} onChange={this.handleChangeTxt.bind(this, 'expiration_year')} />
													{this.state.omiseyearerror}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="form-group label-floating is-empty card-no">
								<div className="input-group">
									<label className="control-label" htmlFor="security_code">CVV</label>
									<input type="number" maxLength="3" className="form-control" id="security_code" value={this.state.security_code} onChange={this.handleChangeTxt.bind(this, 'security_code')}  />
									{this.state.omisecodeerror}
								</div>
							</div>
							<div className="btn_sec">
								<button onClick={this.getOmiseToken.bind(this)} className="btn bg-black">Pay {stripeCurrency} {payAmount}</button>
							</div>
						</div>
					</div>
				</div>			
			</div>
		{/* Omise Popup End */}
			 
			 
			 
			 <div id="processing-popup" className="white-popup mfp-hide popup_sec processing">
                <div className="pouup_in">
                    <h3 className="title1 text-center">We Are Processing Your Order</h3>
                    <div className="process_inner">

                        {this.onlinePaymentLoading()}
                        {this.orderBCLoading()}
                        {this.amountCaptureLoading()}

                    </div>
                </div>
            </div>
			 
			 <div id='dvLoading'></div>


					{/*------Footer--------*/}
					<Footer />


			    </div>
			   )
	  }	
}


const mapStateTopProps = (state) => {
	var overAllcart = Array();
	var cartDetails = Array();
	var cartItems = Array();
	var cartTotalItmCount = 0;
	var cartStatus = '';
	var updateCartResponse = Array();
	
	if(Object.keys(state.cartlistdetail).length > 0) {
		var resultSetArr = (!('result_set' in state.cartlistdetail[0])) ? Array() : state.cartlistdetail[0].result_set;
		if(state.cartlistdetail[0].status === 'ok' && Object.keys(resultSetArr).length > 0) {
		  overAllcart = resultSetArr;  
		  cartDetails = resultSetArr.cart_details;
		  cartItems = resultSetArr.cart_items;
		  cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
		  cartStatus = 'success';
		} else {
		  cartStatus = 'failure';
		}
	}

	var globalSettings = Array();
	if(Object.keys(state.settings).length > 0) {
		if(state.settings[0].status === 'ok') {
			globalSettings = state.settings[0].result_set;
		}
	}

	var zonedetailArr = Array();
	if(Object.keys(state.zonedetail).length > 0) {
		   if(state.zonedetail[0].status === 'ok') {
			   zonedetailArr = state.zonedetail[0].result_set;
		   }
	}

	var activityCountArr = Array();
	if(Object.keys(state.activitycount).length > 0) {
		if(state.activitycount[0].status === 'ok') {
			activityCountArr = state.activitycount[0].result_set;
		}
	}

	var addonProArr = Array();
	var addonCommonArr = Array();
	if(Object.keys(state.addonproduct).length > 0) {
		if(state.addonproduct[0].status === 'ok' && Object.keys(state.addonproduct[0].result_set).length > 0) {
		  addonProArr = state.addonproduct[0].result_set;
		  addonCommonArr = state.addonproduct[0].common;
		}
	}


   if (Object.keys(state.updatecartdetail).length > 0) {
    if (state.updatecartdetail[0].status === "error") {
      updateCartResponse = state.updatecartdetail;
    }
   }

	return {
	  activitycountArr: activityCountArr,  
	  settingsArr: globalSettings,
	  zonedetails: zonedetailArr,	
	  overAllcart: overAllcart,
	  cartDetails: cartDetails,
	  cartItems: cartItems,
	  cartTotalItmCount: cartTotalItmCount,
	  cartStatus: cartStatus,
	  addonproductlist: addonProArr,
   	 addonproductcommon: addonCommonArr,
   	 updateCartResponse: updateCartResponse,
	}
  }
  
  const mapDispatchToProps = (dispatch) => {
	return {
	  getCartDetail: () => {
		dispatch({ type: GET_CART_DETAIL });
	  },
	  updateCartDetail: (productId,cartItemId,cartQty,orderVoucherId) => {
		dispatch({ type: UPDATE_CART_DETAIL, productId, cartItemId, cartQty, orderVoucherId });
	  },
	  deleteCartDetail: (cartItemId) => {
		dispatch({ type: DELETE_CART_DETAIL, cartItemId });
	  },
	  destroyCartDetail: () => {
		dispatch({ type: DESTROY_CART_DETAIL });
	  },
	  getActivityCount: (getObject) => {
		  dispatch({ type: GET_ACTIVITYCOUNT, getObject});
	  },
	  getAddonProList: (outletId) => {
		dispatch({ type: GET_ADDONPRODUCT, outletId });
		},
	}
  }
  export default connect(mapStateTopProps, mapDispatchToProps)(Checkout);
