import { combineReducers } from 'redux';

import settings from './settings';
import zonedetail from './zonedetail';
import secondaryaddress from './secondaryaddress';


import product from './product';
import productdetail from './productdetail';
import addonproduct from './addonproduct';

import cartlistdetail from './cartlistdetail';
import updatecartdetail from "./updatecartdetail";


import activitycount from './activitycount';
import promotionlist from './promotionlist';
import rewardearned from './rewardearned';
import rewardredeem from './rewardredeem';

import customerdetail from './customerdetail';
import updatecustomerprofile from './updatecustomerprofile';


import login from './login';
import fblogin from './fblogin';
import forgetpassword from './forgetpassword';
import registration from './registration';
import changepassword from './changepassword';

import orderhistory from './orderhistory';
import corderdetail from './corderdetail';
import porderdetail from './porderdetail';
import orderdetail from './orderdetail';
import printorder from './printorder';

import menudata from './menudata';
import pagedata from './pages';
import contactdata from './contactdata';

import staticblack from './staticblack';
import socialsettings from './socialsettings';

const rootReducer = combineReducers({
product: product,
productdetail:productdetail,
addonproduct:addonproduct,
settings: settings,
zonedetail: zonedetail,
secondaryaddress:secondaryaddress,
cartlistdetail:cartlistdetail,
updatecartdetail: updatecartdetail,
activitycount:activitycount,
orderdetail:orderdetail,
printorder:printorder,
orderhistory:orderhistory,
corderdetail:corderdetail,
porderdetail:porderdetail,
promotionlist:promotionlist,
login: login,
fblogin: fblogin,
forgetpassword: forgetpassword,
registration: registration,
changepassword: changepassword,
updatecustomerprofile:updatecustomerprofile,
customerdetail: customerdetail,
rewardearned:rewardearned,
rewardredeem:rewardredeem,
menudata:menudata,
pagedata:pagedata,
contactdata:contactdata,
staticblack:staticblack,
socialsettings: socialsettings,
});

export default rootReducer;
