/* eslint-disable */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import cookie from 'react-cookies';

import update from 'immutability-helper';

import { appId, apiUrl, apiUrlV2, deliveryId } from "../Helpers/Config";
import { getReferenceID, showAlert, showLoader, hideLoader, getAliasName, stripslashes, removeOrderDateTime, removePromoCkValue, addressFormat } from "../Helpers/SettingHelper";

import { setMinutes, setHours, getDay, format } from 'date-fns';
var dateFormat = require('dateformat');

import { GET_MENUDATA,GET_GLOBAL_SETTINGS, GET_ZONE_DETAIL, GET_ALLUSERSECADDRDATA,DESTROY_CART_DETAIL,GET_LOGINDATA, GET_FBLOGINDATA, GET_FORGET_PASSWORD, GET_REGISTRATION} from '../../actions';

import OrderdatetimeSlot from "./OrderdatetimeSlot";
import CartSideBar from "./CartSideBar";
import MenuNavigationmob from "./MenuNavigationmob";

import {Login,Forgotpassword,Signup,Guestcheckout} from "../../components/Myaccount/Index";

import logo from "../../common/images/logo.png";

import deliveryImg from "../../common/images/ico_delivery.png";
import iconWin from "../../common/images/ico_awesome.png";
import warningImg from "../../common/images/warning.svg";
import iconUnhappy from "../../common/images/ico_sad.png";
import ico_login from "../../common/images/ico_login.png";
import searchImg from "../../common/images/search-interface-symbol.png";
import brushStroke from "../../common/images/mob-brush-stroke.png";
import mobLogo from "../../common/images/mob-logo.png";
import userLogin from "../../common/images/user2.png";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cartSidebar: false,
            menu: false,
            seletedAvilablityId:'',seletedOutletId:'',order_tat_time:0,globalsettings: [], secondaryaddresslist: [], deliveryOutlets: [], deliveryOutletsList: [], delivery_outlet_id:'', orderHandled: '', orderDeliveryAddress: '', nextavail: '',  getDateTimeFlg:'', deliveryInfo: [],seleted_ord_date: '',seleted_ord_time: '',
            fields: {
            email: '',
            password: ''
            },
            fieldsfgtpassword: {
                email: ''
            },
            fieldscheckout: {
                check_email: '',
                check_firstname: '',
                check_phone: '',
                birthday: '',
                 termsa:1,
            },
            fieldssignup: {
                firstname: '',
                lastname: '',
                email: '',
                birthday: '',
                gender: 'M',
                password: '',
                rePassword: '',
                mobile: '',
                pdpa_consent: '',
                terms: ''

            },
            menuData:[],
            footerSocialcontent :""
        };

        this.props.getGlobalSettings();

        var availbty = cookie.load('defaultAvilablityId');
        var outltIdTxt = (typeof cookie.load('orderOutletId') === 'undefined') ? '' : cookie.load('orderOutletId');
        var zoneIdTxt = (typeof cookie.load('orderZoneId') === 'undefined') ? '' : cookie.load('orderZoneId');

        if(availbty === deliveryId && outltIdTxt !== '' && zoneIdTxt !== '') {
            this.state['delivery_outlet_id'] = outltIdTxt;
            this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
        }

    }

    componentDidMount() {

        this.props.getMenuData('main');

        if(cookie.load('UserId') !== undefined) {
    
            /*if(cookie.load('Token') === undefined || cookie.load('Token') === '' || cookie.load('Token') === null ) {
                const{history} = this.props;
                history.push("/logout");
            }*/
            

            this.props.getSecondaryAddress();
        }

        if ($(".open-popup-link").length > 0) {
            window.$('.open-popup-link').magnificPopup({
              type: 'inline',
              midClick: true 
            });
          }

        /* Input lable animation */
        if ($(".input-focus").length > 0) {
            $('.input-focus').focus(function() {
                $(this).parents('.focus-out').addClass('focused');
            });
            $('.input-focus').blur(function() {
                var inputValue = $(this).val();
                if (inputValue == "") {
                    $(this).removeClass('filled');
                    $(this).parents('.focus-out').removeClass('focused');
                } else {
                    $(this).addClass('filled');
                }
            });
        }

        /* Mobile header menu */
        $('.hcategory_selected_text').click(function () {
            $(this).toggleClass('active');
            $('.hcategory_menu').toggleClass('open');
        });

        $(document).click(function (e) {
            if (!$(e.target).is('.hcategory_selected_text')) {
                if ($('.hcategory_menu').hasClass("open")) {
                    $('.hcategory_menu').removeClass('open');
                    $('.hcategory_selected_text').removeClass('active');
                }
            }
        });

        // if ($(".hordertype_btn").length > 0) {
        //     $(".hordertype_btn").click(function (e) {
        //         e.stopPropagation();
        //         $('.mobile_account_menu').slideToggle();
        //      $('.arrow_myaccount').toggleClass('rotateIcon');
        //     });
        //     $(document).click(function (e) {
        //         if (!$(e.target).is('.arrow_myaccount, .mobile_account_menu_col')) {
        //             if ($('.mobile_account_menu').is(":visible")) {
        //                 // $('.mobile_account_menu').slideToggle();
        //                 $('.mobile_account_menu').hide();
        //             }
        //         }
        //     });
        // }

    }

    componentDidUpdate (prevProps) {

        if (this.props.socialsettingData != prevProps.socialsettingData) {

               this.setState( { footerSocialcontent: this.props.socialsettingData[0].result_set });
           }

   }

   componentWillReceiveProps(PropsDt) {

        if(PropsDt.menudata !== this.props.menudata){
            this.setState({menudata: PropsDt.menudata[0].result_set});
        }

        if(PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
            this.setState({secondaryaddresslist: PropsDt.secondaryaddresslist});
        }
        
        
        if(PropsDt.fbloginData!==this.props.fbloginData){
            this.doLogin(PropsDt.fbloginData);
        }
        
        if(PropsDt.logindata !==this.props.logindata){
            this.doLogin(PropsDt.logindata[0]);
        }

        if(this.state.fpstatus==='loading'){
            if(PropsDt.forgetpassword !== undefined){
               this.setState({fpstatus:'ok'});
               this.showMessage(PropsDt.forgetpassword[0])       
            }
        }
          
        if(this.state.regstatus==='loading'){
            if(PropsDt.registration !== undefined){
                this.setState({regstatus:'ok'});
                this.showMessage(PropsDt.registration[0])       
            }
        }
    }

    handleMenu(){
        this.setState(prevState => ({menu: !prevState.menu}));  
    }

    cartSidebar(){
        this.setState(prevState => ({cartSidebar: !prevState.cartSidebar}));        
    }

    /* find Zone*/
    findOutletBasedZone(first, availability) {

        if (first) {
            var postalcode = $("#postalcode").val();
        } else {
            var postalcode = $("#postalcode1").val();
        }
        
        var outletIdTxt = this.state.delivery_outlet_id;

        /*if (outletIdTxt === '') {
            $(".postal_error").html('<span class="error">Go Back and Select your delivery outlet.</span>');
            return false;
        }*/
        
        if (postalcode.length < 5) {
            $(".postal_error").html('<span class="error">Please enter valid postal code.</span>');
            return false;
        }
        
        showLoader('delivery_submit_cls','class'); 

        var header = {
        headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
        }
        
        if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
        header.headers.Auth = cookie.load('Token')
        }
    
        axios.all([ 
        axios.get(apiUrlV2+'outlets/findOutletZone?app_id='+appId+"&availability_id="+availability+"&postal_code="+postalcode+"&postalcode_basedoutlet=yes", header),
        axios.get(apiUrlV2+'settings/chkTimeslotIsAvaiable?app_id='+appId+"&availability_id="+availability+"&outletId="+outletIdTxt, header),
            ]).then(axios.spread((res,timeslt) => {
                    
                    hideLoader('delivery_submit_cls','class');
                    
                    var deliveryInfo = [];
                    /* Success response */
                    if (res.data.status === "ok") {
                        $.magnificPopup.close();

                        cookie.save('outletchosen', availability);

                        var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname;
                        var orderHandled = stripslashes(res.data.result_set.outlet_name) + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes";
                        
                        deliveryInfo['orderZoneId'] = res.data.result_set.zone_id;
                        deliveryInfo['orderOutletId'] = res.data.result_set.outlet_id;
                        deliveryInfo['orderOutletPostalCode'] = res.data.result_set.outlet_postal_code;
                        deliveryInfo['orderOutletName'] = stripslashes(res.data.result_set.outlet_name);
                        deliveryInfo['orderPostalCode'] = res.data.result_set.postal_code_information.zip_code;
                        deliveryInfo['orderTAT'] = res.data.result_set.outlet_delivery_timing;
                        deliveryInfo['orderDeliveryAddress'] = orderDeliveryAddress;
                        deliveryInfo['orderHandled'] = orderHandled;
                        deliveryInfo['defaultAvilablityId'] = availability;

                        var unitNum = this.showUnitNum(res.data.result_set.outlet_unit_number1,res.data.result_set.outlet_unit_number2);    

                        var orderHandledText = stripslashes(res.data.result_set.outlet_name) + " " + res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", "+unitNum+" Singapore " + ((res.data.result_set.outlet_postal_code != undefined && res.data.result_set.outlet_postal_code !== '')?res.data.result_set.outlet_postal_code:postalcode);
                        deliveryInfo['orderHandledByText'] = orderHandledText;

                        this.setState({deliveryInfo: deliveryInfo,seletedAvilablityId: availability, seletedOutletId: res.data.result_set.outlet_id, order_tat_time: res.data.result_set.outlet_delivery_timing, orderHandled: orderHandled, orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code });

                        /* Success time slot response */
                        if (timeslt.data.status === "success") {
                            this.setState({getDateTimeFlg: 'yes'});

                            removeOrderDateTime();
                            removePromoCkValue();
                                
                            window.$.magnificPopup.open({
                                items: {
                                    src: '#awesome-popup'
                                },
                                type: 'inline'
                            });
                        } else {
                            window.$.magnificPopup.open({
                                items: {
                                    src: '#outlet-error-popup'
                                },
                                type: 'inline'
                            });
                        }
                        
                    }

                    /* Error response */
                    if (res.data.status === "error") {
                    
                        this.setState({deliveryInfo: deliveryInfo});            
                        
                        
                        if(res.data.shop_close !== undefined && res.data.shop_close == 'Yes') {

                            showAlert('Error', 'Unable to take your orders right now, please try again later.');
                            window.$.magnificPopup.open({
                                items: {
                                src: '.alert_popup'
                                },
                                type: 'inline'
                            });
                            this.props.history.push('/');

                        } else {
                        
                        window.$.magnificPopup.close();
                        window.$.magnificPopup.open({
                        items: {
                            src: '#error-postal-popup'
                        },
                        type: 'inline'
                        });
                        
                        }
                        
                        if (first === 0) {
                        var mgsTxt = (res.data.message !== '') ? res.data.message : 'Please enter valid postal code.';  
                        $(".postal_error").html('<span class="error">'+mgsTxt+'</span>');
                        }
                        
                    }
                                    
                }));
        

    }

    gobckOutletpopup() {
        window.$.magnificPopup.close();
        this.props.history.push('/');
        return false;
    }   

    showUnitNum(unit1,unit2) {
        unit1 = (typeof unit1 !== 'undefined') ? unit1 : '';
        unit2 = (typeof unit2 !== 'undefined') ? unit2 : '';

        if(unit1 !== '') {
            var unitNo = (unit2 !== '') ? unit1+' - '+unit2 : unit1;
        } else {
            var unitNo = unit2;
        }
    
        return (unitNo !== '')?'#'+unitNo:'';
    }

    setOrderOutletDateTimeData() {
        
        var seletedOrdDate = this.state.seleted_ord_date;
        var seletedOrdTime = this.state.seleted_ord_time;
        if(seletedOrdDate !== '' && seletedOrdTime !== '' && seletedOrdDate !== null && seletedOrdTime !== null) {
            var orderInfoData =  this.state.deliveryInfo;
            if(Object.keys(orderInfoData).length > 0) {
                
                var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
                var OrderHours = seletedOrdTime.getHours();
                var OrderMunts = seletedOrdTime.getMinutes();
                var OrderSecnd = seletedOrdTime.getSeconds();
                var orderDateTime = new Date(OrderDate);
                      orderDateTime.setHours(OrderHours);
                      orderDateTime.setMinutes(OrderMunts);
                      orderDateTime.setSeconds(OrderSecnd);
            
                var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
                var deliveryTime = this.convPad(OrderHours)+":"+this.convPad(OrderMunts)+":"+this.convPad(OrderSecnd);          
                //console.log(orderDateTime,deliveryDate,deliveryTime,'orderDateTime,deliveryDate,deliveryTime');
                cookie.save('orderDateTime', orderDateTime);
                cookie.save('deliveryDate', deliveryDate);
                cookie.save('deliveryTime', deliveryTime);
                
                
                if(this.state.seletedAvilablityId === deliveryId) {
                    cookie.save('orderZoneId', orderInfoData['orderZoneId']);
                    cookie.save('orderDeliveryAddress', orderInfoData['orderDeliveryAddress']);
                }
                
                cookie.save('orderOutletId', orderInfoData['orderOutletId']);
                cookie.save('orderOutletName', orderInfoData['orderOutletName']);
                cookie.save('orderPostalCode', orderInfoData['orderPostalCode']);
                cookie.save('orderTAT', orderInfoData['orderTAT']);
                cookie.save('orderHandled', orderInfoData['orderHandled']);
                cookie.save('defaultAvilablityId', orderInfoData['defaultAvilablityId']);
                cookie.save('orderHandledByText', orderInfoData['orderHandledByText']);
                cookie.save('outletchosen', orderInfoData['defaultAvilablityId']);
                window.$.magnificPopup.close();
                if(cookie.load('popuptriggerFrom') === 'FeaturedPro') {
                   cookie.remove('popuptriggerFrom');
                   this.props.history.push('/');
                } else {
                  /* this.props.history.push('/menu'); */
                   window.location.reload();

                }
                
            } else {
                $.magnificPopup.open({
                    items: {
                      src: '#outlet-error-popup'
                    },
                    type: 'inline'
                });
            }
            
        } else {
            $(".ordrdatetime_error").html('<span class="error"> Please select order date and time.</span>');
        }
    }

    setdateTimeFlg = (field, value) => {
        if(field == 'tmflg') {
              this.setState({getDateTimeFlg:value});
        }   
        else if(field == 'ordDate') {
              var ordTime = '';
              $('.ordrdatetime_error').html('');
              this.setState({seleted_ord_date:value,seleted_ord_time:ordTime});
        }       
        else if(field == 'ordTime') {
              $('.ordrdatetime_error').html('');
              this.setState({seleted_ord_time:value});
        }
        else if(field == 'triggerErrorPopup') {
              $.magnificPopup.open({
                  items: {
                    src: '#outlet-error-popup'
                  },
                  type: 'inline'
              });
        }     
    }

    userAddressList(typeTxt) {
        if (this.state.secondaryaddresslist.length > 0) {
              var addListTxt = this.state.secondaryaddresslist.map((addr, index) =>
                  <div className="address_linfo" key={typeTxt+'-'+index}>
                     <div className="custom_radio">
                      <input type="radio" name={"address_chk"+typeTxt} value={addr.postal_code} className="address_chk" onChange={this.changPostalValue.bind(this, typeTxt, addr.postal_code)} />
                      <span>{(addressFormat(addr.unit_code, addr.unit_code2, addr.address, addr.country, addr.postal_code))}</span>
                      </div>        
                  </div>);
                  
                return (<div>
                         {addListTxt}
                         <div className="address_linfo" key={typeTxt+'dfl'}>
                             <div className="custom_radio">
                              <input type="radio" name={"address_chk"+typeTxt} value='' defaultChecked={true} className="address_chk" onChange={this.changPostalValue.bind(this, typeTxt, '')} />
                              <span>Enter New Address</span>
                              </div>        
                          </div>    
                        </div>);    
                  
          } else {
              return ''
          }
        
    }

    changPostalValue(type,pstVl) {
        if(type === 1) {
          $("#postalcode").val(pstVl);
        } else {
          $("#postalcode1").val(pstVl); 
        }
    }

    convPad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    
    
    /* facebook login */
    responseFacebook = (response) => {
        if (response.name !== "" && response.email !== "" && response.name !== undefined && response.email !== undefined) {
            var vSplitName = response.name.split(" ");
            var firstname = vSplitName[0];
            var lastname = vSplitName[1];
            var birthday = response.birthday;
            var qs = require('qs');
            var photo = response.picture.data.url;
            /* update gender field */
            var postGender = "";
            if (typeof response.gender !== "undefined" && response.gender === "male") {
                postGender = "M";
            } else if (typeof response.gender !== "undefined" && response.gender === "female") {
                postGender = "F";
            }
            /* update DOB */
            var dob = '';
            if (typeof birthday !== "undefined" && birthday !== "") {
                dob = dateFormat(response.birthday, "yyyy-mm-dd");
            }



            var postObject = {
                "app_id": appId,
                "type": "web",
                "login_firstname": response.first_name,
                "login_lastname": response.last_name,
                "login_username": response.email,
                "customer_fb_id": response.id,
                "customer_gender": postGender,
                "customer_photo": photo,
                "customer_dob": dob
            };
            this.props.getFbLoginData(qs.stringify(postObject));
            
        }
    }

   /* for login and facebook login*/
   doLogin(fbloginData){
       hideLoader('login_submit','class');
       if (fbloginData.status === "ok") {
            $.magnificPopup.close();
            var mobileno = '';
            if (typeof fbloginData.result_set.customer_phone === "undefined" || fbloginData.result_set.customer_phone === "null" || fbloginData.result_set.customer_phone === "") {
                mobileno = "";
            } else {
                mobileno = fbloginData.result_set.customer_phone;
            }

            cookie.save("UserId", fbloginData.result_set.customer_id, { path: '/' });
            cookie.save("UserEmail", fbloginData.result_set.customer_email, { path: '/' });
            cookie.save("UserFname", (fbloginData.result_set.customer_first_name !== "" ? fbloginData.result_set.customer_first_name : ''), { path: '/' });
            cookie.save("UserLname", (fbloginData.result_set.customer_last_name !== "" ? fbloginData.result_set.customer_last_name : ''), { path: '/' });
            cookie.save("UserMobile", mobileno, { path: '/' });

            cookie.save("UserDefaultAddress", fbloginData.result_set.customer_address_name, { path: '/' });
            cookie.save("UserDefaultUnitOne", fbloginData.result_set.customer_address_line1, { path: '/' });
            cookie.save("UserDefaultUnitTwo", fbloginData.result_set.customer_address_line2, { path: '/' });
            cookie.save("UserDefaultPostalCode", fbloginData.result_set.customer_postal_code, { path: '/' });

            const{history} = this.props;

           if(cookie.load('cateringPay') === 'start') {

                showAlert('Success', 'Logged in Successfully!');
                $.magnificPopup.open({
                    items: {
                        src: '.alert_popup'
                    },
                    type: 'inline'
                });

                history.push("/payment/"+cookie.load('orderIdTxt')+"/"+cookie.load('paymentIdTxt'));
                /*history.push("/payment");*/

          } else {

            var qs = require('qs');
            var postObject = {
                "app_id": appId,
                "reference_id": getReferenceID(),
                "customer_id": fbloginData.result_set.customer_id,
                "availability_id": cookie.load('defaultAvilablityId')
            };

            axios.post(apiUrl + "cart/update_customer_info", qs.stringify(postObject)).then(res => {
                showAlert('Success', 'Logged in Successfully!');
                $.magnificPopup.open({
                    items: {
                        src: '.alert_popup'
                    },
                    type: 'inline'
                });
                if (res.data.status === "ok") {
                    if(cookie.load('loginpopupTrigger')==='fromcheckout') {
                      cookie.remove('loginpopupTrigger');
                      history.push("/checkout");
                    } else if(cookie.load('loginpopupTrigger')==='fromchecatering') {
                      cookie.remove('loginpopupTrigger');
                      history.push("/catering");  
                    } else if(cookie.load('redirectReservatin')==='Yes') {
                      cookie.remove('redirectReservatin');
                      history.push("/reservation");  
                    } else {
                      history.push("/myaccount");
                    }
                } else {
                    if(cookie.load('redirectReservatin')==='Yes') {
                      cookie.remove('redirectReservatin');
                      history.push("/reservation");  
                    } else {
                        history.push("/myaccount");
                    }
                }
            });
            
         }

        } else {
            cookie.remove('loginpopupTrigger');
            /*showAlert('Error', 'Invalid Login Credentials','trigger_login','#login-popup');*/
            showAlert('Error', 'Invalid Login Credentials');
            $.magnificPopup.open({
                items: {
                    src: '.alert_popup'
                },
                type: 'inline'
            });
        }
    }
    
    
    
    /*  Onchange  for Guest Checkout */
    fieldChangecheckout = (field, value) => {
        if (field == 'termsa') {
            value = $("#termsa").prop("checked");
        }
        this.setState(update(this.state, { fieldscheckout: { [field]: { $set: value } } }))
    }

    handleCheckout = () => {
        const formPayload = this.state.fieldscheckout;
        if (document.getElementById("spn-email-error").innerHTML === '<span class="error">This email already exists</span>') {
            return false;
        }
        if (document.getElementById("spn-mobile-error").innerHTML === '<span class="error">This mobile number already exists</span>') {
            return false;
        }
        
        showLoader('login_in_guest','class');
        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_first_name": formPayload.check_firstname,
            "customer_email": formPayload.check_email,
            "customer_phone": formPayload.check_phone,
            "customer_birthdate": formPayload.birthday,
            "site_url": this.props.globalsettings[0].result_set.client_site_url,
        };

        var header = {
            headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
          }

          if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
            header.headers.Auth = cookie.load('Token')
          }
        
        axios.post(apiUrl + "guestaccount/create", qs.stringify(postObject), header)
            .then(response => {
                hideLoader('login_in_guest','class');
                if (response.data.status === "ok") {
                    window.scrollTo(0, 0);
                    $.magnificPopup.close();
                    
                    /* set User cookie values - Start */
                    cookie.save("Token", response.data.result_set.token);
                    
                    localStorage.setItem('BeaerToken', response.data.result_set.token);
                    
                    cookie.save("UserId", response.data.result_set.customer_id);
                    cookie.save("UserFname", (response.data.result_set.customer_first_name !== "" ? response.data.result_set.customer_first_name : ''));
                    cookie.save("UserLname", (response.data.result_set.customer_last_name != "" ? response.data.result_set.customer_last_name : ''));
                    cookie.save("UserMobile", response.data.result_set.customer_phone);
                    cookie.save("UserEmail", response.data.result_set.customer_email);
                    cookie.save("UserDefaultAddress", response.data.result_set.customer_address_name);
                    cookie.save("UserDefaultUnitOne", response.data.result_set.customer_address_line1);
                    cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2);
                    cookie.save("UserDefaultPostalCode", response.data.result_set.customer_postal_code);
                    cookie.save("userAccountType",1);
                    const{history} = this.props;

                    var qs = require('qs');
                    var postObject = {
                        "app_id": appId,
                        "reference_id": getReferenceID(),
                        "customer_id": response.data.result_set.customer_id,
                        "availability_id": cookie.load('defaultAvilablityId')
                    };

                     var header = {
                        headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
                      }

                     // if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
                        header.headers.Auth = response.data.result_set.token
                      //}

                    axios.post(apiUrl + "cart/update_customer_info", qs.stringify(postObject), header).then(res => {
                            showAlert('Success', 'Logged in Successfully!');
                            $.magnificPopup.open({
                            items: {
                                src: '.alert_popup'
                            },
                            type: 'inline'
                            });
                        if (res.data.status === "ok") {
                            if(cookie.load('loginpopupTrigger')==='fromcheckout') {
                                cookie.remove('loginpopupTrigger');

                                setTimeout(function(){

                                history.push("/checkout");
                                },800);
                            }  else {
                                
                            setTimeout(function(){

                            history.push("/myaccount");
                            },800);


                            }
                        }
                        else {
                            
                            setTimeout(function(){

                            history.push("/myaccount");
                            },700);

                            
                        }
                    });

                    
                    

                } else {
                    if (response.data.form_error) {
                        $('.guest-chk-error').html('<div class="alert alert_danger" style="display:block">'+response.data.form_error+'</div>');

                    } else {

                        $('.guest-chk-error').html('<div class="alert alert_danger" style="display:block">'+response.data.message+'</div>');

                    }
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    /*forget password  - start*/
    fieldforgot = (field, value) => {
        this.setState(update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } }))
    }
    
    forgotpassword = () => {
        this.setState({fpstatus:'loading'});
        const formPayload = this.state.fieldsfgtpassword;

        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "email_address": formPayload.email,
           "site_url": this.props.globalsettings[0].result_set.client_site_url,
        };
        
       showLoader('forgotpassword-cls','class');
       this.props.getForgetPassword(qs.stringify(postObject));
    }
    /*forget password  - end*/
    
    /* signin - start*/
    fieldChange = (field, value) => {
        this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
    }
    
    handleSignin = () => {
        const formPayload = this.state.fields;
        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "login_username": formPayload.email,
            "login_password": formPayload.password
        };
        showLoader('login_submit','class');
        this.props.getLoginData(qs.stringify(postObject));

    }
   /* signin - end*/
   
   
   /* for signup - start*/
    fieldChangeSignup = (field, value) => {
        if (field == 'terms') {
            value = $("#terms").prop("checked");
        }
        if (field == 'pdpa_consent') {
            value = $("#pdpa_consent").prop("checked");
        }
        this.setState(update(this.state, { fieldssignup: { [field]: { $set: value } } }))
    }
     
    handleSignup = () => {
        
        const formPayload = this.state.fieldssignup;
        this.setState({regstatus:'loading'});
        
        var pdpaConsent = (formPayload.pdpa_consent === true) ? 'yes' : 'no';
        
        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_first_name": formPayload.firstname,
            "customer_email": formPayload.email,
            "customer_password": formPayload.password,
            "customer_phone": formPayload.mobile,
            "customer_gender": formPayload.gender,
            "customer_birthdate": formPayload.birthday,
            "customer_pdpa_consent": pdpaConsent,
            "site_url": this.props.globalsettings[0].result_set.client_site_url,
        };
        
        showLoader('signup_submit','class');
        this.props.getRegistration(qs.stringify(postObject));
 
    }
    /* for signup - end*/
    
    /* show message */
    showMessage(response){
       hideLoader('signup_submit','class');
       hideLoader('forgotpassword-cls','class');
       if (response.status === "ok") {
          showAlert('Success', (response.message)?response.message:'Mail has been sent');
        } else {
            if (response.form_error) {
               showAlert('Error', response.form_error);
             } else {
                showAlert('Error', response.message);
             }
        }
        $.magnificPopup.open({
            items: {
              src: '.alert_popup'
            },
            type: 'inline'
        });
    }
    
    
    changeAvailability() {
        
        var tempArr = [], tempVl = '';
        this.setState({seletedOutletId:tempVl,deliveryInfo:tempArr,pickupInfo:tempArr,seleted_ord_date:tempVl,seleted_ord_time:tempVl});
        
        this.destroyCart("Yes");
        
        var popupIdtxt = '';
        if(this.state.nextavail === deliveryId){
          popupIdtxt = '#delevery-popup';
        }else {}
        
        if(popupIdtxt !== '') {
            $.magnificPopup.open({
                items: {
                  src: popupIdtxt
                },
                type: 'inline'
            });
        }
    }

    destroyCart(clear='No') {
        this.props.destroyCartDetail();
        this.deleteOrderCookie(clear);
    }

    deleteOrderCookie(clear='Yes') {
        if(clear == 'Yes'){
            cookie.remove('orderZoneId');
            cookie.remove('orderOutletId');
            cookie.remove('outletchosen');        
        }
        
        removeOrderDateTime();
        removePromoCkValue();

        
        cookie.remove('orderPaymentMode');
        cookie.remove('orderTableNo');
        cookie.remove('product_remarks');
        cookie.remove('orderOutletName');    
        cookie.remove('carttotalitems');
        cookie.remove('cartsubtotal');
        cookie.remove('cartid');
        cookie.remove('firstNavigation');
        
        /* Delivery avilablity */
        cookie.remove('orderDateTime');
        cookie.remove('deliveryDate');
        cookie.remove('deliveryTime');
        cookie.remove('unitNoOne');
        cookie.remove('unitNoTwo');

        cookie.remove('promotion_id');
        cookie.remove('promotion_applied');
        cookie.remove('promotion_code');
        cookie.remove('promotion_delivery_charge_applied');
        cookie.remove('promotion_amount');
        cookie.remove('promotion_category');
        cookie.remove('prmo_type');
        
        /*Remove voucher*/
        cookie.remove('voucher_applied');
        cookie.remove('voucher_code');
        cookie.remove('voucher_amount');
            
        cookie.remove('points_redeemed');
        cookie.remove('points_used');
        cookie.remove('points_amount');
        cookie.remove('prmo_type');
    }

    createLink(menu){
        var url = window.location.pathname;
        if(menu.nav_type==="0")
        {
          return <Link to={"/page/"+menu.nav_title_slug} title="" className={(url === "/page/"+menu.nav_title_slug)? 'active' : ''}>
            
                          <span>{menu.nav_title}</span>
                        </Link>;
    
          }
          else if(menu.nav_type==="3")
          {  
            let target= ""; 
            if(menu.nav_link_type === "self"){
              target= ""; 
            }else{
              target = "_blank";
            }
           return  <a target={target} href={menu.nav_pages} title={menu.nav_title}><span>{menu.nav_title}</span></a>;
           }
        }

        listMainNavigation(){
             return this.state.menudata.map(function (menu, i) {
                   return  (<li key={i+100}>{this.createLink(menu)}</li>);
           },this)
    }


    validateIntval(e) {
        const re = /[0-9]+/g;
        if (!re.test(e.key)) {
          e.preventDefault();
        }
    }

    render() {
        let url = window.location.pathname;

        let currenturl = window.location.href;
        let substring = "menu";
        let isCheckout = "checkout";

        let showCatryName = (typeof this.props.showCatryName !== "undefined") ? this.props.showCatryName : 'Category Name';

        let socialsettingData = [];

        let facebook,instagram,email_info,youtube,twitter,linkedin,pinterest ='';

        if(this.state.footerSocialcontent) {

            socialsettingData = this.state.footerSocialcontent;

            if(socialsettingData.socailnetwork_facebook_url){
                facebook = <a href={socialsettingData.socailnetwork_facebook_url}  target="_blank" className="facebook icon"></a>
            }

            if(socialsettingData.socailnetwork_instagram_url){
                instagram = <a href={socialsettingData.socailnetwork_instagram_url} target="_blank" className="instagram icon"></a>
            }

            if(socialsettingData.socailnetwork_gplus_url){
                email_info = <a href={"mailto:"+socialsettingData.socailnetwork_gplus_url} target="_top" rel="noopener noreferrer" className="mail icon"></a>
            }
        }
    
        return (
            <header>
            <div className="header">
                <div className="header-top-cls">
                <div className="container-full">
              <div className="logo-main-section">
                <div className="mobile_nav_grp">
                <div className="mobile_nav_btn">
                <a href="javascript:void(0)" onClick={this.handleMenu.bind(this)} className={(this.state.menu===true)?'trigger-menu menu-icon mobile-menu-item active':'trigger-menu mobile-menu-item menu-icon'}>
                        <span className="icon_menu1"></span>
                        <span className="icon_menu2"></span>
                        <span className="icon_menu3"></span>
                    </a>
                </div>
                <div className={(this.state.menu===true) ? "mobile-login hide" : 'mobile-login show' }>
                    <a href="#" className="controller-nav"><img src={userLogin} /></a>
                    <ul className="mobile-login-list">
                    
                    
                     {!cookie.load("UserId") && 
                            <>
                                <li>
                                <a
                                  href="#login-popup"
                                  data-effect="mfp-zoom-in"
                                  className="open-popup-link htico_sign"
                                  title="Login"
                                >
                                  Login
                                </a>
                            </li>
                            <li>
                                <a
                                  href="#signup-popup"
                                  data-effect="mfp-zoom-in"
                                  className="open-popup-link"
                                  title="Sign up"
                                >
                                  Register
                                </a>
                            </li></>
                         
                        }
                            
                            {cookie.load("UserId") &&  <><li>
                                <Link className="htico_sign" to="/myaccount"> My Account</Link></li>
                            <li>
                                <Link className="htico_sign" to="/logout" >Logout</Link>
                            </li></>
                            
                            }
                    
                    
                        
                        
                        
                    </ul>
                </div>
                </div>
                <div className="logo">
                  <Link to={"/"}>
                    <img src={logo} className="desk_logo" alt="Logo" />
                    <img src={mobLogo} className="mobi_logo" alt="Logo" />
                  </Link>
                </div>
                <div className="hmenu_sec">
                 <ul className="hmenu_list desktop_hmenu_list">
                     <li className={(url === "/")? 'active' : ''}><a title="#" target="" href="/"><span>Home</span></a></li>
                     <li className={(currenturl.includes('/menu'))? 'active' : ''}><Link to={"/menu"} title="Menu"><span>Menu</span></Link></li>
                          
                      {this.state.menudata && this.listMainNavigation()}
                      
                      <li className={(url === "/contact-us")? 'active' : ''}><Link to={"/contact-us"} title="Contact Us"><span>Contact Us</span></Link></li>    
 
                    </ul>

                </div>

                <div className="hmenu-login-section">
                  <ul className="hmenu-login-act">
                      
                      
                      {!cookie.load("UserId") && <li className="hsign_sec">
                            <a href="#login-popup" data-effect="mfp-zoom-in" className="open-popup-link htico_sign" title="Login"><span>Login</span></a>
                            <span>|</span>
                            <a href="#signup-popup" data-effect="mfp-zoom-in" className="open-popup-link" title="Sign up"> <span>Sign Up</span></a>
                            </li>}
                            
                            {cookie.load("UserId") &&  <li className="hsign_sec">
                                <Link className="htico_sign" to="/myaccount" title="My Account"><i /> <span>My Account</span></Link><span>|</span>
                                <Link className="htico_sign" to="/logout" title="Logout"><i /> <span>Logout</span></Link>
                            </li>}

                      <li className="hordertype_sec" key="2=h">
                      <a href="#" className="hordertype_btn">  DELIVERY</a>
                    </li>
                    {/*<li className="htico_search">
                        <a href="#" className="hsearch_trigger">
                            <img src={searchImg} />
                        </a>
                    </li>*/}
                    {/*------Cart List start-------*/}  
                    <CartSideBar {...this.props} headerState={this.state} prpSateValChange={this.props.sateValChange} />
                    {/*------Cart List end-------*/}    
                    
                  </ul>
                </div>
              </div>
            </div>
                
            <div className={(this.state.menu===true)?'full-screen-overlay active':'full-screen-overlay'}>
                <div className="overlay-menu-wrap">
                    <div className="logo-header">                   
                        <a href="/" className="logo"><img src={logo} /></a>
                    </div>
                    <div className="overlay-menu">  

                        <ul>
                        <li><Link to={"/menu"} title="Order Now">Order Now</Link></li>
                        {!cookie.load("UserId") && 
                            
                            <li><a href="#login-popup" data-effect="mfp-zoom-in" className="open-popup-link htico_sign" title="Login">Login</a></li>
                        
                        }
                        {cookie.load("UserId") &&  <li>
                            <li><Link to={"/myaccount"} title="My Account">My Account</Link></li>
                        </li>}
                        <li><Link to={"/menu"} title="Menu">Menu</Link></li>
                        {this.state.menudata && this.listMainNavigation()}  
                        <li><Link to={"/contact-us"} title="Contact Us">Contact Us</Link></li>  
                        </ul>
 
                    </div>
                    <div className="social-media-overlay">                              
                        <span className="follow-us">Follow Us</span>
                        {facebook}
                        {instagram} 
                        {email_info}
                    </div>
                </div>
            </div>



            
            <div className="hordermenu_sec">
            {currenturl.includes(substring) && <div className="hcategory_sec">
                   <span className="hcategory_selected_text catergory_title_name_span"><span className="hcategory_item">{showCatryName} <img src={brushStroke} /></span></span>
                    <span className="hcategory_selected_text middle-text open_drop_down_category_menu"><span className="hcategory_trigger middle-text-trigger"></span> <span className="hcategory_item">Click Here To View Full Menu</span></span>
                    <div className="hcategory_menu">
                        <MenuNavigationmob />
                    </div>
               </div>}    
            
            <div className="hordertype_sec">
                    <a href="#" className="hordertype_btn">  DELIVERY {/* <i class="arrow_myaccount"></i> */}</a>
                    {/* <div className="mobile_account_menu" style={{display: "none"}}>
                        <ul className="mobile_account_delivery_item">
                            <li>
                                <a href="javascript:;" className="open-popup-link" >Delivery</a></li>
                            <li>
                                <a href="javascript:;" className="open-popup-link">Takeway</a></li>
                        </ul>
                    </div> */}
            </div>
            </div>      

            </div>
            <div className="header_back"></div>
            </div>

            

            <div className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide" id="jquery-error-msg" style={{display:'none'}}> <a href="javascript:void(0)" className="custom_close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </a> <p className="jquery-error-msg">Something went wrong</p> </div>
            
            
            <div className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide" id="jquery-common-error-msg" style={{display:'none'}}> <a href="javascript:void(0)" className="custom_close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </a> <p className="jquery-common-error-msg">Something went wrong</p> </div>
            
            
            {/* login popup */}

                 <div id="login-popup" className="white-popup mfp-hide popup_sec login-popup">
                 <div className="pop-whole">
                    <div className="pop-whole-lhs">
                         <div className="pop-whole-lhs-inner">
                             <h3>Have an account ?</h3>
                             <p>Log in with your details to manage.</p>
                         </div>
                     </div>
                     <div className="inside-popup-rhs">
                         <div className="popup-footer">
                             {/*<FacebookLogin
                                     appId={fbAppId}
                                     fields="name,email,picture,first_name,last_name,birthday,gender"
                                     callback={this.responseFacebook}
                                     scope="public_profile,email,user_birthday"
                                     cssclassName="btn btn-black  fa fa-facebook"
                                     redirectUri={baseUrl}
                                     icon="fa-facebook"
                                     textButton="&nbsp; Login with Facebook"
                             />*/}
                             <span className="display_block text-center">Continue As</span>
                             <a href="#guest-popup" className="btn guest_btn open-popup-link"> <i className="fa fa-user-o" aria-hidden="true"></i>Guest Checkout </a>
                             
                             <div className="or-seperator"><span>Or</span></div>
                         </div>
                         <div className="popup-header textcenter">
                             <h4>Login with <img src={logo} /></h4>
                         </div>
                          <Login fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleSignin} onInvalid={() => console.log('Form invalid!')} />

                     </div>
                 </div>
                </div>
                
               {/* Signup popup */}
                <div id="signup-popup" className="white-popup mfp-hide popup_sec signup-popup">
                 <div className="pop-whole">
                    <div className="pop-whole-lhs">
                        <div className="back_click_mbl">
                            <a href="#login-popup" className="open-popup-link" >Back</a>
                        </div>
                        <div className="pop-whole-lhs-inner">
                            <h3>New to BBBURGERS?</h3>
                            <p>Create your BBBURGERS account.</p>
                        </div>
                    </div>
                     <div className="inside-popup-rhs">
                        <div className="back_click">
                            <a href="#login-popup" className="open-popup-link" >Back</a>
                        </div>
                         <div className="popup-footer signup-popup-head">
                             {/*<FacebookLogin
                                     appId={fbAppId}
                                     fields="name,email,picture,first_name,last_name,birthday,gender"
                                     callback={this.responseFacebook}
                                     scope="public_profile,email,user_birthday"
                                     cssclassName="btn btn-black  fa fa-facebook"
                                     redirectUri={baseUrl}
                                     icon="fa-facebook"
                                     textButton="&nbsp; Login with Facebook"
                             />*/}
                             <span className="display_block text-center">Continue As</span>
                             <a href="#guest-popup" className="btn guest_btn open-popup-link"> <i className="fa fa-user-o" aria-hidden="true"></i>Guest Checkout </a>
                             
                             <div className="or-seperator"><span>Or</span></div>
                         </div>
                         <div className="popup-header textcenter">
                             <h4>Create account with <img src={logo} /></h4>
                         </div>
                         <Signup fields={this.state.fieldssignup} onChange={this.fieldChangeSignup} onValid={this.handleSignup} onInvalid={() => console.log('Form invalid!')} />
                     </div>
                 </div>
                </div>
            
            {/* Guest Checkout */}
            <div id="guest-popup" className="white-popup popup_sec guest-popup mfp-hide">
                <div className="pop-whole">
                    <div className="pop-whole-lhs">
                        
                        <div className="pop-whole-lhs-inner">
                            <img className="ico_pop" src={ico_login} alt="" />
                            <h3>Express Checkout</h3>
                            <p>Update Your Information and Continue</p>
                        </div>
                    </div>
                    <div className="inside-popup-rhs">
                        <div className="back_click">
                            <a href="#login-popup" className="open-popup-link" >Back</a>
                        </div>                                  
                        <div className="popup-header textcenter" id="register_form_header">
                            <h3 className="text-uppercase">Enter your info</h3>
                        </div>

                        <Guestcheckout {...this.props} fields={this.state.fieldscheckout} onChange={this.fieldChangecheckout} onValid={this.handleCheckout} onInvalid={() => console.log('Form invalid!')} />
                    </div>
                </div>
                <button title="Close (Esc)" type="button" className="mfp-close">×</button>
            </div>

            {/* Forgot Password Popup */}

                <div id="forgot-password-popup" className="white-popup mfp-hide popup_sec login-popup forgot-password-popup">
                 <div className="pop-whole">

                  <div className="pop-whole-lhs">
                     <div className="pop-whole-lhs-inner">
                         <h3>Forgot your password?</h3>
                         <p>You can reset your password here.</p>
                     </div>
                  </div>

                  <div className="inside-popup-rhs">
                     <div className="popup-header textcenter">
                         <h4><img src={logo} /></h4>
                     </div> 
                     <Forgotpassword fields={this.state.fieldsfgtpassword} onChange={this.fieldforgot} onValid={this.forgotpassword} onInvalid={() => console.log('Form invalid!')} />
                  </div>

                 </div>
                </div>

             {/*  Delivery Postal code Popup - start */}
             <div id="delevery-postcode-popup" className="white-popup mfp-hide popup_sec delevery_popup">
                    <div className="popup_equalrw">
                      <div className="popup_ttsec">
                        <div className="innervmid_in">
                          <div className="pop_title">
                            {/*<img src={scooterBg} />*/}
                            <img className="pop-scooter-img" src={deliveryImg} />
                            <h2 className="text-uppercase">Let us know</h2>
                            <small>Your Delivery Location</small>
                          </div>
                        </div>
                      </div>
                      <div className="popup_right">
                        <div className="innervmid_in">
                            
                            {cookie.load("UserId") &&  <div className="address-list-cls address-list-main">
                            {this.userAddressList(1)}
                            </div>}
                            
                            <div className="form-group">
                              <div className={(this.state.secondaryaddresslist.length > 0)?"focus-out focused":"focus-out"}>
                                <label>Enter your postal code</label>
                                <input type="text" id="postalcode" onKeyPress={(e) => this.validateIntval(e)} maxLength="6"  className="form-control input-focus" />
                                <div className="postal_error"></div>
                              </div>
                            </div>
                            <div className="btn_sec">
                              <div className="two-button-row">
                                <div className="go_second">
                                
                                  <a href="javascript:;" onClick={this.gobckOutletpopup.bind(this)} className="button button-left" title="Go Back">Go Back</a>
                                </div>
                                <div className="con_first delivery_submit_cls">
                                
                                <input type="button" onClick={this.findOutletBasedZone.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value="Continue" />
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
             {/* Delivery Popup - end */}
            
             {/* success popup - Start */}
            <div id="awesome-popup" className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime">
            <div className="popup_equalrw">
                <div className="popup_ttsec">
                <div className="innervmid_in">
                    <div className="pop_title">
                    <img className="pop-scooter-img" src={iconWin} />
                    <h2 className="text-uppercase">Awesome</h2>
                    {(cookie.load('defaultAvilablityId') === deliveryId) && <small>We can Deliver !</small>}
                    </div>
                    <div className="awesome_del">
                    <h5>Your Delivery Address:</h5>
                    <h2>{this.state.orderDeliveryAddress}</h2>
                    </div> 
                </div>
                </div>
                <div className="popup_right">
                <div className="innervmid_in">
                    
                    <div className="datetime_selt_sec">
                        <div className="datetime_selt_lbl">
                        {(this.state.seletedAvilablityId === deliveryId) ? 'Select Your Delivery Date & Time' : 'Select Your Pickup Date & Time'}
                        </div> 
                        
                        {!currenturl.includes(isCheckout) && <OrderdatetimeSlot {...this.props} hdrState={this.state} setdateTimeFlg={this.setdateTimeFlg} />}
                        
                        <div className="ordrdatetime_error"></div>
                    </div>
                    
                    <div className="btn_sec">
                        <input type="button" onClick={this.setOrderOutletDateTimeData.bind(this)} className="button" value="Continue" />
                    </div>
                    
                </div>
                </div>
            </div>
            </div>
            {/* success popup - end */}

              {/* Delevery Popup error - start */}
              <div id="error-postal-popup" className="white-popup mfp-hide popup_sec error_postal_popup">
                    <div className="popup_equalrw">
                      <div className="popup_ttsec">
                        <div className="innervmid_in">
                          <div className="pop_title poptt_icontop text-center">
                            <img src={iconUnhappy} />
                            <h2 className="text-uppercase">Sorry !</h2>
                            <small>We cant find your postal code</small>

                          </div>
                        </div>
                      </div>
                      <div className="popup_right">
                        <div className="innervmid_in">
                         {cookie.load("UserId") &&  <div className="address-list-cls address-list-error">
                            {this.userAddressList(2)}
                            </div>}
                          <div className="error-title">Change Your Postal Code</div>
                          <form className="form_sec">
                            <div className="form-group">
                              <div className={(this.state.secondaryaddresslist.length > 0)?"focus-out focused":"focus-out"}>
                                <label>Enter your postal code</label>
                                <input type="text" id="postalcode1" onKeyPress={(e) => this.validateIntval(e)} maxLength="6" className="form-control input-focus" />
                                <div className="postal_error"></div>
                              </div>
                            </div>
                            <div className="btn_sec delivery_submit_cls delivery_submit_div">
                              <input type="button" onClick={this.findOutletBasedZone.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Delevery Popup error - Start */}

            {/* Timeslot error popup - start */}
            <div id="outlet-error-popup" className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup">
            <div className="custom_alert">
                <div className="custom_alertin">
                    <div className="alert_height">
                        <div className="alert_body">
                            <img className="warning-popup-img" src={warningImg} />
                            <h2 className="text-uppercase">Sorry</h2>
                            <p>{'We can`t Deliver At the Moment!'}</p>
                            <p>Please come back again.</p> 
                            <div className="alt_btns">
                            <a href="javascript:;" onClick={this.gobckOutletpopup.bind(this)}  className="button button-right popup-modal-dismiss">change address</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* Timeslot error popup - end */}
            

             {/* Warning Popup - start */}
             <div id="warning-popup" className="white-popup mfp-hide popup_sec warning_popup">
                    <div className="custom_alert">
                        <div className="custom_alertin">
                            <div className="alert_height">
                                <div className="alert_header">Warning</div>
                                <div className="alert_body">
                                    <img className="warning-popup-img" src={warningImg} />
                                    <p>By switching you are about to clear your cart.</p>
                                    <p>Do you wish to proceed ?</p> 
                                    <div className="alt_btns">
                                        <a href="javascript:;"  className="popup-modal-dismiss button button-left">No</a>
                                        <a href="javascript:;" onClick={this.changeAvailability.bind(this)}  className="button button-right popup-modal-dismiss">Yes</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
            {/* Warning Popup - end */}

            </header>

             );
    }
}


const mapStateTopProps = (state) => {

    var zonedetailArr = Array();
    if(Object.keys(state.zonedetail).length > 0) {
           if(state.zonedetail[0].status === 'ok') {
               zonedetailArr = state.zonedetail[0].result_set;
           }
    }

    var secondarydataArr = Array();
    if(Object.keys(state.secondaryaddress).length > 0) {
           if(state.secondaryaddress[0].status === 'ok') {
               secondarydataArr = state.secondaryaddress[0].result_set;
           }
    }

    return {
      globalsettings: state.settings,
      menudata: state.menudata,
      zonedetails: zonedetailArr,
      secondaryaddresslist: secondarydataArr,
      socialsettingData: state.socialsettings,
      logindata: state.login,
    fblogin: state.fblogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      getGlobalSettings: () => {
        dispatch({ type: GET_GLOBAL_SETTINGS });
      },
      getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
        },
        getFbLoginData: (formPayload) => {
          dispatch({ type: GET_FBLOGINDATA, formPayload });
        },
        getRegistration: (formPayload) => {
          dispatch({ type: GET_REGISTRATION, formPayload });
        },
        getForgetPassword: (formPayload) => {
          dispatch({ type: GET_FORGET_PASSWORD, formPayload });
        },
      getMenuData: (menuslug) => {
        dispatch({ type: GET_MENUDATA, menuslug });
      },
      getZoneDetail: (outletId, zoneId) => {
        dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
      },
      destroyCartDetail: () => {
        dispatch({ type: DESTROY_CART_DETAIL });
      },
      getSecondaryAddress: () => {
        dispatch({ type: GET_ALLUSERSECADDRDATA });
      },
    }
  }

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Header));

