/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from "react-slick";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

var Parser = require('html-react-parser');

import { deliveryId, pickupId} from "../Helpers/Config";
import { addressFormat, stripslashes, showPriceValue,timeToConv12, showLoader, hideLoader } from "../Helpers/SettingHelper";

import { connect } from 'react-redux';
import Moment from 'moment';
var qs = require('qs');
import { GET_CORDERDETAIL, GET_PORDERDETAIL, GET_PRINTORDER, GET_ORDERHISTORY, GET_ACTIVITYCOUNT} from '../../actions';

import cookie from 'react-cookies';

import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import pickupImg from "../../common/images/takeaway_old-b.png";
import productImg from "../../common/images/noimg-400x400.jpg";
class Orders extends Component {

    constructor(props) {
        super(props);
        
         this.state = {
        corderdetail: [],         
		cartItems:[],
		cartData:[],
		cart_brktime_list:[],
		showitems:10,
		showpitems:10,
		showresvitems:10,
		resviewmore:'none',
		order_all:0,
		overall_orders:0,
		deliveryTakeaway_orders:0,
	
    };

    if(cookie.load('UserId')===undefined){
     props.history.push("/");
    }
    }
    
    componentDidMount() { 		
		 /* delivery current - past orders */
	    var deliveryparams = '&customer_id=' + cookie.load('UserId') + '&limit=' + this.state.showitems+'&except_availability=yes';
		this.props.getCorderDetail(deliveryparams);
		
		var deliverypastparams = '&customer_id=' + cookie.load('UserId') + '&limit=' + this.state.showitems+'&except_availability=yes';
		this.props.getPorderDetail(deliverypastparams);
         $('#dvLoading').fadeOut(2000);
         		
	
         $('#dvLoading').fadeOut(2000);		
        		
          //Default Action		
		$(".filter_tabsec").hide(); //Hide all content		
		$("ul.web_order_typecls li:first").addClass("active").show(); //Activate first tab		
		$(".filter_tabsec:first").show(); //Show first tab content		
				
				
		//On Click Event		
		$("ul.web_order_typecls li").click(function() {		
			$("ul.web_order_typecls li").removeClass("active"); //Remove any "active" class		
			$(this).addClass("active"); //Add "active" class to selected tab		
			$(".filter_tabsec").hide(); //Hide all tab content		
			var activeTab = $(this).find("a").attr("href"); //Find the rel attribute value to identify the active tab + content		
			$(activeTab).fadeIn(); //Fade in the active content		
			return false;		
		});		
		
		this.getActivityCounts();
    }
 
    /* for order load more  button */
    loadcurrentItems(){
		 var pageNext = this.state.showitems+10;
         this.setState({showitems : pageNext},function(){this.loadcitems()});
        } 
	loadpastItems(){
	     var pagepNext = this.state.showpitems+10;
		 this.setState({showpitems : pagepNext},function(){this.loadpitems()});
	}  
    
    loadcitems()
    {
		$(".load_more_data").append('<b class="gloading_img"></b>');
		var deliveryparams = '&customer_id=' + cookie.load('UserId') + '&availabilityIDs=' + deliveryId + '&limit=' + this.state.showitems+'&except_availability=yes';
		this.props.getCorderDetail(deliveryparams);
	}
    loadpitems()
    {
		$(".load_more_data1").append('<b class="gloading_img"></b>');
		var deliverypastparams = '&customer_id=' + cookie.load('UserId') + '&availabilityIDs=' + deliveryId + '&limit=' + this.state.showitems+'&except_availability=yes';
		this.props.getPorderDetail(deliverypastparams);
	}

	getActivityCounts() {
		const inputKeys = ["catering_orders", "order_all", "overall_orders", "deliveryTakeaway_orders"];
		this.props.getActivityCount(JSON.stringify(inputKeys));
	}

   componentWillReceiveProps(nextProps){
   
	  /*activity count -start */
	  if(Object.keys(nextProps.activitycount).length > 0){
			if(nextProps.activitycount !== this.props.activitycount){
				if(nextProps.activitycount[0].status && nextProps.activitycount[0].result_set){
					this.setState({ order_all: nextProps.activitycount[0].result_set.order_all, overall_orders: nextProps.activitycount[0].result_set.overall_orders, deliveryTakeaway_orders: nextProps.activitycount[0].result_set.deliveryTakeaway_orders,catering_orders: nextProps.activitycount[0].result_set.catering_orders, reservation_orders: nextProps.activitycount[0].result_set.reservation_orders });
					$('#dvLoading').fadeOut(2000);
				}
			}
	   } else{
			this.setState({ order_all: 0, overall_orders: 0,catering_orders: 0, deliveryTakeaway_orders: 0, reservation_orders: 0 });
	   }
   
     /*current orders */
	 if(nextProps.corderdetail !==this.props.corderdetail){
		   if(nextProps.corderdetail && nextProps.corderdetail[0].status == 'ok'){
		   		 $( "b" ).removeClass( "gloading_img" )
				 $('#dvLoading').fadeOut(2000);
                 this.setState({corderdetail: nextProps.corderdetail[0].result_set});
                 if(nextProps.corderdetail[0].common.total_records)
                 {
		                  this.setState({totalcount: nextProps.corderdetail[0].common.total_records});

				 }
                 if(this.state.showitems > nextProps.corderdetail[0].common.total_records){
					 $( ".load_more_data" ).hide();
				 } else {
					 $( ".load_more_data" ).show();
				 }
			 } else {
				 $( ".load_more_data" ).hide();
			 }
		 }
		 
	
	     if(nextProps.porderdetail !==this.props.porderdetail){
			 if(nextProps.porderdetail && nextProps.porderdetail[0].status == 'ok'){
		   		$( "b" ).removeClass( "gloading_img" )
                $('#dvLoading').fadeOut(2000);
                this.setState({porderdetail: nextProps.porderdetail[0].result_set});
                if(nextProps.porderdetail[0].common.total_records && (this.state.showpitems > nextProps.porderdetail[0].common.total_records)){
					 $( ".load_more_data1" ).hide();
				 } else {
					 $( ".load_more_data1" ).show();
				 }
			 } else {
				 $( ".load_more_data1" ).hide();
			 }
			}
			
      
       if(nextProps.orderhistory !==this.props.orderhistory){
       
        if (nextProps.orderhistory[0].status === "ok") {
				this.setState({ CartItems: nextProps.orderhistory[0].result_set[0]['items'], ReceiptDetails: nextProps.orderhistory[0].result_set[0] });

 					$('#dvLoading').fadeOut(5000);
					setTimeout(function () {
						$.magnificPopup.open({
							items: {
								src: '.receipt_popup'
							},
							type: 'inline'
						});
					}, 1000);

				} else {
					this.setState({ ReceiptDetails: [] });
        }
        
      }
      
      if(nextProps.printorder !==this.props.printorder){

       if (nextProps.printorder[0].status === "ok") {
        $('#dvLoading').fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, '_blank');
      }
     }
}

  getReceipt(orderId) {
    $('#dvLoading').fadeIn();
    var params = '&customer_id=' + cookie.load('UserId') + '&order_id=' + orderId;
    this.props.getOrderHistory(params);

	}

  printReceipt(orderId, availabilityId) {

	   $('#dvLoading').fadeIn();
       this.props.getPrintOrder(orderId, deliveryId); 
	}

	getOrderItemData = (dataProp) =>{
	  var dataProp = (dataProp!== undefined) ? dataProp : Array();
      if (Object.keys(dataProp).length > 0) { 
        return dataProp.map((item) =>{
			const orderDate = Moment(item.order_date).format('DD/MM/YYYY');
			const orderTime = Moment(item.order_date).format('h:mm A');

            return (<div key={item.order_id} className="current_order">
					   <div className="myacc_head_sec">
						  <div className="head_left">
							 <div className="head-group">
								<h4>ORDER NO - {item.order_local_no}</h4>
							 </div>
						  </div>
						  <div className="head_right">
							 <div className="head-group">
						   
								<h4>{item.status_name}</h4>
							 </div>
						  </div>
					   </div>

					   <div className="order_details_body">
						   <div className="order_no_deatails">
							 Order placed at :{Moment(item.order_created_on).format('DD/MM/YYYY')+" "+Moment(item.order_created_on).format('h:mm A')}
							 <span>Pay by : {item.order_method_name}</span>
							 
							  {(item.order_delivery_self_collection === '1')&& <span className="self-span">Self Collection</span> }
							 
						   </div>
						   {(item.order_availability_id === deliveryId) ? <div className="delivery_total delivery_total_text">
							  <div className="delivery_total_left">
								 <h4>Order Handled By</h4>
								 <p>{(item.outlet_name !== '' && item.outlet_name !== null)?stripslashes(item.outlet_name):''}</p>
								 <p>{addressFormat(item.outlet_unit_number1, item.outlet_unit_number2, item.outlet_address_line1, item.outlet_address_line2, item.outlet_postal_code)}</p>
							  </div>
							  <div className="delivery_total_left delivery_total_right">
								 <h4>Delivery Location</h4>
								 <p>{addressFormat(item.order_customer_unit_no1, item.order_customer_unit_no2, item.order_customer_address_line1, item.order_customer_address_line2, item.order_customer_postal_code)}</p>
							  </div>
						   </div> : <div className="delivery_total delivery_total_text pickup-order-div">
							  <div className="delivery_total_left">
								 <h4>Pickup Location</h4>
								 <p>{(item.outlet_name !== '' && item.outlet_name !== undefined)?stripslashes(item.outlet_name):''}</p>
								 <p>{addressFormat(item.outlet_unit_number1, item.outlet_unit_number2, item.outlet_address_line1, item.outlet_address_line2, item.outlet_postal_code)}</p>
							  </div>
						   </div>}
						   <div className="delivery_total delivery_total_number">
							  <div className="delivery_total_left">
								 <h5>{item.order_availability_id === deliveryId?"Delivery":"Pickup"} Date</h5>
								 <h3 className="checkoutDate">{orderDate}</h3>
							  </div>
							  <div className="delivery_total_left delivery_total_right">
								 <h5>{item.order_availability_id === deliveryId?"Delivery":"Pickup"} time</h5>
								 <h3 className="checkoutTime">{orderTime}</h3>
							  </div>
						   </div>
					   </div>
					   
					   
					   <div className="order_details_footer">
					   
					   <div className="order_amt">
							 <div className="order_amt-left">
								<h3>Order Amount</h3>
							 </div>
							 <div className="order_amt-right">
								<h3 className="text-right"><sup>$</sup>{item.order_total_amount}</h3>
							 </div>
					   </div>
					   

					  {/*onClick={this.printReceipt.bind(this, item.order_primary_id)} onClick={this.getReceipt.bind(this, item.order_id)}*/}

					   {(item.order_availability_id === pickupId && item.order_payment_mode === '1' && item.order_method_name.toLowerCase() === 'cash') ? <div className="order_btns">
					    <a href="javascript:void(0)" onClick={this.getReceipt.bind(this, item.order_id)}  className="button view_recipt button_full_view" title="">View Recipt</a>
					   </div> : <div className="order_btns">
						<a href="javascript:void(0)" onClick={this.printReceipt.bind(this, item.order_primary_id)}   className="button print_invoice" title="">Print Invoice</a>
					    <a href="javascript:void(0)" onClick={this.getReceipt.bind(this, item.order_id)}  className="button view_recipt" title="">View Recipt</a>
					   </div>}
					   
					</div>	
					   
					</div>);
			})
      } else
		{
                       
			  return (<div className="no-recrds-found">No records found</div>);
			  
		}
    }

    render() {

		var settingsMyaccNav = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 2,
			slidesToScroll: 1,
			initialSlide: 0,
			responsive: [
			  {
				breakpoint: 1024,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 1,
				  infinite: true,
				  dots: false
				}
			  },
			  {
				breakpoint: 700,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 1,
				  initialSlide: 1
				}
			  },
			  {
				breakpoint: 520,
				settings: {
				  slidesToShow: 1,
				  slidesToScroll: 1,
				  initialSlide: 1
				}
			  }
			]
		  };
		
		
        return (<div className="home-wrapper inner-page-wrapper">

				{/*-------Header---------*/}	
				<Header />

				<div className="myaccount-wrapper">		
				<div className="myaccount-container">
					<div className="myaccount-sec">
							<div className="nav-sec">
							<ul className="navlist">
								<li><Link to="/myaccount" title="My Account">Account Information</Link></li>
								<li className="active"><Link to="/myorders" title="My Orders">Orders {(parseFloat(this.state.overall_orders)>0) && <span className="count" id="masterCount">{this.state.overall_orders}</span>}</Link></li>
								<li><Link to="/rewards" title="My Rewards">Rewards</Link></li>
								<li><Link to="/mypromotions" title="My Promotions">Promotions </Link></li>
								<li><Link to="/myvoucher" title="My Vouchers">Vouchers </Link></li>
							</ul>

							<div className="mobi-product-menu-list myacc-menu-list">
							<Slider {...settingsMyaccNav}>                  
							<div className="menu-slide">
							<Link to="/myaccount" title="My Account">Account Information</Link>
							</div>												
							<div className="menu-slide active">
							<Link to="/myorders" title="My Orders">Orders {(parseFloat(this.state.overall_orders)>0) && <span className="count" id="masterCount">{this.state.overall_orders}</span>}			</Link>							
							</div>												
							<div className="menu-slide">	
							<Link to="/rewards" title="My Rewards">Rewards</Link>								
							</div>												
							<div className="menu-slide">
							<Link to="/mypromotions" title="My Promotions">Promotions </Link>									
							</div>		
							<div className="menu-slide">
							<Link to="/myvoucher" title="My Vouchers">Vouchers </Link>									
							</div>
							</Slider>
						</div>
						</div>

							<div className="mainacc_menucontent">
									<div className="main_tabsec">
									<div className="order-tab-section">
										<div className="mainacc_mobrow">
											
											<div className="tab_sec main_tabsec_inner">
											
											{/*} <ul className="web_account_menu_cls orc_list orderType web_order_typecls"> 
												<li className="active orderTypeli" data-tab="orc-tab1"> 
													<a className="orderNotifi" href="#ordertab1"> 
													<i className="sprite_lst_icon order_t">
														<img src={twoWheeler} />
													</i> 
													<h5>Delivery / Takeaway </h5>
													{(parseFloat(this.state.deliveryTakeaway_orders)>0) && <span className="count_num_t " id="orderNotifi">{this.state.deliveryTakeaway_orders}</span>}
													</a> 
												</li> 
												<li data-tab="orc-tab3" className="orderTypeli getCateringCls"> 
													<a className="cateringorderNotifi" href="#ordertab2">  <i className="sprite_lst_icon catering_icon_t "><img src={categoringImg} /></i> 
													<h5>Catering</h5>
													{(parseFloat(this.state.catering_orders)>0) && <span className="count_num_t " id="cateringorderNotifiMbl">{this.state.catering_orders}</span>}
													</a> 
												</li>
												<li data-tab="orc-tab2" className="orderTypeli getReservationCls"> 
													<a className="ReservationorderNotifi" href="#ordertab3"> <i className="sprite_lst_icon reserve_icon_t ">
													<img src={reservIcon} /></i> 
													<h5>Reservation</h5> 
													{(parseFloat(this.state.reservation_orders)>0) && <span className="count_num_t " id="ReservationorderNotifi">{this.state.reservation_orders}</span>}
													</a>
												</li>
											</ul>
											{*/}
											
											
											
												<div className="myacc_filter">
												<div className="tab_sec filter_tabsec" id="ordertab1">
													<ul className="nav nav-tabs text-center">
														<li className="active"><a data-toggle="tab" href="#tab-id-inn5" aria-expanded="true"><span>Current Orders</span></a></li>
														<li className=""><a data-toggle="tab" href="#tab-id-inn6" aria-expanded="false"><span>Past Orders</span></a></li>
													</ul>
													<div className="tab-content">
														<div id="tab-id-inn5" className="tab-pane fade active in">
															<h4 className="tab_mobtrigger inner_tab_border active">Current orders<i></i></h4>
															<div className="tab_mobrow filter_tabin">
												<div className="order-delivery">
													<div className="ord-body">
															
													<div className="cur-order-body">
														<div className="myacc_order_details"> 
														{this.getOrderItemData(this.state.corderdetail)}      
														</div>
														</div>
													</div>
												</div>
												</div>
												<div className="load_more_div"><button className="load_more_data" onClick={this.loadcurrentItems.bind(this)} style={{display:'none'}}>Load More</button></div>
														</div>
														<div id="tab-id-inn6" className="tab-pane fade">
															<h4 className="tab_mobtrigger inner_tab_border">Past Orders<i></i></h4>
															<div className="tab_mobrow filter_tabin">
														<div className="pst-order-body">
															<div className="myacc_order_details">
																{this.getOrderItemData(this.state.porderdetail)}
																</div>	
															</div>	
															</div>
																<div className="load_more_div"><button className="load_more_data1" style={{display:'none'}} onClick={this.loadpastItems.bind(this)}>Load More</button></div>
														</div>
													</div>
												</div>
												{/* next tab */}
												</div>

											</div>
										</div>
									</div>
									</div>
								</div>

					</div>
				</div>
				</div>

			{/*------Footer--------*/}
			<Footer />

			<div id="receipt-popup" className="white-popup mfp-hide popup_sec receipt_popup catering_receipt_popup">
					  <div className="pouup_in">
						   <Viewreceipt details={this.state.ReceiptDetails} cartItems={this.state.CartItems} />
                        </div>
				    </div>

			 <div id="dvLoading"></div>
		 </div>);
    }
}

const mapStateToProps = (state) => {

  return {
    corderdetail: state.corderdetail,
    porderdetail: state.porderdetail,
    printorder: state.printorder,
    orderhistory: state.orderhistory,
	activitycount: state.activitycount,
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    getCorderDetail: (deliveryparams) => {
      dispatch({ type: GET_CORDERDETAIL, deliveryparams});
    },
    getPorderDetail: (deliverypastparams) => {
      dispatch({ type: GET_PORDERDETAIL, deliverypastparams});
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId});
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params});
    },
    getActivityCount: (getObject) => {
		dispatch({ type: GET_ACTIVITYCOUNT, getObject});
	},
  }
}


Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));


class Viewreceipt extends Component {
	constructor(props) {
		super(props);
	}
	
	getCartDetList(cartItems) {
			if (cartItems.length > 0) {
			return cartItems.map((item, index) =>
			<div className="cart_row oreder_itm_row" key={index}>
			<div className="row oreder-row-inv">
			  <div className="col-xs-7 cart_left">
				 <div className="cart_img">
					<a href="javascript:void(0)" title="">
					<img src={(item.item_image !== '')?item.item_image:productImg} alt="" />
					</a>
				 </div>
				 <div className="cart_info">
					<h4>{stripslashes(item.item_name)} X {item.item_qty}</h4>
					{this.loadModifierItems("Component", item.modifiers, item.set_menu_component)}
				 
				 {item.item_specification !== '' && 
				 <p className="help-block">{stripslashes(item.item_specification)}</p>
				 }
				 {/*} 
				 <p className ="help-block">Special instruction for individual product items ...</p>
				 {*/}
				 </div>
			  </div>
			  <div className="col-xs-5 cart_right text-right">
				 <div className="cart_price">
					<p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
				 </div>
			  </div>
			</div>
			</div>
			);
		 }
        }
		  
		/* this function used to load modifer items */
		loadModifierItems(itemType, modifiers, combo) {
					var len = modifiers.length;
					var comboLen = combo.length;
					var html = '<div class="cart_extrainfo">';
					   if (len > 0) {
					   for (var i = 0, length = len; i < length; i++) {
						   var modName = modifiers[i]['order_modifier_name'];
						   var modval = modifiers[i]['modifiers_values'][0]['order_modifier_name'];
						   html += "<p><b>" + stripslashes(
							  modName) + ":</b></p><p> " + stripslashes(modval) + "</p>";
					   }
						html += "</div>";
						var reactElement = Parser(html);
						return reactElement;
					} else if (comboLen > 0) {
						for (var i = 0, length = comboLen; i < length; i++) {
						var comboName = combo[i]['menu_component_name'];
						var comboVal = this.showComboProducts(combo[i]['product_details']);
						html += "<p><b>" + comboName + ":</b></p><p> " + comboVal + " " + this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) + "</p>";
						}
						html += "</div>";
						var reactElement = Parser(html);
						return reactElement;
					}
		}
		
		/* show combo products  list */
		showComboProducts(combos) {
			var lenCombo = combos.length;
			var html = " ";
			if (lenCombo > 0) {
			for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
			var comboPro = combos[r]['menu_product_name'];
			var comboQty = combos[r]['menu_product_qty'];
			var comboPrice = combos[r]['menu_product_price'];
			var newPrice = (comboPrice > 0) ? " (+ $" + comboPrice + ")" : "";
			html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p>";
			}
			return html;
			}
			return "";
		}
		/* this function used to show combo modifieirs list */
		showComboModifiers(modifiers) {
			var lenMod = modifiers.length;
			var html = '';
			if (lenMod > 0) {
			html = '<div > ';
			   for (var i = 0, length = lenMod; i < length; i++) {
			   var modName = modifiers[i]['order_modifier_name'];
			   var modval = modifiers[i]['modifiers_values'][0]['order_modifier_name'];
			   var modValPrice = modifiers[i]['modifiers_values'][0]['order_modifier_price'];
			   var newModValPrice = (modValPrice > 0) ? " (+ $" + modValPrice + ")" : "";
			   html += "<p><b>" + modName + ":</b></p><p> " + modval + newModValPrice + "</p>";
			   }
			   html += '</div>';
			}
			return html;

		}
		componentWillReceiveProps(nextProps){
		}

	
	render() {
const { details, cartItems } = this.props;
if(details!== undefined){
return (
<div className="Viewreceipt" >
   <div className="tnk-you">
      <div className="tnk-header">
         <div className="tnk-detail">
            <h2>YOUR ORDER DETAILS</h2>
            <div className="tnk-order">
               <h3>Order No - {details.order_local_no}</h3>
               <p>
               Order placed at :  {Moment(details.order_created_on).format('DD-MM-YYYY hh:mm A')}
               <br></br>
               Pay by : {details.order_method_name}  
               {(details.order_delivery_self_collection === '1')&& <><br></br><span className="self-span">Self Collection</span></> }
               </p>
            </div>
         </div>
      </div>
      <div className="tnk-delivery">
         {(details.order_availability_id === deliveryId) ? <div className="delivery_total">
            <div className="delivery_total_left">
               <h4>Delivery From</h4>
               <p>{(details.outlet_name !== '' && details.outlet_name !== undefined)?stripslashes(details.outlet_name):''}</p><p>{addressFormat(details.outlet_unit_number1, details.outlet_unit_number2, details.outlet_address_line1, details.outlet_address_line2, details.outlet_postal_code)}</p> 
            </div>
            <div className="delivery_total_left delivery_total_right">
               <h4>Deliver TO</h4>
               <p>{details.order_customer_address_line1}</p> <p>Singapore {details.order_customer_postal_code}</p> <p>{(details.order_customer_unit_no1 != '' && details.order_customer_unit_no2 != '') ? (details.order_customer_unit_no1 + '-' + details.order_customer_unit_no2) : ''}</p>
            </div>
         </div> : <div className="delivery_total pickup-order-div">
            <div className="delivery_total_left">
               <h4>Pickup Location</h4>
               <p>{(details.outlet_name !== '' && details.outlet_name !== undefined)?stripslashes(details.outlet_name):''}</p><p>{addressFormat(details.outlet_unit_number1, details.outlet_unit_number2, details.outlet_address_line1, details.outlet_address_line2, details.outlet_postal_code)}</p> 
            </div>
         </div>}
         <div className="delivery_total delivery_total_number">
            <div className="delivery_total_left">
               <h5>{details.order_availability_id === deliveryId?"Delivery":"Pickup"} Date</h5>
               <h3 className="checkoutDate">{Moment(details.order_date).format('DD-MM-YYYY')}</h3>
            </div>
            <div className="delivery_total_left delivery_total_right">
               <h5>{details.order_availability_id === deliveryId?"Delivery":"Pickup"} time</h5>
               <h3 className="checkoutTime">{Moment(details.order_date).format('hh:mm A')}</h3>
            </div>
         </div>
		 
		 <div className="hcart_tt horder_tt">
			<div className="row-replace">
				<div className="col-sm-cls text-left"><h3>Order Items</h3></div>
			</div>
		 </div>
		 
		 <div className="order-items-maindiv">
			{this.getCartDetList(cartItems)}
		 </div>
		 
         <div className="cart_footer tnk_cart_footer">
            <div className="cart_row">
               <div className="row">
                  <div className="col-xs-7">
                     <p className="text-uppercase">SUBTOTAL</p>
                  </div>
                  <div className="col-xs-5 text-right">
                     <span>${details.order_sub_total}</span>
                  </div>
               </div>
            </div>
            {parseFloat(details.order_discount_amount) > 0 &&
            <div className="cart_row">
               <div className="row">
                  <div className="col-xs-7">
                     <p className="text-uppercase">Discount (-)</p>
                  </div>
                  <div className="col-xs-5 text-right">
                     <span>${(parseFloat(details.order_discount_amount)).toFixed(2)}</span>
                  </div>
               </div>
            </div>
            }
            {(parseFloat(details.order_delivery_charge) > 0|| parseFloat(details.order_additional_delivery) > 0)  &&
            <div className="cart_row">
               <div className="row">
                  <div className="col-xs-7">
                     <p className="text-uppercase">Delivery Charges</p>
                  </div>
                  <div className="col-xs-5 text-right">
                     <span>${(parseFloat(details.order_delivery_charge) + parseFloat(details.order_additional_delivery)).toFixed(2)}</span>
                  </div>
               </div>
            </div>
            }
            {details.order_tax_calculate_amount > 0 && 
            <div className="cart_row">
               <div className="row">
                  <div className="col-xs-7">
                     <p className="text-uppercase">GST ({parseFloat(details.order_tax_charge)}%)</p>
                  </div>
                  <div className="col-xs-5 text-right">
                     <span>${details.order_tax_calculate_amount}</span>
                  </div>
               </div>
            </div>
            }
            <div className="cart_row cart_footer_totrow grant-total-cls">
               <div className="row">
                  <div className="col-xs-7">
                     <p className="text-uppercase">Total</p>
                  </div>
                  <div className="col-xs-5 text-right">
                     <span>${details.order_total_amount}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
);
}else{
return (
<div ></div>
);
}
}

}
