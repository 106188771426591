/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import moment from 'moment';
//import moment from 'moment-timezone';


import { getReferenceID, stripslashes, getCalculatedAmount, callImage, showLoader, hideLoader, getOrderDateTime, getPromoCkValue, removeOrderDateTime, removePromoCkValue, showAlert } from "../Helpers/SettingHelper";
import { appId, apiUrl, deliveryId, CountryTxt } from "../Helpers/Config";

import { GET_CART_DETAIL, UPDATE_CART_DETAIL, DELETE_CART_DETAIL, DESTROY_CART_DETAIL } from '../../actions';

var Parser = require('html-react-parser');

import cart from "../../common/images/cart.png";
import productImg from "../../common/images/noimg-400x400.jpg";

class CartSideBar extends Component {

  constructor(props) {
    super(props);
    this.state = {cartlistdetail:[], overAllcart: [], cartItems: [], cartDetails: [], cartStatus: '', settings: [], cartTotalItmCount: 0, cartTotalAmount: 0, cateringCartItems: [], cateringCartDetails: [], cateringCartTotalItmCount: 0,  updateCartResponse: [], startMsg: 0,
	};

	}

	cartSidebar(){
		this.setState(prevState => ({cartSidebar: !prevState.cartSidebar}));		
	}

	componentWillMount() {

		var avltyTxt = cookie.load("defaultAvilablityId");
		this.props.getCartDetail();

		setTimeout(function(){ if ($(".mCustomScrollbar").length > 0) {
			$(".mCustomScrollbar").mCustomScrollbar();
			} }, 800);

	}

	componentWillReceiveProps(headerProps) {

		if(headerProps.cartTriggerFlg === 'yes') {
			headerProps.prpSateValChange('cartflg', 'no');
			this.props.getCartDetail();
		}
		hideLoader('product-details','class');
		hideLoader('cart_body','class');


      if (this.state.updateCartResponse !== headerProps.updateCartResponse) {
      if (Object.keys(headerProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: headerProps.updateCartResponse },
          function () {
            var Response = headerProps.updateCartResponse;
            
            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {

                  this.handleShowAlertFunct("Error", Response[0].message);
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }
   }


  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }


	componentDidMount() {
		
		setTimeout(function(){ if ($(".cartScrollBar").length > 0) {
			if($(window).width() < 991){
				$(".cartScrollBar").mCustomScrollbar("disable",true);
			}
			else{
				$(".cartScrollBar").mCustomScrollbar();
			}
		} }, 800);
	
	}
	
	removePromoFun() {
		removePromoCkValue();
		this.props.getCartDetail();
	}

		
	cartDetailsList() {
		
		var cartItemsArr = this.props.cartItems;
		
		if (Object.keys(cartItemsArr).length > 0) {
		
		var cartDetailsArr = this.props.cartDetails;
		var promoTionArr = getPromoCkValue();
		
		var globalSettings = Array();
	    if(Object.keys(this.props.globalsettings).length > 0) {
		  if(this.props.globalsettings[0].status === 'ok') {
			  globalSettings = this.props.globalsettings[0].result_set;
		  }
	    }

		var zoneDetails = this.props.zonedetails;
	    var calculatedAmount = getCalculatedAmount(globalSettings,zoneDetails,cartDetailsArr,promoTionArr);
		
		var orderDateTime = (typeof cookie.load('orderDateTime') === 'undefined') ? '' : cookie.load('orderDateTime');

		//orderDateTime = moment(orderDateTime).tz('Asia/Singapore').format();
		//console.log();

		var orderTAT = (typeof cookie.load('orderTAT') === 'undefined') ? '' : cookie.load('orderTAT');

		//console.log(orderDateTime,orderTAT,'orderDateTime,orderTAT,cart-sidebae');

		var orderDateTmTxt = getOrderDateTime(orderDateTime,orderTAT);
		//console.log(orderDateTmTxt,'orderDateTmTxt-cart-sidebae');
		var cartMinAmount =  0;

		if(zoneDetails != undefined && zoneDetails.length > 0) {
			 cartMinAmount = (zoneDetails[0].zone_min_amount !== '') ? parseFloat(zoneDetails[0].zone_min_amount) : 0;
		  }

		var cartSubTotal = parseFloat(cartDetailsArr.cart_sub_total);
		
		return (
			<div className={(this.state.cartSidebar===true)?'cart-sidebar active':'cart-sidebar'}>
			<div className="cartlist-wrapper">
					
					<div className="cart-header">
						<div className="ctitle">
							<h4>Your Order Details</h4>
											
							<a href="javascript:void(0)" onClick={this.cartSidebar.bind(this)} className='remove' title="remove"></a>
						</div>
					</div>

					<div className="cart_scrollarea cartScrollBar">
							<div className="cart_row cart-header-first">
								<div className="row-replace">
								<div className="col-sm-cls cart_left text-left">
									<h4>Order Handled By</h4>
									<p>{cookie.load('orderOutletName')}</p>
									<p>{cookie.load('orderHandledByText')}</p>
								</div>
								<div className="col-sm-cls cart_right text-right">
									<h4>Delivery Location</h4>
									<p>{cookie.load('orderDeliveryAddress')}</p>   
									<p>{CountryTxt} {cookie.load('orderPostalCode')}</p>   
								</div>
								</div>
							</div>

							<div className="cart_row cart-header-second">
								<div className="row-replace">
									<div className="col-sm-cls text-left">
										<h5>{(cookie.load('defaultAvilablityId')===deliveryId)?"Delivery":"Pickup"} Date</h5>
										<h3>{moment(orderDateTmTxt).format("DD/MM/YYYY")}</h3>
									</div>
									<div className="col-sm-cls text-right">
										<h5>{(cookie.load('defaultAvilablityId')===deliveryId)?"Delivery":"Pickup"} Time</h5>
										<h3>{moment(orderDateTmTxt).format("hh:mm A")}</h3>    
									</div>
								</div>
							</div>
								
							<div className="hcart_tt">
								<div className="row-replace">
									<div className="col-sm-cls text-left">
										<h3>Your Items</h3>
									</div>
									<div className="col-sm-cls text-right">
										<a href="javascript:void(0)" onClick={this.clearCartItm.bind(this)} className="hclear_cart" title="CLEAR CART">CLEAR CART</a>
									</div>
								</div>
							</div>
							<div className="cart_body pdt-cart-list">	
								{this.cartItemList()}
							</div>
								
							
							
							<div className="cart_footer">
								<div className="cart_row">
									<div className="row-replace">
										<div className="col-sm-cls text-left">
											<p className="text-uppercase">SUBTOTAL</p>
										</div>
										<div className="col-sm-cls text-right">
											<span>${calculatedAmount['cartSubTotalAmount']}</span>
										</div>
									</div>
								</div>
								{(parseFloat(calculatedAmount['deliveryCharge'])>0) &&  <div className="cart_row">
									<div className="row-replace">
										<div className="col-sm-cls text-left">
											<p className="text-uppercase">Delivery</p>
										</div>
										<div className="col-sm-cls text-right">
											<span>${parseFloat(calculatedAmount['deliveryCharge']).toFixed(2)}</span>
										</div>
									</div>
								</div>}
								{(parseFloat(calculatedAmount['additionalDelivery'])>0) &&  <div className="cart_row">
									<div className="row-replace">
										<div className="col-sm-cls text-left">
											<p className="text-uppercase">Additional Delivery</p>
										</div>
										<div className="col-sm-cls text-right">
											<span>${parseFloat(calculatedAmount['additionalDelivery']).toFixed(2)}</span>
										</div>
									</div>
								</div>}
								{(parseFloat(calculatedAmount['promotionAmount'])>0) && <div className="cart_row">
									<div className="row-replace">
										<div className="col-sm-cls text-left">
											<p className="text-uppercase">Discount</p>
										</div>
										<div className="col-sm-cls text-right">
											<span>${parseFloat(calculatedAmount['promotionAmount']).toFixed(2)}</span>
										</div>
									</div>
									<a href="javascript:void(0)" onClick={this.removePromoFun.bind(this)} className="cart_remove"></a>
								</div>}
								{(parseFloat(calculatedAmount['orderGstAmount'])>0) && <div className="cart_row">
									<div className="row-replace">
										<div className="col-sm-cls text-left">
											<p className="text-uppercase">GST ({calculatedAmount['orderDisplayGst']}%)</p>
										</div>
										<div className="col-sm-cls text-right">
											<span>${parseFloat(calculatedAmount['orderGstAmount']).toFixed(2)}</span>
										</div>
									</div>
								</div>}
								
								 {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
								  <div className="cart_row">
									<div className="row-replace">
									  <div className="col-sm-cls text-left">
										<p className="text-uppercase">
										  Voucher Disocunt Amount
										</p>
									  </div>
									  <div className="col-sm-cls text-right">
										<span>
										  $
										  {parseFloat(
											calculatedAmount["voucherDiscountAmount"]
										  ).toFixed(2)}
										</span>
									  </div>
									</div>
								  </div>
								)}
								
								<div className="cart_row grant-total-cls">
									<div className="row-replace">
										<div className="col-sm-cls text-left">
											<p className="text-uppercase">Total</p>
										</div>
										<div className="col-sm-cls text-right">
											<span><sup>$</sup>{calculatedAmount['grandTotalAmount']}</span>
										</div>
									</div>
								</div>

							   {(cookie.load("defaultAvilablityId") === deliveryId) && this.loadDeliveryPercentage()}

							   {parseFloat(cartDetailsArr.cart_special_discount) > 0 && 

								<div className="member-discount-total">* {cartDetailsArr.cart_special_discount_type} ${cartDetailsArr.cart_special_discount} Applied</div>

								}

						</div>
					<div className="chk-order">
						<Link to={"/checkout"} className="btn btn_yelow cartaction_checkout" title="Checkout Now">Checkout</Link>
					</div>						
				</div>
			</div>
			</div>);
		} else {
			return '';
		}
	}

	/* this  function used to load delivery percentage */
    loadDeliveryPercentage() {

		var freeDeliveryAmnt = 0;
		var DeliveryAmnt = 0;
		var remainAmnt = 0;
		var delPercentage = 0;
		
		var cartMinAmount =  0;
		
		let barhtml='';

		var cartItemsArr = this.props.cartItems;

		if (Object.keys(cartItemsArr).length > 0) {
		
			var cartDetailsArr = this.props.cartDetails;
			var promoTionArr = getPromoCkValue();
			
			var globalSettings = Array();
			if(Object.keys(this.props.globalsettings).length > 0) {
			  if(this.props.globalsettings[0].status === 'ok') {
				  globalSettings = this.props.globalsettings[0].result_set;
			  }
			}

			var zoneDetails = this.props.zonedetails;
			var calculatedAmount = getCalculatedAmount(globalSettings,zoneDetails,cartDetailsArr,promoTionArr);
			
				freeDeliveryAmnt = parseFloat(calculatedAmount['freeDeliveryAmnt']);
				DeliveryAmnt = parseFloat(calculatedAmount['deliveryCharge']);
			var subTotal = parseFloat(calculatedAmount['cartSubTotalAmount']);
			var percentage = (subTotal * 100) / freeDeliveryAmnt;
				percentage = Math.round(percentage);
				delPercentage = percentage;
				remainAmnt = parseFloat((freeDeliveryAmnt - subTotal)).toFixed(2);

			if(zoneDetails != undefined && zoneDetails.length > 0) {
				 cartMinAmount = (zoneDetails[0].zone_min_amount !== '') ? parseFloat(zoneDetails[0].zone_min_amount) : 0;
			}

		}
		
	
		if((cookie.load('defaultAvilablityId')===deliveryId) && (subTotal < cartMinAmount)) {

			let mindelPercentage = Math.round(((subTotal * 100) / cartMinAmount));

		  barhtml =
		`  <div class="cart_row min-cart">
		  <div class="indication">
		  <p class="help-block">${(cartMinAmount-subTotal).toFixed(2)} more to min order amount</p>
			  <div class="indication_progress">
				  <span class="progress_bar Progress_bar_min" style="width:${mindelPercentage}%" ><span class="progress_indi"></span></span>
			  </div>
		  </div>
	  </div>`
		 ;
		}

		if(DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {

		  barhtml +=
		 ` <div class="cart_row min-delivery">
		  <div class="indication">
		  <p class="help-block">${remainAmnt} more to free delivery</p>
			  <div class="indication_progress">
				  <span class="progress_bar" style="width:${delPercentage}%" ><span class="progress_indi"></span></span>
			  </div>
		  </div>
	  </div>`;
		  
		}

		return Parser(barhtml);
	
    }

	cartItemList() {
		var cartItemsArr = this.props.cartItems;
		if (Object.keys(cartItemsArr).length > 0) {
			return cartItemsArr.map((product, index) =>
						<div className="cart_row product-details" id={'rowcartid-'+product.cart_item_id} key={index}>
							<div className="row">
								<div className="col-xs-8 cart_left">
									<div className="cart_img">
										<a href="javascript:void(0)" title="">
										{(product.cart_item_product_image !== '')?<img src={product.cart_item_product_image} width="110" height="67" alt="" />:<img src={productImg} width="110" height="67" alt="" />}
										</a>
									</div>
									<div className="cart_info">
										<h4>{stripslashes(product.cart_item_product_name)}</h4>


										 <h4>
						                  {product.cart_item_voucher_id !== "" &&
						                  product.cart_item_voucher_id != null
						                    ? "Discount Applied"
						                    : ""}
						                </h4>

										{this.loadModifierItems(product.cart_item_type, product.modifiers, product.set_menu_component)}
										
										{product.cart_item_special_notes !== '' && <p className="help-block">{stripslashes(product.cart_item_special_notes)}</p>}


										{parseFloat(product.cart_item_promotion_discount) > 0 &&

										<span className="member-discount-desc">$ {product.cart_item_promotion_discount} {product.cart_item_promotion_type} discount Applied</span>
										}
									</div>
								</div>
								<div className="col-xs-4 cart_right text-right">

								<div className="cart_price"><p>${product.cart_item_total_price}</p></div>

									 {(product.cart_item_voucher_product_free != 1 ) ?
									<div className="qty_bx">
										<span className="qty_minus" onClick={this.updateCartQty.bind(this, product, 'decr')}>-</span>
										<input type="text" value={product.cart_item_qty} readOnly />
										<span className="qty_plus" onClick={this.updateCartQty.bind(this, product, 'incr')}>+</span>
									</div>:

									  <div className="qty_bx free_product">
										<span className="qty_minus"></span>
										<input type="text" value={product.cart_item_qty} readOnly />
										<span className="qty_plus"></span>
									  </div>

									  }
											
								</div>
							</div>
							<a href="javascript:void(0)" onClick={this.deleteCartItm.bind(this, product)} className="cart_remove"></a>
						</div>);
		}
	}
	
	 /* this function used to load modifer items */
	 loadModifierItems(itemType, modifiers, combo) {
	 	
		var len = modifiers.length;
		var comboLen = combo.length;
		var html = '<div class="cart_extrainfo">';

		var temp_html = '';

		if (itemType === "Modifier" && len > 0) {
		  for (var i = 0, length = len; i < length; i++) {
			var modName = modifiers[i]['cart_modifier_name'];
			var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name'];
			var modVlPrice = modifiers[i]['modifiers_values'][0]['cart_modifier_price'];
			var newModVlPrice = (modVlPrice > 0) ? " (+" + modVlPrice + ")" : "";
			temp_html += "<p><b>" + modName + ":</b></p> <p>" + modval + newModVlPrice + "</b></p> ";
		  }
		  
		  html += temp_html+"</div>";
		  var reactElement = Parser(html);
		  return reactElement;

		} else if (itemType === "Component" && comboLen > 0) {
		  for (var i = 0, length = comboLen; i < length; i++) {
			
			var comboVal = this.showComboProducts(combo[i]['product_details']);
			var comboName = "";
			if (combo[i]['product_details'][0]['modifiers'].length > 0) {

				var comboName = combo[i]['menu_component_name'];

			  html += "<p><b>" + comboName + ":</b> </p><p>" + comboVal + "  " + this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) + "</p> ";

			} else {

				if(comboName !==""){
				
				 html += "<p><b>" + comboName + ":</b> </p><p>" + comboVal + " " + this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) + "</p> ";
	
				}else{

				 html += "<p>" + comboVal + " " + this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) + "</p> ";

				}
			}
		  }
		  html += "</div>";
		  var reactElement = Parser(html);
		  return reactElement;

		}
	}


	/* show combo products  list */
	showComboProducts(combos) {
		var lenCombo = combos.length;
		var html = " ";
		if (lenCombo > 0) {
		  for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
		   if(parseInt(combos[r]['cart_menu_component_product_qty']) > 0) {	  
			var comboPro = combos[r]['cart_menu_component_product_name'];
			var comboQty = combos[r]['cart_menu_component_product_qty'];
			var comboPrice = combos[r]['cart_menu_component_product_price'];
			var newPrice = (comboPrice > 0) ? " (+" + comboPrice + ")" : "";
			html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
		   }
		  }
		  return html;
		}
		return "";
	}

	/* this function used to show combo modifieirs list */
	showComboModifiers(modifiers) {
		var lenMod = modifiers.length;
		var html = '<div >';
		if (lenMod > 0) {
		  for (var i = 0, length = lenMod; i < length; i++) {
			var modName = modifiers[i]['cart_modifier_name'];
			var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name'];
			var modValPrice = modifiers[i]['modifiers_values'][0]['cart_modifier_price'];
			var newModValPrice = (modValPrice > 0) ? " (+" + modValPrice + ")" : "";
			html += "<p><b>" + modName + ":</b> </p><p> " + modval + newModValPrice + "</p> ";
		  }
		  html += '</div>';

		  return html;

		}

		return "";
	}
	
	updateCartQty(itemArr, type) {
		
		var productId = itemArr.cart_item_product_id;
		var cartItemId = itemArr.cart_item_id;
		var cartQty = itemArr.cart_item_qty;
		 var orderVoucherId = itemArr.cart_voucher_order_item_id;
		var totalItmCount = this.props.cartTotalItmCount;
		
		showLoader('rowcartid-'+cartItemId,'Idtext');
		
		if(type === 'decr') {
			cartQty = parseInt(cartQty) - 1;
			if(parseInt(totalItmCount) === 0) {
				
			} else if(parseInt(cartQty) === 0) {
				this.props.deleteCartDetail(cartItemId);
			} else {
				this.props.updateCartDetail(productId,cartItemId,cartQty, orderVoucherId);
			}
		} else {
			cartQty = parseInt(cartQty) + 1;
			this.props.updateCartDetail(productId,cartItemId,cartQty, orderVoucherId);
		}
		this.setState({ startMsg: 1 });
		removePromoCkValue();
	}
	
	deleteCartItm(itemArr) {
		var cartItemId = itemArr.cart_item_id;
		showLoader('rowcartid-'+cartItemId,'Idtext');
		this.props.deleteCartDetail(cartItemId);
		removePromoCkValue();
	}
	
	clearCartItm() {
		showLoader('cart_body','class');
		this.props.destroyCartDetail();
		removePromoCkValue();
	}
	

    render() {

		setTimeout(function(){ if ($(".mCustomScrollbar").length > 0) {
			$(".mCustomScrollbar").mCustomScrollbar();
			} }, 600);

		let currenturl = window.location.href;
        let isCheckout = "checkout";
		
		var avltyTxt = cookie.load("defaultAvilablityId");

        return (
		<>
		
		{currenturl.includes(isCheckout) && <a href="javascript:void(0)" className={(this.state.cartSidebar===true)?'hcartdd_trigger cart active':'hcartdd_trigger cart'} title="cart"><img src={cart} alt="cart" />
			<span className="count">{this.props.cartTotalItmCount}</span>
		</a>}
		
		{!currenturl.includes(isCheckout) && <a href="javascript:void(0)" onClick={this.cartSidebar.bind(this)} className={(this.state.cartSidebar===true)?'hcartdd_trigger cart active':'hcartdd_trigger cart'} title="cart"><img src={cart} alt="cart" />
			<span className="count">{this.props.cartTotalItmCount}</span>
		</a>}
		
		
		{this.cartDetailsList()}	
		</>
		);

    }
}

const mapStateTopProps = (state) => {
	var overAllcart = Array();
	var cartDetails = Array();
	var cartItems = Array();
	var cartTotalItmCount = 0;
	var cartStatus = '';
	var updateCartResponse = Array();

	if(Object.keys(state.cartlistdetail).length > 0) {
		var resultSetArr = (!('result_set' in state.cartlistdetail[0])) ? Array() : state.cartlistdetail[0].result_set;
		if(state.cartlistdetail[0].status === 'ok' && Object.keys(resultSetArr).length > 0) {
		  overAllcart = resultSetArr;  
		  cartDetails = resultSetArr.cart_details;
		  cartItems = resultSetArr.cart_items;
		  cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
		  cartStatus = 'success';
		} else {
		  cartStatus = 'failure';
		}
	}

  
	  if (Object.keys(state.updatecartdetail).length > 0) {
	    if (state.updatecartdetail[0].status === "error") {
	      updateCartResponse = state.updatecartdetail;
	    }
	  }
	
	return {
	  overAllcart: overAllcart,
	  cartDetails: cartDetails,
	  cartItems: cartItems,
	  cartTotalItmCount: cartTotalItmCount,
	  cartStatus: cartStatus,
	  updateCartResponse: updateCartResponse,
	}
  }
  
  const mapDispatchToProps = (dispatch) => {
	return {
	  getCartDetail: () => {
		dispatch({ type: GET_CART_DETAIL });
	  },
	  updateCartDetail: (productId,cartItemId,cartQty, orderVoucherId) => {
		dispatch({ type: UPDATE_CART_DETAIL, productId, cartItemId, cartQty, orderVoucherId });
	  },
	  deleteCartDetail: (cartItemId) => {
		dispatch({ type: DELETE_CART_DETAIL, cartItemId });
	  },
	  destroyCartDetail: () => {
		dispatch({ type: DESTROY_CART_DETAIL });
	  },
	}
  }
  export default connect(mapStateTopProps, mapDispatchToProps)(CartSideBar);
