import cookie from 'react-cookies';

 /*LIVE

export const appId        = "8FA119AF-B812-4374-9773-0EF044516321";
export const apiUrl       = "https://ccpl.ninjaos.com/api/v1.1/";
export const apiUrlV2     = "https://ccpl.ninjaos.com/api/v1.2/";
export const apiUrlPro    = "https://ccpl.ninjaos.com/api/v1.3/";
export const baseUrl      = "https://chixhotchicken.com/";
export const timThumpUrl  = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/"; */

export const appId        = "66C7D67F-4700-40CB-91A4-A47C67D38E33";
export const apiUrl       = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2     = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro    = "https://ccpl.ninjaos.com/apiv3/";
//export const baseUrl      = "https://staging.bbburgers.sg/";
export const baseUrl      = "https://www.bbburgers.sg/";
export const timThumpUrl  = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";

/*DEV
export const appId        = "D04E670F-6489-4B58-B922-88F5C00CB728";
export const apiUrl       = "https://ccpl.ninjaos.com/development/api/v1.1/";
export const apiUrlV2     = "https://ccpl.ninjaos.com/development/api/v1.2/";
export const apiUrlPro    = "https://ccpl.ninjaos.com/development/api/v1.3/";
export const baseUrl      = "http://chix.jankosoftworks.com/";
export const timThumpUrl  = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const apiUrlNotify = "https://ccpl.ninjaos.com/development/Pushorder/";*/
//export const stripeImage  = "/img/stripe_logo.png";

export const stripeImage  = "";
export const deliveryId  = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId    = "718B1A92-5EBB-4F25-B24D-3067606F67F0";

export const CountryTxt   = "Singapore";
export const productNoImg = "";
export const stripeCompany    = "BBBurgers";
export const stripeKey    = "";
export const stripeEmail    = "dev@jankosoft.com";
export const stripeReference    = "bbburger";
export const stripeDesc    = "My Checkout";
export const stripeCurrency    = "SGD";

export const getHeader = function() {
	
	let header = {
	  headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
	}

	/*if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
	  header.headers.Auth = cookie.load('Token')
	}*/
	
	if(localStorage.getItem('BeaerToken') !== undefined && localStorage.getItem('BeaerToken') !== '' && localStorage.getItem('BeaerToken') !== null) {

		 header.headers.Auth = localStorage.getItem('BeaerToken') 
	}

	return header;

}; 

